import ConfirmationModal from './ConfirmationModal/ConfirmationModal'
import VersionModal from './VersionModal/VersionModal'
import VersionModelModal from './VersionModelModal/VersionModelModal'
import DownloadSettings from './DownloadSettings'

export default {
  ConfirmationModal,
  VersionModal,
  VersionModelModal,
  DownloadSettings
}
