import { takeLatest, call, put, all, select } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { push as pushHistory } from 'react-router-redux'

import actions, { constants } from 'common/actions/auth'
import snackbarActions from 'common/actions/snackbar'

import * as schemas from 'schemas'
import * as api from 'api/auth'
import * as userApi from 'api/user'

import * as Strings from 'constants/Strings'
import * as Routes from 'constants/Routes'
import { setTokenHeader, clearTokenHeader } from 'utils/request'
import { getAuthStoreData, setAuthStoreData, clearAuthStoreData } from 'utils/authStore'

function* postAuth(routing) {
  if (routing.locationBeforeTransitions.pathname === Routes.MAIN) {
    return yield put(pushHistory(Routes.DASHBOARD))
  }
  yield put(pushHistory(routing.locationBeforeTransitions))
}

export function* onAuthUser() {
  yield put(actions.authUserRequest.start())

  const routing = yield select(state => state.routing)

  // Pages to ignore
  if (routing.locationBeforeTransitions.pathname.includes(Routes.LOGIN)) {
    return yield put(actions.authUserRequest.failure(null))
  }

  const authStoreData = getAuthStoreData()

  if (!authStoreData) {
    yield put(actions.authUserRequest.failure())
    return yield call(postAuth, routing)
  }

  try {
    const session = yield call(api.getCognitoSession)
    setTokenHeader(authStoreData)
    const { user } = yield call(userApi.getCognitoUser, {
      username: session.getAccessToken().payload.username
    }) // Pedimos el usuario de la Dynamo
    const norm = yield call(normalize, user, schemas.user)
    yield put(actions.authUserRequest.success(norm))
    yield call(postAuth, routing)
  } catch (err) {
    yield put(actions.authUserRequest.failure(err))
    yield put(pushHistory(Routes.LOGIN))
    yield put(snackbarActions.showSnackbar(Strings.EXPIRED_TOKEN))
  }
}

export function* onLoginCognito({ payload: data }) {
  yield put(actions.loginRequest.start())

  try {
    const cognitoUser = yield call(api.loginCognito, data)
    if (cognitoUser.username === undefined) {
      yield put(actions.loginRequest.failure(cognitoUser))
    } else {
      const session = yield call(api.getCognitoSession)

      yield call(setAuthStoreData, 'Bearer ' + session.getAccessToken().getJwtToken())
      yield call(setTokenHeader, 'Bearer ' + session.getAccessToken().getJwtToken())

      const { user } = yield call(userApi.getCognitoUser, { username: cognitoUser.username }) // Pedimos el usuario de la Dynamo
      const norm = yield call(normalize, user, schemas.user)
      yield put(actions.loginRequest.success(norm))
      yield put(pushHistory(Routes.DASHBOARD))
    }
  } catch (err) {
    yield put(actions.loginRequest.failure(err))
  }
}

export function* onLogoutCognito() {
  yield call(api.logoutCognito)
  yield call(clearAuthStoreData)
  yield call(clearTokenHeader)
  yield put(pushHistory(Routes.LOGIN))
}

export default function* watchAuth() {
  yield all([
    takeLatest(constants.AUTH_USER, onAuthUser),
    takeLatest(constants.LOGIN, onLoginCognito),
    takeLatest(constants.LOGOUT, onLogoutCognito)
  ])
}
