import { createAction, createRequestTypes, createRequestAction } from 'utils'

export const LOAD_SYSTEMS = '@system/LOAD_SYSTEMS'
export const LOAD_SYSTEM = '@system/LOAD_SYSTEM'
export const POLL_SYSTEMS_START = '@system/POLL_SYSTEMS_START'
export const POLL_SYSTEMS_STOP = '@system/POLL_SYSTEMS_STOP'
export const LOAD_LAST_VERSIONS = '@system/LOAD_LAST_VERSIONS'
export const CREATE_SYSTEM = '@system/CREATE_SYSTEM'
export const UPDATE_SYSTEM = '@system/UPDATE_SYSTEM'
export const UPDATE_SYSTEMS_BY_MODEL = '@system/UPDATE_SYSTEMS_BY_MODEL'
export const DELETE_SYSTEM = '@system/DELETE_SYSTEM'
export const DELETE_SYSTEM_DATA = '@system/DELETE_SYSTEM_DATA'
export const DELETE_SYSTEM_FROM_USER = '@system/DELETE_SYSTEM_FROM_USER'
export const ACTIVATE_SYSTEM_API_KEY = '@system/ACTIVATE_SYSTEM_API_KEY'
export const DISABLE_SYSTEM_API_KEY = '@system/DISABLE_SYSTEM_API_KEY'
export const UPDATE_RESET_SYSTEM = '@system/UPDATE_RESET_SYSTEM'
export const UPDATE_NEXT_VERSION_SYSTEM = '@system/UPDATE_NEXT_VERSION_SYSTEM'
export const SYNC_AIRTABLE = '@system/SYNC_AIRTABLE'

export const LOAD_SYSTEMS_REQUEST = createRequestTypes('system/LOAD_SYSTEMS_REQUEST')
export const POLL_SYSTEMS_REQUEST = createRequestTypes('system/POLL_SYSTEMS_REQUEST')
export const LOAD_SYSTEM_REQUEST = createRequestTypes('system/LOAD_SYSTEM_REQUEST')
export const LOAD_LAST_VERSIONS_REQUEST = createRequestTypes('system/LOAD_LAST_VERSIONS_REQUEST')
export const CREATE_SYSTEM_REQUEST = createRequestTypes('system/CREATE_SYSTEM_REQUEST')
export const UPDATE_SYSTEM_REQUEST = createRequestTypes('system/UPDATE_SYSTEM_REQUEST')
export const UPDATE_SYSTEMS_BY_MODEL_REQUEST = createRequestTypes(
  'system/UPDATE_SYSTEMS_BY_MODEL_REQUEST'
)
export const DELETE_SYSTEM_REQUEST = createRequestTypes('system/DELETE_SYSTEM_REQUEST')
export const DELETE_SYSTEM_DATA_REQUEST = createRequestTypes('system/DELETE_SYSTEM_DATA_REQUEST')
export const DELETE_SYSTEM_FROM_USER_REQUEST = createRequestTypes(
  'system/DELETE_SYSTEM_FROM_USER_REQUEST'
)
export const ACTIVATE_SYSTEM_API_KEY_REQUEST = createRequestTypes(
  'system/ACTIVATE_SYSTEM_API_KEY_REQUEST'
)
export const DISABLE_SYSTEM_API_KEY_REQUEST = createRequestTypes(
  'system/DISABLE_SYSTEM_API_KEY_REQUEST'
)
export const UPDATE_RESET_SYSTEM_REQUEST = createRequestTypes('system/UPDATE_RESET_SYSTEM_REQUEST')
export const UPDATE_NEXT_VERSION_SYSTEM_REQUEST = createRequestTypes(
  'system/UPDATE_NEXT_VERSION_SYSTEM_REQUEST'
)
export const SYNC_AIRTABLE_REQUEST = createRequestTypes('system/SYNC_AIRTABLE_REQUEST')

export const constants = {
  LOAD_SYSTEMS,
  LOAD_SYSTEM,
  POLL_SYSTEMS_START,
  POLL_SYSTEMS_STOP,
  LOAD_LAST_VERSIONS,
  CREATE_SYSTEM,
  UPDATE_SYSTEM,
  UPDATE_SYSTEMS_BY_MODEL,
  DELETE_SYSTEM,
  DELETE_SYSTEM_DATA,
  DELETE_SYSTEM_FROM_USER,
  ACTIVATE_SYSTEM_API_KEY,
  DISABLE_SYSTEM_API_KEY,
  UPDATE_RESET_SYSTEM,
  UPDATE_NEXT_VERSION_SYSTEM,
  SYNC_AIRTABLE,

  LOAD_SYSTEMS_REQUEST,
  LOAD_SYSTEM_REQUEST,
  POLL_SYSTEMS_REQUEST,
  LOAD_LAST_VERSIONS_REQUEST,
  CREATE_SYSTEM_REQUEST,
  UPDATE_SYSTEM_REQUEST,
  UPDATE_SYSTEMS_BY_MODEL_REQUEST,
  DELETE_SYSTEM_REQUEST,
  DELETE_SYSTEM_DATA_REQUEST,
  DELETE_SYSTEM_FROM_USER_REQUEST,
  ACTIVATE_SYSTEM_API_KEY_REQUEST,
  DISABLE_SYSTEM_API_KEY_REQUEST,
  UPDATE_RESET_SYSTEM_REQUEST,
  UPDATE_NEXT_VERSION_SYSTEM_REQUEST,
  SYNC_AIRTABLE_REQUEST
}

export const loadSystems = createAction(LOAD_SYSTEMS)
export const loadSystem = createAction(LOAD_SYSTEM)
export const pollSystemsStart = createAction(POLL_SYSTEMS_START)
export const pollSystemsStop = createAction(POLL_SYSTEMS_STOP)
export const loadLastVersions = createAction(LOAD_LAST_VERSIONS)
export const createSystem = createAction(CREATE_SYSTEM)
export const updateSystem = createAction(UPDATE_SYSTEM)
export const updateSystemsByModel = createAction(UPDATE_SYSTEMS_BY_MODEL)
export const deleteSystem = createAction(DELETE_SYSTEM)
export const deleteSystemData = createAction(DELETE_SYSTEM_DATA)
export const deleteSystemFromUser = createAction(DELETE_SYSTEM_FROM_USER)
export const activateSystemApiKey = createAction(ACTIVATE_SYSTEM_API_KEY)
export const disableSystemApiKey = createAction(DISABLE_SYSTEM_API_KEY)
export const updateResetSystem = createAction(UPDATE_RESET_SYSTEM)
export const updateNextVersionSystem = createAction(UPDATE_NEXT_VERSION_SYSTEM)
export const syncAirtable = createAction(SYNC_AIRTABLE)

export const loadSystemsRequest = createRequestAction(LOAD_SYSTEMS_REQUEST)
export const pollSystemsRequest = createRequestAction(POLL_SYSTEMS_REQUEST)
export const loadSystemRequest = createRequestAction(LOAD_SYSTEM_REQUEST)
export const loadLastVersionsRequest = createRequestAction(LOAD_LAST_VERSIONS_REQUEST)
export const createSystemRequest = createRequestAction(CREATE_SYSTEM_REQUEST)
export const updateSystemRequest = createRequestAction(UPDATE_SYSTEM_REQUEST)
export const updateSystemsByModelRequest = createRequestAction(UPDATE_SYSTEMS_BY_MODEL_REQUEST)
export const deleteSystemRequest = createRequestAction(DELETE_SYSTEM_REQUEST)
export const deleteSystemDataRequest = createRequestAction(DELETE_SYSTEM_DATA_REQUEST)
export const deleteSystemFromUserRequest = createRequestAction(DELETE_SYSTEM_FROM_USER_REQUEST)
export const activateSystemApiKeyRequest = createRequestAction(ACTIVATE_SYSTEM_API_KEY_REQUEST)
export const disableSystemApiKeyRequest = createRequestAction(DISABLE_SYSTEM_API_KEY_REQUEST)
export const updateResetSystemRequest = createRequestAction(UPDATE_RESET_SYSTEM_REQUEST)
export const updateNextVersionSystemRequest = createRequestAction(
  UPDATE_NEXT_VERSION_SYSTEM_REQUEST
)
export const syncAirtableRequest = createRequestAction(SYNC_AIRTABLE_REQUEST)

export default {
  loadSystems,
  loadSystem,
  pollSystemsStart,
  pollSystemsStop,
  loadLastVersions,
  createSystem,
  updateSystem,
  updateSystemsByModel,
  deleteSystem,
  deleteSystemData,
  deleteSystemFromUser,
  activateSystemApiKey,
  disableSystemApiKey,
  updateResetSystem,
  updateNextVersionSystem,
  syncAirtable,

  loadSystemsRequest,
  loadSystemRequest,
  pollSystemsRequest,
  loadLastVersionsRequest,
  createSystemRequest,
  updateSystemRequest,
  updateSystemsByModelRequest,
  deleteSystemRequest,
  deleteSystemDataRequest,
  deleteSystemFromUserRequest,
  activateSystemApiKeyRequest,
  disableSystemApiKeyRequest,
  updateResetSystemRequest,
  updateNextVersionSystemRequest,
  syncAirtableRequest
}
