// TODO: spelling / grammar check
export default {
  // Maintenance message
  maintenance: [
    'Servidores en mantenimiento. En breve podrás volver a acceder a My inBiot. Disculpa las molestias.',
    'Servers in maintenance. Shortly you will be able to access My inBiot again. Sorry for the inconvenience.',
    'Server in Wartung. In Kürze werden Sie wieder auf Mein inBiot zugreifen können. Entschuldigung für die Unannehmlichkeiten.'
  ],
  // Login & sign up
  discoverInbiot: ['Descubre inBiot', 'Discover inBiot', 'Entdecken Sie inBiot'],
  copyright: [
    'Copyright © 2022 inBiot. Todos los derechos reservados.',
    'Copyright © 2022 inBiot. All rights reserved.',
    'Copyright © 2022 inBiot. Alle Rechte vorbehalten.'
  ],
  login: ['Iniciar sesión', 'Login', 'Melden Sie sich an'],
  signUp: ['Crear cuenta', 'Sign Up', 'Registrierung'],
  premiumFunctionalityText: [
    'Esta funcionalidad pertenece al plan Premium. Contacta a info@inbiot.es para más información.',
    'This functionality belongs to the Premium plan. Contact info@inbiot.es for more information.',
    'Diese Funktion ist Bestandteil des Premium-Tarifs. Für weitere Informationen wenden Sie sich bitte an info@inbiot.es.'
  ],
  businessPlan: ['Plan Business', 'Business Plan', 'Business Plan'],
  firstName: ['Nombre', 'First name', 'Vorname'],
  lastName: ['Apellidos', 'Last name', 'Nachname'],
  country: ['País', 'Country', 'Land'],
  countryRequired: ['País *', 'Country *', 'Firmenname *'],
  company: ['Empresa', 'Company', 'Firmenname '],
  position: ['Cargo en la empresa', 'Company position', 'Position in der Firma'],
  positionPlaceholder: ['Cargo en la empresa', 'Position in the company', 'Position in der Firma'],
  email: ['Correo electrónico', 'Email address', 'E-Mail-Adresse'],
  password: ['Contraseña', 'Password', 'Passwort'],
  oldPassword: ['Contraseña antigua', 'Old password', 'Altes Passwort'],
  newPassword: ['Contraseña nueva', 'New password', 'Neues Passwort'],
  repeatPassword: ['Repetir contraseña', 'Repeat password', 'Passwort bestätigen'],
  verificationCode: ['Código de verificación', 'Verification Code', 'Verifizierungs-Schlüssel'],
  or: ['O', 'Or', 'Oder'],
  add: ['Añadir', 'Add', 'Hinzufügen'],
  myMicas: ['Mis dispositivos MICA', 'My MICA devices', 'Meine MICA-Geräte'],
  newGroup: ['Nuevo grupo', 'New group', 'Neue Gruppe'],
  multipleDownload: ['Descarga múltiple', 'Multiple download', 'Mehrfacher Download'],
  options: ['Opciones', 'Options', 'Auswahl'],
  summary: ['Resumen', 'Summary', 'Zusammenfassung'],
  details: ['En detalle', 'View details', 'Details anzeigen'],
  addSystem: ['Añadir MICA', 'Add MICA', 'System hinzufügen'],
  addGroup: ['Añadir grupo', 'Add group', 'Gruppe hinzufügen'],
  editGroup: ['Editar grupo', 'Edit group', 'Gruppe bearbeiten'],
  noGroup: ['Sin asignar', 'No assigned', 'Keine zugewiesen'],
  groupName: ['Nombre del grupo', 'Group name', 'Gruppenname'],
  searchMica: ['Buscar MICA', 'Search MICA', 'Suche MICA'],
  searchDevice: ['Buscar dispositivo', 'Search device', 'Gerät suchen'],
  save: ['Guardar', 'Save', 'Speichern'],
  saveChanges: ['Guardar cambios', 'Save changes', 'Änderungen speichern'],
  edit: ['Editar', 'Edit', 'Bearbeiten'],
  editSystem: ['Editar dispositivo', 'Edit device', 'Gerät bearbeiten'],
  timeRangeSettings: [
    'Ajuste rango de gráficas',
    'Graphs range setting',
    'Einstellung des Grafikbereichs'
  ],
  delete: ['Borrar', 'Delete', 'Löschen'],
  deleteSystem: ['Borrar dispositivo', 'Delete device', 'System löschen'],
  clearData: ['Limpiar datos', 'Clear data', 'Daten löschen'],
  downloadStatistics: [
    'Descargar estadísticos',
    'Download statistics',
    'Statistiken herunterladen'
  ],
  dailyStatistics: ['Estadísticos diarios', 'Daily statistics', 'Tägliche Statistiken'],
  weeklyStatistics: ['Estadísticos semanales', 'Weekly statistics', 'Wöchentliche Statistiken'],
  monthlyStatistics: ['Estadísticos mensuales', 'Monthly statistics', 'Monatliche Statistiken'],
  hideVirusPanel: ['Ocultar panel virus', 'Hide virus panel', 'Virus-Panel ausblenden'],
  showVirusPanel: ['Mostrar panel virus', 'Show virus panel', 'Virus-Panel anzeigen'],
  dashboard: ['MICAs', 'MICAs', 'MICAs'],
  home: ['Inicio', 'Home', 'Home'],
  parameters: ['Parámetros', 'Parameters', 'Parameter'],
  indicators: ['Indicadores', 'Indicators', 'Indikatoren'],
  chart: ['Gráfica', 'Chart', 'Grafik'],
  iaqIndicator: ['Indicador IAQ', 'IAQ Indicator', 'IAQ Indikator'],
  thermalComfortIndicator: ['Confort térmico', 'Thermal comfort', 'Thermischer Komfort'],
  iaqInterior: ['Calidad de Aire Interior', 'Indoor Air Quality', 'Luftqualität in Innenräumen'],
  indicatorThermico: ['Confort Térmico', 'Thermal Comfort', 'Thermischer Komfort'],
  ViricoIndicator: ['Resistencia a Virus', 'Resistance to Virus', 'Virusresistenz'],
  VentilaciónIndicator: [
    'Eficacia de Ventilación',
    'Ventilation Efficiency',
    'Qualität der Belüftung'
  ],
  virusIndicator: ['Indicador Virus', 'Virus Indicator', 'Virus Indikator'],
  ventilationIndicator: ['Indicador Ventilación', 'Ventilation Indicator', 'Belüftung Indikator'],
  DESK: ['MICA Desk', 'MICA Desk', 'MICA Desk'],
  MICA_LITE: ['MICA Lite', 'MICA Lite', 'MICA Lite'],
  MICA_WELL: ['MICA Well', 'MICA Well', 'MICA Well'],
  MICA: ['MICA', 'MICA', 'MICA'],
  report: ['Informe inteligente', 'Smart report', 'Intelligenter Bericht'],
  compare: ['Comparar', 'Compare', 'Vergleichen'],
  fullscreenView: ['Pantallas', 'Views', 'Bildschirme'],
  shareables: ['Compartibles', 'Shareables', 'Shareables'],
  alerts: ['Alertas', 'Alerts', 'Warnungen'],
  inbiot: ['¿Qué es inBiot?', 'What is inBiot?', 'Was ist inBiot?'],
  wikiInbiot: ['WikinBiot', 'WikinBiot', 'WikinBiot'],
  help: ['Ayuda', 'Help', 'Hilfe'],
  user: ['Usuario', 'User', 'Nutzer'],
  language: ['Idioma', 'Language', 'Sprache'],
  ES: ['Español', 'Spanish', 'Spanisch'],
  EN: ['Inglés', 'English', 'Englisch'],
  useInfo: ['Información de uso', 'Usage information', 'Nutzungsinformationen'],
  faqs: ['Preguntas frecuentes', 'FAQs', 'Häufig gestellte Fragen'],
  comments: ['Envíanos tus comentarios', 'Send us your comments', 'Senden Sie uns Ihren Kommentar'],
  profile: ['Perfil', 'Profile', 'Profil'],
  editProfile: ['Editar perfil', 'Edit profile', 'Profil bearbeiten'],
  restorePassword: ['Restablecer contraseña', 'Restore password', 'Passwort wiederherstellen'],
  uploadLogo: ['Subir o cargar logotipo', 'Upload logo', 'Logo hochladen'],
  deleteLogo: ['Eliminar logotipo', 'Delete logo', 'Logo löschen'],
  logout: ['Cerrar sesión', 'Logout', 'Abmelden'],
  settings: ['Ajustes', 'Settings', 'Einstellungen'],
  name: ['Nombre', 'Name', 'Name'],
  systemName: ['Nombra el dispositivo', 'Name your device', 'Benennen Sie Ihr Gerät'],
  systemDescription: ['Descripción del dispositivo', 'Device description', 'Gerätebeschreibung'],
  sensorId: ['Id de sensor', 'Sensor id', 'Sensor id'],
  description: ['Descripción', 'Description', 'Beschreibung'],
  zipCode: ['Código postal', 'Zip code', 'Postleitzahl'],
  isPublic: ['Público: ', 'Public:  ', 'Ist öffentlich: '],
  isPublicInfo: [
    'Si marcas el sensor como público pordrás compartir tus datos con otros',
    'If you set public you can share your data with others',
    'Wenn Sie öffentlich festlegen, können Sie Ihre Daten mit anderen teilen'
  ],
  micaMiniInfoText: ['Necesidad de ventilación', 'Ventilation need', 'Lüftungsbedarf'],
  micaDeskInfoText: ['Indicador IAQ', 'IAQ indicator', 'IAQ Indikator'],
  micaDeskInfoTextTitle: [
    'Indicador de Calidad del Aire Interior',
    'Indoor Air Quality Indicator',
    'Luftqualität in Innenräumen Luftqualität in Innenräumen'
  ],
  micaLiteInfoText: ['Resistencia a Virus', 'Resistance to Virus', 'Virusresistenz'],
  micaLiteInfoTextTitle: [
    'Indicador de Resistencia a la Propagación de Virus',
    'Resistance to Virus Spread Indicator',
    'Indikator für Resistenz gegen Virusausbreitung Resistenzindikator'
  ],
  micaInfoText: ['Calidad de Aire Interior', 'Indoor Air Quality', 'Luftqualität in Innenräumen'],
  micaMiniInfoTextTitle: [
    'Indicador del Confort Termohigrométrico',
    'Thermohygrometric Comfort Indicator',
    'Thermo-hygrometrischer Komfort-Indikator'
  ],
  micaWellInfoText: [
    'Calidad de Aire Interior',
    'Indoor Air Quality',
    'Luftqualität in Innenräumen'
  ],
  micaLiteInfo: [
    'El Indicador Virus, basado en el RESET VIRAL INDEX, muestra en una escala 0-100 la resistencia que ofrece el aire de un espacio a la propagación de virus. </br></br>' +
      'Nos ayuda a optimizar los sistemas de un edificio para minimizar el potencial de infección vírica por vía aérea.',

    'The Virus Indicator, based on the RESET VIRAL INDEX, shows on a scale of 0-100 the resistance of the air in a space to the spread of viruses. </br></br>' +
      "It helps us to optimize a building's systems to minimize the potential for airborne viral infection.",

    'Der Virus-Indikator, der auf dem RESET VIRAL INDEX basiert, zeigt auf einer Skala von 0-100 an, wie resistent die Luft in einem Raum gegenüber der Verbreitung von Viren ist. </br></br>' +
      'Er hilft uns, die Systeme eines Gebäudes so zu optimieren, dass das Potenzial für Virusinfektionen über die Luft minimiert wird.'
  ],
  micaInfo: [
    'Basado en el RESET AIR INDEX, permite comunicar de manera sencilla, en una escala 0-100, la calidad del aire interior de un espacio. </br></br>' +
      'Está basado en los parámetros CO₂, TVOC y PM2.5.',

    'Based on the RESET AIR INDEX, it allows to communicate in a simple way, on a 0-100 scale, the indoor air quality of a space. </br></br>' +
      'It is based on the parameters CO₂, TVOC and PM2.5.',

    'Basierend auf dem RESET AIR INDEX ermöglicht es auf einfache Weise, auf einer Skala von 0-100, die Innenraumluftqualität eines Raumes zu kommunizieren. </br></br>' +
      'Er basiert auf den Parametern CO₂, TVOC und PM2.5.'
  ],
  micaInfoReduced: [
    'Basado en el RESET AIR INDEX, permite comunicar de manera sencilla, en una escala 0-100, la calidad del aire interior de un espacio. </br></br>' +
      'Está basado en los parámetros CO₂, TVOC y PM2.5.',

    'Based on the RESET AIR INDEX, it allows to communicate in a simple way, on a 0-100 scale, the indoor air quality of a space. </br></br>' +
      'It is based on the parameters CO₂, TVOC and PM2.5.',

    'Basierend auf dem RESET AIR INDEX ermöglicht es auf einfache Weise, auf einer Skala von 0-100, die Innenraumluftqualität eines Raumes zu kommunizieren. </br></br>' +
      'Er basiert auf den Parametern CO₂, TVOC und PM2.5.'
  ],
  micaWellInfo: [
    'Basado en el RESET AIR INDEX, permite comunicar de manera sencilla, en una escala 0-100, la calidad del aire interior de un espacio. </br></br>' +
      'Está basado en los parámetros CO₂, TVOC y PM2.5.',

    'Based on the RESET AIR INDEX, it allows to communicate in a simple way, on a 0-100 scale, the indoor air quality of a space. </br></br>' +
      'It is based on the parameters CO₂, TVOC and PM2.5.',

    'Basierend auf dem RESET AIR INDEX ermöglicht es auf einfache Weise, auf einer Skala von 0-100, die Innenraumluftqualität eines Raumes zu kommunizieren. </br></br>' +
      'Er basiert auf den Parametern CO₂, TVOC und PM2.5.'
  ],
  micaDeskInfo: [
    'Basado en el RESET AIR INDEX, permite comunicar de manera sencilla, en una escala 0-100, la calidad del aire interior de un espacio. </br></br>' +
      'Está basado en los parámetros CO₂, TVOC y PM2.5.',

    'Based on the RESET AIR INDEX, it allows to communicate in a simple way, on a 0-100 scale, the indoor air quality of a space. </br></br>' +
      'It is based on the parameters CO₂, TVOC and PM2.5.',

    'Basierend auf dem RESET AIR INDEX ermöglicht es auf einfache Weise, auf einer Skala von 0-100, die Innenraumluftqualität eines Raumes zu kommunizieren. </br></br>' +
      'Er basiert auf den Parametern CO₂, TVOC und PM2.5.'
  ],
  ventilationInfoText: [
    'Eficacia de Ventilación',
    'Ventilation Efficiency',
    'Effizienz der Belüftung'
  ],
  ventilationInfoTextTitle: [
    'Indicador de Eficacia de Ventilación',
    'Ventilation Efficiency Indicator',
    'Indikator für den Lüftungswirkungsgrad'
  ],
  ventilationInfo: [
    'Mide en tiempo real la eficacia de la ventilación en un espacio interior, representada en una escala 0-100 y basada en la concentración de CO₂ y de TVOC. ',
    'It measures in real time the ventilation efficiency in an indoor space, represented on a 0-100 scale and based on CO₂ and TVOC concentration.',
    'Es misst in Echtzeit die Lüftungseffizienz eines Innenraums, dargestellt auf einer Skala von 0-100 und basierend auf der CO₂- und TVOC-Konzentration.'
  ],
  ventilationInfoVocs: [
    'Mide en tiempo real la eficacia de la ventilación en un espacio interior, representada en una escala 0-100 y basada en la concentración de CO₂. ',
    'It measures in real time the ventilation efficiency in an indoor space, represented on a 0-100 scale and based on CO₂ concentration.',
    'Es misst in Echtzeit die Lüftungseffizienz eines Innenraums, dargestellt auf einer Skala von 0-100 und basierend auf der CO₂-Konzentration.'
  ],
  micaMiniInfo: [
    'Permite identificar de manera sencilla el rango de temperatura y humedad relativa que representa el bienestar térmico.   </br></br>' +
      'Nos ayuda a objetivizar las condiciones de temperatura y humedad de un espacio interior.',

    'It allows to identify in a simple way the range of temperature and relative humidity that represents the thermal comfort. <br/> <br/>' +
      'It helps us to objectify the temperature and humidity conditions of an interior space.',

    'Sie ermöglicht es uns, den Bereich der Temperatur und der relativen Luftfeuchtigkeit, der für die thermische Behaglichkeit ausschlaggebend ist, leicht zu bestimmen. <br/> <br/>' +
      'Sie hilft uns, die Temperatur- und Feuchtigkeitsbedingungen in einem Innenraum zu objektivieren.'
  ],
  thermalIndicator: ['Confort Térmico', 'Thermal Comfort', 'Thermischer Komfort'],
  moreInfo: ['Ampliar Información', 'More information'],
  learnMore: ['SABER MÁS', 'LEARN MORE', 'MEHR LERNEN'],
  noDataMeasured: ['No se han medido datos', 'No data measured', 'Keine Daten gemessen'],
  forgotPassword: ['¿Has olvidado la contraseña?', 'Forgot password?', 'Passwort vergessen?'],
  resendCode: [
    'Reenviar correo de confirmación',
    'Resend confirmation email',
    'Bestätigungsmail erneut senden'
  ],
  loading: ['Cargando', 'Loading', 'Wird geladen'],
  min: ['Mín', 'Min', 'Min'],
  max: ['Máx', 'Max', 'Max'],
  timeRange: ['Rango de tiempo', 'Time range', 'Zeitspanne'],
  chartAvg: ['med.', 'avg.', 'avg.'],
  chartMax: ['max.', 'max.', 'max.'],
  chartMin: ['max.', 'max.', 'max.'],
  mean: ['Media', 'Mean', 'Bedeuten'],
  downloadSettings: ['Parámetros de descarga', 'Download parameters', 'Parameter herunterladen'],
  fullscreenSettings: [
    'Parámetros de visualización',
    'Visualization parameters',
    'Anzeigeparameter'
  ],
  fullscreenFormat: ['Formato de visualización', 'Visualization format', 'Anzeigeformat'],
  showCharts: ['Mostrar gráficas', 'Show charts', 'Grafiken anzeigen'],
  openFullscreen: ['Abrir pantalla completa', 'Open fullscreen', 'Vollbild öffnen'],
  dataTypes: ['Tipos de datos', 'Data types', 'Datentypen'],
  dateInterval: ['Intervalo de fechas', 'Date interval', 'Datumsintervall'],
  startDate: ['Fecha de inicio', 'Start date', 'Startdatum'],
  endDate: ['Fecha final', 'End date', 'Endtermin'],
  downloadExtension: ['Extensión del archivo', 'File extension', 'Dateierweiterung'],
  download: ['Descargar datos', 'Download data', 'Daten herunterladen'],
  downloadData: ['Descarga de datos', 'Data download', 'Daten herunterladen'],
  notificationAlarm: ['Gestionar alarma', 'Manage alarm'],
  newAlarm: ['Nueva alerta', 'New alarm', 'Neuer Alarm'],
  createAlarm: ['Crear alerta', 'Create alert', 'Einen Alarm erstellen'],
  fullScreen: ['Pantalla completa', 'Full Screen', 'Vollbild'],
  next: ['Siguiente', 'Next', 'Nächste'],
  back: ['Atrás', 'Back', 'Zurück'],
  copyPublicApiKey: ['Copiar API Pública', 'Copy Public API Key', 'Public API Key kopieren'],
  copyShareableLink: [
    'Copiar enlace compartible',
    'Copy shareable link',
    'Freigabefähigen Link kopieren'
  ],
  compareIndoor: [
    'Comparar la temperatura interior',
    'Compare indoor temperature',
    'Innentemperatur vergleichen'
  ],
  compareIndoorHelper: [
    'Seleccionar esto le permitirá comparar la temperatura exterior con la temperatura interior.',
    'Selecting this will alow you to compare the outside temperature with your indoor temperature.',
    'Wenn Sie dies auswählen, können Sie die Außentemperatur mit Ihrer Innentemperatur vergleichen.'
  ],

  noWeatherDataAvailable: [
    'No hay datos de tiempo disponibles.',
    'No weather data available.',
    'Keine Wetterdaten verfügbar.'
  ],
  noWeatherDataForZipcode: [
    'Lo sentimos, no disponemos de datos de tiempo exterior para el código postal introducido.',
    'We are sorry. We do not have outdoor weather data available for that zip code.',
    'We are sorry. We do not have outdoor weather data available for that zip code.'
  ],
  changeZipcode: [
    'Puedes probar a cambiarlo por un código postal cercano.',
    'You can try to change it by a near zip code.',
    'You can try to change it by a near zip code.'
  ],

  customValues: ['Valores personalizados', 'Custom values', 'benutzerdefinierte Werte'],

  yellowWarning: ['Nivel Amarillo', 'Yellow Warning', 'Gelbe Warnung'],

  redWarning: ['Nivel Rojo', 'Red Warning', 'Rote Warnung'],

  Whoops: ['Ups', 'Whoops!', 'Hoppla!'],

  notFoundPage: [
    'No hemos podido encontrar la página que estás buscando',
    'We could not find the page you were looking for.',
    'Wir konnten die von Ihnen gesuchte Seite nicht finden'
  ],

  noWeatherDataAvailableExtra: [
    '¿Acabas de añadir este sensor? Los datos del tiempo tardarán una media hora en aparecer.',
    'Did u just recently added this system? It will take about half an hour before we have your weather data measured.'
  ],
  makeSureCorrectZipCode: [
    'Asegurate de que has introducido el código postal correctamente.',
    'Make sure you entered the zip code correctly.',
    'Haben Sie diesen Sensor gerade hinzugefügt? Es dauert etwa eine halbe Stunde, bis die Wetterdaten angezeigt werden.'
  ],
  noOutdoorQualityDataAvailable: [
    'No hay datos de calidad de aire exterior disponibles.',
    'No outdoor air quality data available.',
    'Es liegen keine Daten zur Außenluftqualität vor.'
  ],
  zipCodeAbbreviation: ['CP', 'ZIP', 'PLZ'],
  navDevice: ['1. Dispositivo', '1. Device', '1. Gerät'],
  navDevices: ['1. Dispositivos', '1. Devices', '1. Geräte'],
  navEnviroment: ['2. Entorno', '2. Enviroment', '2. Umgebung'],
  navParams: ['2. Parámetros', '2. Parameters', '2. Parameter'],
  editNavParams: ['1. Parámetros', '1. Parameters', '1. Parameter'],
  navQRCode: ['3. Código QR', '3. QR-Code', '3. QR-Code'],
  navPrivacy: ['3. Privacidad', '3. Privacy', '3. Privatsphäre'],
  editNavPrivacy: ['2. Privacidad', '2. Privacy', '2. Privatsphäre'],
  navProgram: ['2. Programar', '2. Program', '2. Programm'],
  navValues: ['3. Valores', '3. Values', '3. Werte'],
  editNavValues: ['2. Valores', '2. Values', '2. Werte'],
  navDateAndFormat: ['2. Fechas y formato', '2. Date and format', '2. Daten und Format'],
  multipleNavDateAndFormat: ['3. Fechas y formato', '3. Date and format', '3. Daten und Format'],
  idSensor: ['Id del sensor', 'Sensor`s id', 'Sensor-ID'],
  infoId: [
    'El Id se obtiene al configurar el dispositivo por primera vez desde la app',
    'Id is obtained the first time the device is configured from the app',
    'Die ID wird bei der ersten Konfiguration des Geräts über die App abgerufen'
  ],
  infoPaisYcp: [
    'El país y código postal se utilizan para medir el tiempo exterior',
    'The country and zip are required for measure the exterior wheather',
    'Das Land und die Postleitzahl werden verwendet, um die Zeit draußen zu messen'
  ],
  agree: ['Sí, eliminarlo', 'Yes, delete', 'Akzeptieren'],
  understood: ['Entendido', 'Understood', 'Verstanden'],
  cancel: ['Cancelar', 'Cancel', 'Stornieren'],
  definedValues: ['Valores predefinidos: ', 'Define value: ', 'Wert definieren'],
  addDevice: ['Añadir dispositivo', 'Add device', 'Gerät hinzufügen'],
  editDevice: ['Editar dispositivo', 'Edit device', 'Gerät bearbeiten'],
  editDevicePeriodicity: [
    'Periodicidad subida datos',
    'Periodicity of uploading data',
    'Periodizität des Hochladens von Daten'
  ],
  timeInterval: ['Periodicidad en minutos', 'Periodicity in minutes', 'Periodizität in Minuten'],
  timeIntervalInfo: [
    'Introduce la periodicidad(en minutos) en la que tu MICA enviará datos a My inBiot.',
    'Enter the frequency (in minutes) at which your MICA will send data to My inBiot.',
    'Geben Sie die Häufigkeit (in Minuten) an, mit der Ihr MICA Daten an My inBiot senden soll.'
  ],
  editDeviceLights: ['Activación luz MICA', 'MICA light activation', 'MICA-Lichtaktivierung'],
  deviceLights: [
    'Activación luz indicadora MICA',
    'Activation of MICA indicator light',
    'Aktivierung der MICA-Kontrollleuchte'
  ],
  deviceLigthsInfo: [
    'Si activas esta opción el MICA mantendrá encendida la luz indicadora de necesidad de ventilación.',
    'If you activate this option, the MICA will keep the ventilation indicator light on.',
    'Wenn Sie diese Option aktivieren, leuchtet die Belüftungsanzeige des MICA weiter.'
  ],
  deviceUpdateInfo: [
    'Los cambios se aplicarán en el MICA en la siguiente toma de datos.',
    'The changes will be implemented in the MICA at the next data collection.',
    'Die Änderungen werden bei der nächsten Datenerhebung in die MICA aufgenommen.'
  ],
  applyToAllDevices: [
    'Aplicar a todos los MICAs',
    'Apply to all MICAs',
    'Anwendung auf alle MICAs'
  ],
  applyToAllDevicesInfo: [
    'Seleccionando esta opción aplicarás los cambios a todos los MICAs asociados a esta cuenta.',
    'Selecting this option will apply the changes to all MICAs associated with this account.',
    'Wenn Sie diese Option wählen, werden die Änderungen auf alle mit diesem Konto verbundenen MICAs angewendet.'
  ],
  device: ['Dispositivo', 'Device', 'Gerät'],
  warning: ['Aviso', 'Warning', 'Warnung'],
  errorIdTitle: ['Error añadiendo MICA', 'Error adding MICA', 'Fehler beim Hinzufügen von MICA'],
  errorIdQuestion: ['¿Por qué ocurre esto?', 'Why does it happen?', 'Warum passiert das?'],
  errorIdProblems: [
    '<p>Este error se produce por uno de estos motivos: </p>' +
      '<ol>' +
      '<li><b>Se ha introducido erróneamente el ID del sensor.</b></li><br>' +
      '<ul><li>Comprueba que el ID coincide con el que se ha generado durante el proceso de configuración a la red WiFi.</li></ul><br>' +
      '<li><b>MICA no se ha conectado a la red WiFi o no tiene acceso a Internet</b></li><br>' +
      '<ul><li>Desconecta y conecta de nuevo el conector magnético de alimentación.</li><br>' +
      '<li>Se encenderá la luz blanca. Espera unos segundos.</li><br>' +
      '<ul><li>Si la luz se apaga pero no te permite añadir el sensor es debido a que la red WiFi configurada tiene restricciones de seguridad o se trata de una red WPA-Enterprise (con necesidad de iniciar sesión).' +
      ' Es necesario que te habiliten acceso a dicha red.</li><br>' +
      ' <li>Si la luz se enciende azul y empieza a parpadear, significa que no se ha podido conectar a la red WiFi para la que se ha configurado.' +
      ' En ese caso, vuelve a realizar el proceso de configuración y asegúrate de que las credenciales se han introducido correctamente.<a href="https://www.inbiot.es/config.html" target="_blank"> Configuración MICA</a></li></ul><br>' +
      '<li>Añade nuevamente el sensor a tu cuenta de My inBiot.</li><br>' +
      '<li>Si tras este proceso persiste el error, contacta con nosotros: <b>info@inbiot.es<b> </li></ul>' +
      '</ol>',
    '<p>This error occurs for one of these reasons: </p>' +
      '<ol>' +
      '<li><b>Sensor ID wrongly entered.</b></li><br>' +
      '<ul><li>Check that the ID matches the one generated during the configuration process to the WiFi network.</li></ul><br>' +
      '<li><b>MICA has not connected to the WiFi network or does not have access to the Internet</b></li><br>' +
      '<ul><li>Disconnect and reconnect the magnetic power connector.</li><br>' +
      '<li>The white light will turn on. Wait a few seconds.</li><br>' +
      '<ul><li> If the light turns off but does not allow you to add the sensor, it is because the configured WiFi network has security restrictions or it is a WPA-Enterprise network (with need to log in). ' +
      'It is necessary that they enable you access to said network.</li><br>' +
      ' <liIf the light turns blue and starts flashing, it means that it has not been able to connect to the WiFi network for which it has been configured.' +
      ' In that case, go through the setup process again and make sure the credentials have been entered correctly.<a href="https://www.inbiot.es/config.html" target="_blank"> MICA configuration</a></li></ul><br>' +
      '<li>Add the device back to your My inBiot account.</li><br>' +
      '<li>If after this process the error persists, contact us: <b>info@inbiot.es<b> </li></ul>' +
      '</ol>',
    '<p>Dieser Fehler tritt aus einem dieser Gründe auf: </p>' +
      '<ol>' +
      '<li><b>Sensor-ID falsch eingegeben.</b></li><br>' +
      '<ul><li>Überprüfen Sie, ob die ID mit der während des Konfigurationsprozesses generierten ID für das WLAN-Netzwerk übereinstimmt.</li></ul><br>' +
      '<li><b>MICA hat sich nicht mit dem WiFi-Netzwerk verbunden oder hat keinen Zugang zum Internet</b></li><br>' +
      '<ul><li>Trennen Sie den magnetischen Stromanschluss und schließen Sie ihn wieder an.</li><br>' +
      '<li>Das weiße Licht wird eingeschaltet. Warte ein paar Sekunden.</li><br>' +
      '<ul><li>Wenn das Licht ausgeht, Sie den Sensor jedoch nicht hinzufügen können, liegt dies daran, dass das konfigurierte WLAN-Netzwerk Sicherheitsbeschränkungen aufweist oder es sich um ein WPA-Enterprise-Netzwerk handelt (mit Anmeldung). ' +
      'Es ist notwendig, dass sie Ihnen den Zugang zu diesem Netzwerk ermöglichen.</li><br>' +
      ' <li>Wenn das Licht blau wird und zu blinken beginnt, bedeutet dies, dass es nicht in der Lage war, sich mit dem WiFi-Netzwerk zu verbinden, für das es konfiguriert wurde.' +
      ' Führen Sie in diesem Fall den Einrichtungsprozess erneut durch und vergewissern Sie sich, dass die Anmeldeinformationen korrekt eingegeben wurden.<a href="https://www.inbiot.es/config.html" target="_blank"> MICA-Konfiguration</a></li></ul><br>' +
      '<li>Fügen Sie das Gerät wieder Ihrem My inBiot-Konto hinzu.</li><br>' +
      '<li>Wenn der Fehler nach diesem Vorgang weiterhin besteht, kontaktieren Sie uns: <b>info@inbiot.es<b> </li></ul>' +
      '</ol>'
  ],
  deleteSystemConfirmationText: [
    '¿Estas seguro de que quieres borrar el dispositivo?',
    'You are going to delete the device. Are you sure about it?',
    'Sie werden das Gerät löschen. Bist du dir sicher?'
  ],
  deleteDataConfirmationText: [
    '¿Estas seguro de que quieres borrar los datos?',
    'You are going to delete the data. Are you sure about it?',
    'Sie werden die Daten löschen. Bist du dir sicher?'
  ],
  deleteSystemFromGroupConfirmationText: [
    '¿Estas seguro de que quieres borrar el dispositivo del grupo?',
    'You are going to delete the device from this group. Are you sure about it?'
  ],
  deleteGroupConfirmationText: [
    '¿Estás seguro de que quieres borrar el grupo?',
    'You are going to delete this group. Are you sure about it?'
  ],
  deleteLogoConfirmationText: [
    '¿Estás seguro de que quieres eliminar el logo?',
    'You are going to delete this logo. Are you sure about it?',
    'Sie werden dieses Logo löschen. Sind Sie sich dessen sicher?'
  ],
  deleteScreenConfirmationText: [
    '¿Estás seguro de que quieres borrar la pantalla?',
    'You are going to delete this screen. Are you sure about it?'
  ],
  deleteAlarmConfirmationText: [
    '¿Estás seguro de que quieres borrar la alarma?',
    'You are going to delete this alarm. Are you sure about it?',
    'Sie werden diesen Alarm löschen. Sind Sie sich dessen sicher?'
  ],
  contact: ['Contacto', 'Contact us', 'Kontaktiere uns'],
  activeAlarm: ['Activar alarma', 'Set alarm', 'Wecker stellen'],
  alarmCO2: ['Gestionar alarma de CO2', 'Manage CO2 alarmn', 'CO2-Alarm verwalten'],
  alarmCO2Form: [
    'Recibir un email cuando el CO2 sea superior a :',
    'Receive an email when CO2 is higher than: ',
    'Erhalten Sie eine E-Mail, wenn CO2 höher ist als: '
  ],
  selectParticlesForm: [
    'Selecciona qué partícula se representará en la gráfica:',
    'Select which particle will be plotted on the graph: ',
    'Wählen Sie aus, welches Partikel im Diagramm dargestellt werden soll: '
  ],

  // Login page
  loginPage: {
    needAnAccount: ['¿Necesitas una cuenta?', 'Need an account?', 'Benötigen Sie einen Account?'],
    termsAndConditions1: [
      'Al iniciar sesión, aceptas la',
      'When you login, you accept the',
      'Wenn Sie sich anmelden, akzeptieren Sie die'
    ],
    termsAndConditions2: [' y los', ' and the', ' und das'],
    termsAndConditions3: [' de inBiot.', ' of inBiot.', ' von inBiot.']
  },

  helpOptions: {
    howMyInbiotWorks: [
      '¿Cómo funciona My Inbiot?',
      'How My Inbiot works?',
      'Wie funktioniert mein Inbiot?'
    ],
    contactUs: ['Contacta con nosotros', 'Contact us', 'Kontaktiere uns']
  },

  veryGoodIndoorAirQualityTip: [
    'Aire limpio e higiénico. No se requiere acción.',
    'Clean and hygienic air. No action required.',
    'Saubere und hygienische Luft. Keine Maßnahmen erforderlich.'
  ],
  goodIndoorAirQuality: [
    'Calidad de Aire Interior buena',
    'Good Indoor Air Quality',
    'Gute Luftqualität in Innenräumen'
  ],
  goodIndoorAirQualityTip: [
    'Buena calidad del aire sin superar umbrales de riesgo.',
    'Good air quality without exceeding risk thresholds.',
    'Gute Luftqualität ohne Überschreitung der Risikogrenzen.'
  ],
  mediumIndoorAirQuality: [
    'Calidad de Aire Interior media',
    'Medium Indoor Air Quality',
    'Mittlere Luftqualität in Innenräumen'
  ],
  mediumIndoorAirQualityTip: [
    'Calidad del aire interior no recomendada para grupos de riesgo. Sensación de disconfort en exposiciones de larga duración. Mejora la ventilación y reduce fuentes de contaminación.',
    'Indoor air quality not recommended for risk groups. Feeling of discomfort in long-term exposures Improve ventilation and reduce sources of contamination.',
    'Raumluftqualität für Risikogruppen nicht empfohlen. Gefühl des Unbehagens bei Langzeitexposition Verbesserung der Belüftung und Verringerung der Verschmutzungsquellen.'
  ],
  poorIndoorAirQuality: [
    'Calidad de Aire Interior baja',
    'Poor Indoor Air Quality',
    'Schlechte Luftqualität in Innenräumen'
  ],
  poorIndoorAirQualityTip: [
    'Calidad del aire no recomendada para exposiciones continuas. Mejora e incrementa la ventilación y reduce las fuentes de contaminación.',
    'Air quality not recommended for continuous exposures. Improve and increase ventilation and reduce sources of indoor pollutants.',
    'Die Luftqualität wird für eine kontinuierliche Exposition nicht empfohlen. Verbessern und erhöhen Sie Belüftung und Verringerung der Quellen von Schadstoffen in Innenräumen.'
  ],
  veryPoorIndoorAirQuality: [
    'Calidad de Aire Interior muy baja',
    'Very poor Indoor Air Quality',
    'Sehr schlechte Luftqualität in Innenräumen'
  ],
  veryPoorIndoorAirQualityTip: [
    'Condiciones de calidad del aire interior no adecuadas. Se necesita ventilar y mejorar las condiciones de calidad del aire interior de forma urgente.',
    'Ventilation and improvement of indoor air quality conditions is urgently needed.',
    'Die Belüftung und Verbesserung der Luftqualität in Innenräumen ist dringend erforderlich.'
  ],
  highSpreadProbability: [
    'Probabilidad de propagación alta.',
    'High probability of spread',
    'Hohe Wahrscheinlichkeit der Ausbreitung'
  ],
  highSpreadProbabilityTip: [
    'Es urgente revisar el nivel de ventilación y el aforo del espacio, ajustar las condiciones de confort termohigrométrico y reducir la presencia de partículas en suspensión.',
    'It is urgent to review the ventilation level and the space occupancy, adjust the thermo-hygrometric comfort conditions and reduce the presence of Particulate Matter.',
    'Es ist dringend erforderlich, die Belüftungsstufe und die Raumbelegung zu überprüfen, die thermohygrometrischen Komfortbedingungen anzupassen und das Vorhandensein von Feinstaub zu reduzieren.'
  ],
  mediumSpreadProbability: [
    'Probabilidad de propagación media',
    'Medium probability of spread',
    'Mittlere Wahrscheinlichkeit der Ausbreitung'
  ],
  mediumSpreadProbabilityTip: [
    'Se recomienda revisar las condiciones específicas de ventilación, confort termohigrométrico y propagación de partículas para reducir el riesgo de propagación de virus.',
    'It is recommended to review ventilation rate, thermo-hygrometric comfort and PM spread to reduce the risk of virus spread.',
    'Es wird empfohlen, die Beatmungsrate, den thermohygrometrischen Komfort und die PM-Ausbreitung zu überprüfen, um das Risiko einer Virusausbreitung zu verringern.'
  ],
  lowSpreadProbability: [
    'Probabilidad de propagación baja',
    'Low probability of spread',
    'Geringe Wahrscheinlichkeit der Ausbreitung'
  ],
  lowSpreadProbabilityTip: [
    'No se requiere acción específica',
    'No specific action is required.',
    'Es sind keine besonderen Maßnahmen erforderlich.'
  ],
  lowVentilationNeeds: [
    'No se requiere ventilar',
    'No ventilation required',
    'Keine Belüftung erforderlich'
  ],
  mediumVentilationNeeds: [
    'Se recomienda ventilar',
    'Ventilation recommended',
    'Belüftung empfohlen'
  ],
  highVentilationNeeds: [
    'Es necesario ventilar',
    'Ventilation is required',
    'Belüftung ist erforderlich'
  ],
  sensorErrorMessage: [
    'No se están recibiendo datos de este sensor. Encuentra soluciones o contáctanos.',
    'No data is being received from this sensor. Find solutions or contact us.',
    'Es werden keine Daten von diesem Sensor empfangen. Finden Sie Lösungen oder kontaktieren Sie uns.'
  ],
  optimalTemperatureTip: [
    'Rango de temperaturas óptimo para la actividad normal diurna.',
    'Optimal temperature range for normal daytime activity.',
    'Optimaler Temperaturbereich für normale Tagesaktivität.'
  ],
  lowTemperatureTip: [
    'Aumenta la temperatura del termostato hasta valores en torno a los 20-21ºC.',
    'Increase the thermostat temperature up to values around 20-21ºC.',
    'Erhöhen Sie die Thermostattemperatur auf Werte um 20-21ºC.'
  ],
  lowTemperatureTipImperial: [
    'Aumenta la temperatura del termostato hasta valores en torno a los 68-69ºF.',
    'Increase the thermostat temperature up to values around 68-69ºF.',
    'Erhöhen Sie die Thermostattemperatur auf Werte um 68-69ºF.'
  ],
  highTemperatureTip: [
    'Reduce la temperatura del termostato hasta valores en torno a los 20-21ºC',
    'Reduce the thermostat temperature down to values around 20-21ºC.',
    'Reduzieren Sie die Temperatur des Thermostats auf Werte um 20-21ºC.'
  ],
  highTemperatureTipImperial: [
    'Reduce la temperatura del termostato hasta valores en torno a los 68-69ºF',
    'Reduce the thermostat temperature down to values around 68-69ºF.',
    'Reduzieren Sie die Temperatur des Thermostats auf Werte um 68-69ºF.'
  ],
  optimalHumidityTip: [
    'Los valores de humedad relativa de entre el 40 y el 60% son adecuados para un ambiente interior saludable.',
    'Relative humidity values of 40-60% are suitable for a healthy indoor environment.',
    'Relative Luftfeuchtigkeitswerte von 40-60% sind für ein gesundes Raumklima geeignet.'
  ],
  lowHumidityTip: [
    'Aporta humedad al espacio hasta alcanzar niveles de humedad relativa de entre el 40 y el 60%.',
    'Add space humidity up to levels between 40 and 60%.',
    'Erhöhung der Luftfeuchtigkeit auf Werte zwischen 40 und 60%.'
  ],
  highHumidityTip: [
    'Revisa las fuentes de humedad y ajusta los niveles de ventilación hasta reducir los niveles de humedad relativa a 40-60%.',
    'Check sources of moisture and adjust ventilation levels down to levels between 40 and 60%.',
    'Überprüfen Sie die Feuchtigkeitsquellen und regeln Sie die Belüftung auf einen Wert zwischen 40 und 60 % herunter.'
  ],
  optimalCo2Tip: [
    'Continúa garantizando adecuada renovación de aire para mantener el CO₂ por debajo de 800 ppm.',
    'Continue to guarantee adequate air renewal to keep CO₂ below 800 ppm.',
    'Sorgen Sie weiterhin für eine ausreichende Lufterneuerung, um CO₂ unter 800 ppm zu halten.'
  ],
  mediumCo2Tip: [
    'Mejora la tasa de ventilación del espacio.',
    'Improve the ventilation rate of the space.',
    'Verbessern Sie die Belüftungsrate des Raums.'
  ],
  highCo2Tip: [
    'Incrementa la tasa de renovación de aire y ajusta el sistema de ventilación a las necesidades reales gracias a MICA.',
    'Increase the air renewal rate and adjust the Ventilation System system to real needs thanks to MICA.',
    'Erhöhen Sie die Lufterneuerungsrate und passen Sie das Belüftungssystem dank MICA an den tatsächlichen Bedarf an.'
  ],
  optimalPm25Tip: [
    'Mantén los niveles de partículas PM2.5 siempre por debajo de 15 µg/m³.',
    'Keep PM2.5 levels always below 15 µg/m³.',
    'Halten Sie die PM2,5-Werte stets unter 15 µg/m³.'
  ],
  mediumPm25Tip: [
    'Aumenta la tasa de ventilación y mejora el filtrado de partículas en suspensión.',
    'Increase ventilation rate and improve the PM (Particulate Matter) filter.',
    'Erhöhen Sie die Belüftungsrate und verbessern Sie den PM-Filter (Feinstaub).'
  ],
  highPm25Tip: [
    'Implementa sistemas de filtrado con filtros de alta eficiencia (tipo HEPA) o purificadores de aire que filtren partículas finas (hasta 0,3 micras).',
    'Implement filtration systems with high-efficiency filters (HEPA type) or air purifiers that filter fine particles (up to 0.3 microns).',
    'Verwenden Sie Filtersysteme mit hocheffizienten Filtern (HEPA-Typ) oder Luftreiniger, die feine Partikel (bis zu 0,3 Mikrometer) filtern.'
  ],
  optimalPm1Tip: [
    'Mantén los niveles de partículas PM1 siempre por debajo de 15 µg/m³.',
    'Keep PM1 levels always below 15 µg/m³.',
    'Halten Sie die PM1-Werte stets unter 15 µg/m³.'
  ],
  mediumPm1Tip: [
    'Aumenta la tasa de ventilación y mejora el filtrado de partículas en suspensión.',
    'Increase ventilation rate and improve the PM (Particulate Matter) filter.',
    'Erhöhen Sie die Belüftungsrate und verbessern Sie den PM-Filter (Feinstaub).'
  ],
  highPm1Tip: [
    'Implementa sistemas de filtrado con filtros de alta eficiencia (tipo HEPA) o purificadores de aire que filtren partículas finas (hasta 0,3 micras).',
    'Implement filtration systems with high-efficiency filters (HEPA type) or air purifiers that filter fine particles (up to 0.3 microns).',
    'Verwenden Sie Filtersysteme mit hocheffizienten Filtern (HEPA-Typ) oder Luftreiniger, die feine Partikel (bis zu 0,3 Mikrometer) filtern.'
  ],
  optimalPm4Tip: [
    'Mantén los niveles de partículas PM4 siempre por debajo de 15 µg/m³.',
    'Keep PM4 levels always below 15 µg/m³.',
    'Halten Sie die PM4-Werte stets unter 15 µg/m³.'
  ],
  mediumPm4Tip: [
    'Aumenta la tasa de ventilación y mejora el filtrado de partículas en suspensión.',
    'Increase ventilation rate and improve the PM (Particulate Matter) filter.',
    'Erhöhen Sie die Belüftungsrate und verbessern Sie den PM-Filter (Feinstaub).'
  ],
  highPm4Tip: [
    'Implementa sistemas de filtrado con filtros de alta eficiencia (tipo HEPA) o purificadores de aire que filtren partículas finas (hasta 0,3 micras).',
    'Implement filtration systems with high-efficiency filters (HEPA type) or air purifiers that filter fine particles (up to 0.3 microns).',
    'Verwenden Sie Filtersysteme mit hocheffizienten Filtern (HEPA-Typ) oder Luftreiniger, die feine Partikel (bis zu 0,3 Mikrometer) filtern.'
  ],
  optimalPm10Tip: [
    'Mantén los niveles de partículas PM10 siempre por debajo de 50 µg/m³.',
    'Keep PM10 levels always below 50 µg/m³.',
    'Halten Sie die PM10-Werte stets unter 50 µg/m³.'
  ],
  mediumPm10Tip: [
    'Aumenta la tasa de ventilación y mejora el filtrado de partículas en suspensión.',
    'Increase ventilation rate and improve the PM (Particulate Matter) filter.',
    'Erhöhen Sie die Belüftungsrate und verbessern Sie den PM-Filter (Feinstaub).'
  ],
  highPm10Tip: [
    'Implementa sistemas de filtrado con filtros de alta eficiencia (tipo HEPA) o purificadores de aire que filtren partículas finas (hasta 0,3 micras).',
    'Implement filtration systems with high-efficiency filters (HEPA type) or air purifiers that filter fine particles (up to 0.3 microns).',
    'Verwenden Sie Filtersysteme mit hocheffizienten Filtern (HEPA-Typ) oder Luftreiniger, die feine Partikel (bis zu 0,3 Mikrometer) filtern.'
  ],
  optimalFormaldehydeTip: [
    'Asegura buenos niveles de ventilación para mantener el formaldehído por debajo de 70 µg/m³ de forma continua.',
    'Ensure good ventilation levels to keep formaldehyde below 70 µg/m³ continuously.',
    'Sorgen Sie für eine gute Belüftung, um Formaldehyd dauerhaft unter 70 µg/m³ zu halten.'
  ],
  mediumFormaldehydeTip: [
    'Revisa defectos en los materiales de acabado o equipamiento y utiliza productos sellantes para formaldehído.',
    'Check for defects in finishing materials or equipment and use formaldehyde sealants.',
    'Prüfen Sie, ob die Endbearbeitungsmaterialien oder -geräte Mängel aufweisen, und verwenden Sie Formaldehyd-Dichtungsmittel.'
  ],
  highFormaldehydeTip: [
    'Revisa defectos en los materiales de acabado o equipamiento, utiliza productos sellantes para formaldehído y selecciona productos sustitutivos para las fuentes de emisión detectadas, mientras aumenta la tasa de ventilación.',
    'Check for defects in finishing materials or equipment, use formaldehyde sealants and selects substitute products for detected emission sources, while increasing ventilation rate.',
    'Prüfen Sie auf Mängel an den Ausbaumaterialien oder Geräten, verwenden Sie Formaldehyd-Dichtstoffe und wählen Sie Ersatzprodukte für festgestellte Emissionsquellen, während Sie die Lüftungsrate erhöhen.'
  ],
  optimalVocsTip: [
    'Asegura buenos niveles de ventilación para mantener los COVs por debajo de 220 ppb de forma continua.',
    'Ensure good ventilation levels to keep VOCs below 220 ppb continuously.',
    'Sorgen Sie für eine gute Belüftung, um die VOC-Werte kontinuierlich unter 220 ppb zu halten.'
  ],
  mediumVocsTip: [
    'Ajusta los niveles de ventilación para reducir la concentración de COVs.',
    'Adjust ventilation levels to reduce VOCs concentration.',
    'Passen Sie die Belüftungsstufen an, um die VOC-Konzentration zu verringern.'
  ],
  highVocsTip: [
    'Implanta un sistema de ventilación eficaz, un sistema de purificación de aire con tecnología específica para COVs y utiliza productos sustitutivos de bajas emisiones.',
    'Implement an effective ventilation system, an air purification system with specific technology for VOCs and use low-emission substitute materials and products.',
    'Einführung eines wirksamen Belüftungssystems, eines Luftreinigungssystems mit spezieller Technologie für flüchtige organische Verbindungen und Verwendung emissionsarmer Ersatzstoffe und -produkte.'
  ],

  // Sign up page
  signUpPage: {
    alreadyHaveAnAccount: [
      '¿Ya tienes una cuenta?',
      'Already have an account?',
      'Haben Sie bereits einen Account?'
    ]
  },

  newGroupForm: {
    selectDevices: ['Seleccionar dispositivos:', 'Select devices:', 'Geräte auswählen:']
  },

  editGroupForm: {
    selectDevices: ['Seleccionar dispositivos para añadir', 'Select devices to add'],
    groupDevices: ['Dispositivos en el grupo', 'Group devices']
  },

  confirmUserInfoPage: {
    header: [
      'Verificar dirección de correo electrónico',
      'Verify e-mail address',
      'Email Adresse bestätigen'
    ],
    subHeader: [
      'Te hemos enviado un mail a ',
      'An email has been sent to ',
      'Eine email wurde gesendet an '
    ],
    mailInstructions: [
      'Pulsa el botón "Verificar correo" y podrás acceder a My inBiot.',
      'Press the "Verify email" button and you could access to My inBiot.',
      'Drücken Sie die Schaltfläche "E-Mail bestätigen" und Sie können auf Mein inBiot zugreifen.'
    ],
    noMailReceived: [
      'Si no has recibido ningún correo: ',
      'If you have not received any email: ',
      'Falls Sie keine E-Mail erhalten haben: '
    ],
    resendCode: [
      'Volver a enviar correo de verificación',
      'Resend verification email',
      'Bestätigungsmail erneut senden'
    ],
    nothingReceived: [
      'Si sigues sin recibir nada, prueba a: ',
      'If you still do not receive anything, try: ',
      'Wenn Sie immer noch nichts erhalten, versuchen Sie Folgendes: '
    ],
    backToSignUp: [
      'Volver a crear cuenta',
      'Go back to sign up',
      'Gehen Sie zurück, um sich anzumelden'
    ]
  },

  // Confirm Sign Up page
  confirmUserPage: {
    header: [
      'Dirección de correo verificada con éxito.',
      'E-mail account has been successfully verified.',
      'E-Mail-Konto wurde erfolgreich verifiziert'
    ],
    subHeader: [
      '¡Todo listo! Ya puedes acceder a My inBiot.',
      'All ready! You can now access My inBiot.',
      'Alles bereit! Sie können jetzt auf Mein inBiot zugreifen.'
    ],
    goToLogin: ['Acceder a My inBiot', 'Go to login', 'Gehe zu Login']
  },

  acceptPolicyandTerms: {
    header: [
      'He leído y acepto la',
      'I have read and accept the',
      'Ich habe die AGB gelesen und stimme diesen zu.'
    ],
    policy: ['Política de Privacidad', 'Privacy Policy', 'Datenschutzrichtlinien'],
    terms: ['Términos de Uso', 'Terms of Use', 'Nutzungsbedingungen'],
    adding: ['y', 'and', 'und']
  },
  // Forgotten password page
  forgottenPasswordPage: {
    header: ['Contraseña olvidada', 'Forgot password', 'Passwort vergessen'],
    subHeader: [
      'Escribe tu dirección de correo electrónico y te enviaremos instrucciones para restablecer la contraseña.',
      'Please enter your email address and we will send you the instructions to restore the password.',
      'Bitte geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen die Anweisungen zum Wiederherstellen des Passworts'
    ],
    submitButton: ['Mándame un link', 'Send me a link', 'Sende mir einen Link'],
    restore: ['Restablecer', 'Restore', 'Wiederherstellen'],
    returnTo: ['Volver a ', 'Return to ', 'Zurücksenden an ']
  },

  // Recover password page
  recoverPasswordPage: {
    header: ['Recuperar contraseña', 'Recover password', 'Passwort wiederherstellen'],
    subHeader: [
      'Se ha enviado un enlace a tu correo para proceder al cambio de contraseña.',
      'A changing password link has been sent to your mail.',
      'Ein Link zum Ändern des Passworts wurde an Ihre E-Mail gesendet.'
    ],
    submitButton: ['Restablecer contraseña', 'Restore password', 'Passwort wiederherstellen']
  },

  homePage: {
    editGroup: ['Editar grupo', 'Edit group', 'Gruppe bearbeiten'],
    deleteGroup: ['Eliminar grupo', 'Delete group', 'Gruppe löschen']
  },

  disabledSystem: [
    'El sensor ha sido desactivado.',
    'This system is disabled.',
    'Dieses System ist deaktiviert.'
  ],

  disabledSystemExpanded: [
    'El sensor ha sido desactivado.' +
      '<br />' +
      "Por favor contáctanos en <a href='mailto:info@inbiot.es?Subject=Mi sensor está desactivado' target='_top'>info@inbiot.es</a>.",
    'This system is disabled.' +
      '<br />' +
      "Please contact us at <a href='mailto:info@inbiot.es?Subject=My system is disabled' target='_top'>info@inbiot.es</a>.",
    'Dieses System ist deaktiviert.' +
      '<br />' +
      "Bitte kontaktieren Sie uns unter <a href='mailto:info@inbiot.es?Subject=My system is disabled' target='_top'>info@inbiot.es</a>."
  ],

  downloadDataForm: {
    selectDevices: ['Seleccionar dispositivos', 'Select devices', 'Geräte auswählen'],
    selectParameters: [
      'Seleccionar parámetros a mostrar',
      'Select parameters to display',
      'Wählen Sie die anzuzeigenden Parameter aus'
    ],
    selectValues: [
      'Seleccionar valores a alertar',
      'Select values to alert',
      'Wählen Sie Werte für die Warnung aus'
    ],
    selectAll: ['Seleccionar todos', 'Select all', 'Alle auswählen'],
    selectDateInterval: [
      'Seleccionar intervalo de fechas',
      'Select date range',
      'Select date range',
      'Datumsbereich auswählen'
    ],
    getMoreRange: [
      '¿Cómo accedo a un mayor rango de datos?',
      'How do I access a wider range of data?',
      'Wie erhalte ich Zugang zu einer breiteren Palette von Daten?'
    ],
    downloadFormat: ['Seleccionar formato', 'Select format', 'Format auswählen'],
    downloadExtension: ['Extensión de archivo:', 'File extension: ', 'Dateierweiterung:'],
    statisticsInterval: [
      'Intervalo de estadísticos',
      'Statistics interval',
      'Statistisches Intervall'
    ],
    downloadOrder: ['Orden datos:', 'Data order:', 'Bestellung von Daten:'],
    olderDataFirst: ['Más antiguos primero', 'Older first', 'Ältere zuerst'],
    latestDataFirst: ['Más recientes primero', 'Latest first', 'Neueste zuerst'],
    dataGrouping: ['Agrupación datos', 'Data grouping', 'Gruppierung der Daten'],
    originalData: ['Datos originales', 'Original data', 'Ursprüngliche Daten'],
    grouping10: ['Medias 10min', 'Average 10min', 'Durchschnittlich 10min'],
    grouping15: ['Medias 15min', 'Average 15min', 'Durchschnittlich 15min'],
    grouping30: ['Medias 30min', 'Average 30min', 'Durchschnittlich 30min'],
    grouping1: ['Medias 1h', 'Average 1h', 'Strümpfe 1h']
  },

  uploadFileModal: {
    title: ['Configuración logotipo', 'Logo configuration', 'Logokonfiguration'],
    currentLogo: ['Logotipo actual', 'Current logo', 'aktuelles Logo'],
    noLogo: [
      'Actualmente no hay ningún archivo subido',
      'There are currently no files uploaded',
      'Es sind derzeit keine Dateien hochgeladen'
    ],
    deleteLogoConfirmationText: [
      '¿Estás seguro de que quieres eliminar el logo?',
      'You are going to delete this logo. Are you sure about it?',
      'Sie werden dieses Logo löschen. Sind Sie sich dessen sicher?'
    ],
    uploadFile: ['Subir archivo', 'Upload file', 'Datei hochladen'],
    deleteFile: ['Eliminar', 'Delete', 'Löschen'],
    selectFile: [
      'Selecciona el fichero que desea cargar',
      'Select the file to be uploaded',
      'Wählen Sie die hochzuladende Datei'
    ],
    upload: ['Subir', 'Upload', 'Hochladen'],
    saveChanges: ['Guardar cambios', 'Save changes', 'Änderungen speichern'],
    cancel: ['Cancelar', 'Cancel', 'Abbrechen']
  },
  fullScreenPage: {
    screens: ['Pantallas', 'Screens', 'Bildschirme'],
    shareables: ['Compartibles', 'Shareables', 'Shareables'],
    searchShareable: ['Buscar compartible', 'Search shareable', 'Suche gemeinsam nutzbar'],
    newScreen: ['Nueva pantalla', 'New screen', 'Neuer Bildschirm'],
    uploadLogo: ['Logotipo', 'Logo', 'Logo'],
    newShareable: ['Nuevo compartible', 'New shareable', 'Neue Teilung'],
    createScreen: ['Crear pantalla', 'Create screen', 'Bildschirm erstellen'],
    createShareable: ['Crear compartible', 'Create shareable', 'Gemeinsam nutzbar machen'],
    saveChanges: ['Guardar cambios', 'Save changes', 'Save changes'],
    editSharable: ['Editar compartible', 'Edit shareable', 'Edit shareable'],
    deleteSharable: ['Borrar compartible', 'Delete shareable', 'Delete shareable'],
    infoPublic: [
      'Si desactivas esta opción el link compartible a tu dispositivo MICA quedará inutilizado y nadie podrá acceder a él.' +
        'Tampoco se mostrará el código QR en la Pantalla Completa. Puedes editarlo más tarde en editar compartible. ',
      'If you disable this option, the shareable link to your MICA device will be unused and no one will be able to access it. ' +
        'The QR code will also not be displayed on the Full Screen. You can edit it later in edit shareable.',
      'Wenn Sie diese Option deaktivieren, wird der gemeinsame Link zu Ihrem MICA-Gerät nicht genutzt und niemand kann darauf zugreifen.' +
        'Der QR-Code wird auch nicht im Vollbildmodus angezeigt. Sie können es später in Edit Shareable bearbeiten.'
    ],
    savedScreens: ['Vistas guardadas', 'Saved views', 'Gespeicherte Ansichten'],
    qrDownload: ['Descargar QR', 'Download QR', 'Download QR'],
    downloadQRCertificate: [
      'Descargar certificado QR',
      'Download QR certificate',
      'Download QR certificate'
    ],
    downloadQR: ['Descargar código QR', 'Download QR code', 'Download QR code'],
    share: ['Compartir', 'Share', 'Share'],
    shareDeviceLink: ['Copiar enlace a MICA', 'Copy link to device', 'Copy link to device'],
    shareFullscreenLink: [
      'Copiar enlace a pantalla completa',
      'Copy link to fullscreen',
      'Copy link to fullscreen'
    ],
    editScreen: ['Editar pantalla', 'Edit Screen', 'Edit Screen'],
    deleteScreen: ['Borrar pantalla', 'Delete Screen', 'Delete Screen'],
    generateFullScreenView: [
      'Generar una nueva vista en pantalla completa',
      'Generate a new full screen view',
      'Generieren Sie eine neue Vollbildansicht'
    ],
    generateFullScreenViewInfo: [
      'Crea una vista personalizada con los parámetros que desees visualizar de tu MICA.',
      "Create a custom view with your device's IAQ parameters that you want to visualize.",
      'Erstellen Sie eine benutzerdefinierte Ansicht mit den IAQ-Parametern Ihres Geräts, die Sie visualisieren möchten.'
    ],
    chooseDevice: ['Selecciona tu MICA', 'Choose your MICA', 'Wähle dein MICA'],
    chooseDeviceInfo: [
      'Selecciona el dispositivo que quieres que se visualice en pantalla completa.',
      'Choose the device that you want to display in full screen view',
      'Wählen Sie das Gerät aus, das Sie im Vollbildmodus anzeigen möchten'
    ],
    customizingView: [
      'Personaliza la vista de tu dispositivo',
      'Custom your device view',
      'Passen Sie Ihre Geräteansicht an'
    ],
    customizingViewInfo: [
      'Selecciona el formato de visualización y los parámetros visibles en la vista.',
      'Choose the view format and the shown parameters',
      'Wählen Sie das Ansichtsformat und die angezeigten Parameter'
    ],
    parameters: ['Parametros visibles', 'Shown parameters', 'Angezeigte Parameter'],
    selectParameters: [
      'Seleccionar parámetros a mostrar',
      'Select parameters to display',
      'Wählen Sie die anzuzeigenden Parameter aus'
    ],
    selectAll: ['Seleccionar todos', 'Select all', 'Alle auswählen'],
    infoLabelRadio: [
      'Acceso público al MICA',
      'Public access to MICA',
      'Öffentlicher Zugang zu MICA'
    ],
    infoShowLogo: ['Mostrar logotipo personal', 'Show personal logo', 'Persönliches Logo anzeigen'],
    howToShowLogo: [
      '¿Cómo muestro mi logotipo en el compartible?',
      'How do I show my logo in the shareable?',
      'Wie zeige ich mein Logo in der Freigabe?'
    ],
    viewType: ['Tipo de vista', 'View type', 'Ansichtstyp'],
    detailedView: ['Vista en detalle', 'Detailed view', 'Detaillierte Ansicht'],
    detailedViewInfo: [
      'Muestra las gráficas de todos los parámetros',
      'Shows charts for all parameters',
      'Zeigt Diagramme für alle Parameter an'
    ],
    overviewView: ['Vista en resumen', 'Overview view', 'Übersichtsansicht'],
    overviewViewInfo: [
      'Muestra un resumen de los parámetros',
      'Shows a parameter overview',
      'Zeigt eine Parameterübersicht'
    ],
    openScreen: ['Ver pantalla', 'Open screen', 'Bildschirm öffnen'],
    screenViewName: ['Nombrar la pantalla', 'Screen View name', 'Benennen Sie den Bildschirm'],
    veryGoodIAQ: [
      'Muy buena Calidad de Aire Interior',
      'Very good Indoor Air Quality',
      'Very good Indoor Air Quality'
    ],
    veryBadIAQ: [
      'Muy mala Calidad de Aire Interior',
      'Very bad Indoor Air Quality',
      'Very bad Indoor Air Quality'
    ],
    normalIAQ: [
      'Calidad de Aire Interior normal',
      'Regular Indoor Air Quality',
      'Regular Indoor Air Quality'
    ],
    veryLowProbability: [
      'Probabilidad de propagación de virus muy baja',
      'Very low virus propagation probability',
      'Very low virus propagation probability'
    ],
    lowProbability: [
      'Probabilidad de propagación de virus baja',
      'Low virus propagation probability',
      'Low virus propagation probability'
    ],
    mediumProbability: [
      'Probabilidad de propagación de virus media',
      'Medium virus propagation probability',
      'Medium virus propagation probability'
    ],
    highProbability: [
      'Probabilidad de propagación de virus alta',
      'High virus propagation probability',
      'High virus propagation probability'
    ],
    micaLiteHeading: [
      'Probabilidad de propagación de virus',
      'Virus propagation probability',
      'Virus propagation probability'
    ],
    micaMiniHeading: ['Necesidad de ventilación', 'Ventilation need', 'Ventilation need'],
    micaHeading: ['Calidad de aire interior', 'Indoor Air Quality', 'Indoor Air Quality'],
    smartphoneText: [
      'Visualiza estas mediciones y mucho más en tu smartphone',
      'View this data and more information in your smartphone',
      'View this data and more information in your smartphone'
    ],
    discoverMore: ['Descubre más en: ', 'Discover more in: ', 'Discover more in: '],
    lastUpdated: ['Última actualización hace: ', 'Last update: ', 'Last update: '],
    outdoorWeather: ['Tiempo exterior', 'Outdoor Weather', 'Outdoor Weather']
  },

  outdoorWeather: {
    clearSky: ['Cielo despejado', 'Clear sky', 'Klarer himmel'],
    fewClouds: ['Pocas nubes', 'Few clouds', 'Wenige wolken'],
    scatteredClouds: ['Nubes dispersas', 'Scattered Clouds', 'Vereinzelte wolken'],
    brokenClouds: ['Nubes rotas', 'Broken clouds', 'Zerrissene wolken'],
    showerRain: ['Lluvias y chubascos', 'Shower rain', 'Dusche regen'],
    rain: ['Lluvia', 'Rain', 'Regen'],
    thunderstorm: ['Tormenta eléctrica', 'Thunderstorm', 'Gewittersturm'],
    snow: ['Nieve', 'Snow', 'Schnee'],
    mist: ['Niebla', 'Mist', 'Nebel']
  },

  alertsPage: {
    alerts: ['Alertas', 'Alarms', 'Benachrichtigungen'],
    newAlert: ['Nueva alerta', 'New alarm', 'Neue Benachrichtigung'],
    savedAlerts: ['Alertas guardadas', 'Saved alarms', 'Gespeicherte Benachrichtigungen'],
    searchAlerts: ['Buscar alerta', 'Search alert', 'Suchmeldung'],
    editAlert: ['Editar alerta', 'Edit alert', 'Alarm bearbeiten'],
    deleteAlert: ['Borrar alerta', 'Delete alert', 'Alarm löschen'],
    alarmName: ['Nombrar la alarma', 'Alarm name', 'Alarmname']
  },

  // Popups de información
  infoModal: {
    temperature: [
      'Temperatura: ' +
        'El rango de temperaturas que se considera confort va de los 21 a los 25ºC, siendo, según el RITE (Reglamento de Instalaciones Térmicas), ' +
        '21-23ºC en invierno y 23-25ºC en verano, aunque dichos parámetros pueden verse aumentados y/o disminuidos en función del resto de variables que condicionan el confort.',
      'You received this sensor id after configuring your sensor formatted as XXXX-XXXX-XXXX.',
      'Sie haben diese Sensor-ID erhalten, nachdem Sie Ihren Sensor im Format XXXX-XXXX-XXXX konfiguriert haben.'
    ],
    humidity: [
      'Humedad: ' +
        'La humedad relativa interior ideal para una temperatura normal del interior de un espacio habitado se sitúa entre el 45 ' +
        'y el 50% de humedad, con un margen de valores recomendables entre el 40 y el 60%.',
      'You received this sensor id after configuring your sensor formatted as XXXX-XXXX-XXXX.',
      'Sie haben diese Sensor-ID erhalten, nachdem Sie Ihren Sensor im Format XXXX-XXXX-XXXX konfiguriert haben.'
    ],
    co2: [
      'CO2:' +
        'El dióxido de carbono es un gas inodoro, incoloro e insípido. Se trata de una molécula muy estable y, a priori, no es un gas tóxico, pero sí puede llegar a ser asfixiante' +
        'por desplazamiento del oxígeno en concentraciones muy elevadas. La medición del CO₂ es un buen indicador para evaluar la calidad del aire interior de un espacio y de su tasa de renovación de aire.',
      'You received this sensor id after configuring your sensor formatted as XXXX-XXXX-XXXX.',
      'Sie haben diese Sensor-ID erhalten, nachdem Sie Ihren Sensor im Format XXXX-XXXX-XXXX konfiguriert haben.'
    ],
    formaldehyde: [
      'Ha recibido un id de sensor al finalizar el proceso de configuración con formato XXXX-XXXX-XXXX.',
      'You received this sensor id after configuring your sensor formatted as XXXX-XXXX-XXXX.',
      'Sie haben diese Sensor-ID erhalten, nachdem Sie Ihren Sensor im Format XXXX-XXXX-XXXX konfiguriert haben.'
    ],
    vocs: [
      'Ha recibido un id de sensor al finalizar el proceso de configuración con formato XXXX-XXXX-XXXX.',
      'You received this sensor id after configuring your sensor formatted as XXXX-XXXX-XXXX.',
      'Sie haben diese Sensor-ID erhalten, nachdem Sie Ihren Sensor im Format XXXX-XXXX-XXXX konfiguriert haben.'
    ],
    pm25: [
      'Ha recibido un id de sensor al finalizar el proceso de configuración con formato XXXX-XXXX-XXXX.',
      'You received this sensor id after configuring your sensor formatted as XXXX-XXXX-XXXX.',
      'Sie haben diese Sensor-ID erhalten, nachdem Sie Ihren Sensor im Format XXXX-XXXX-XXXX konfiguriert haben.'
    ],
    pm10: [
      'Ha recibido un id de sensor al finalizar el proceso de configuración con formato XXXX-XXXX-XXXX.',
      'You received this sensor id after configuring your sensor formatted as XXXX-XXXX-XXXX.',
      'Sie haben diese Sensor-ID erhalten, nachdem Sie Ihren Sensor im Format XXXX-XXXX-XXXX konfiguriert haben.'
    ],
    pm1: [
      'Ha recibido un id de sensor al finalizar el proceso de configuración con formato XXXX-XXXX-XXXX.',
      'You received this sensor id after configuring your sensor formatted as XXXX-XXXX-XXXX.',
      'Sie haben diese Sensor-ID erhalten, nachdem Sie Ihren Sensor im Format XXXX-XXXX-XXXX konfiguriert haben.'
    ],
    iaq: [
      'Ha recibido un id de sensor al finalizar el proceso de configuración con formato XXXX-XXXX-XXXX.',
      'You received this sensor id after configuring your sensor formatted as XXXX-XXXX-XXXX.',
      'Sie haben diese Sensor-ID erhalten, nachdem Sie Ihren Sensor im Format XXXX-XXXX-XXXX konfiguriert haben.'
    ],
    moreInfo: ['Ampliar información', 'More information', 'Mehr Informationen']
  },
  // System page
  systemPage: {
    macAddressHelper: [
      'Ha recibido un id de sensor al finalizar el proceso de configuración con formato XXXX-XXXX-XXXX.',
      'You received this sensor id after configuring your sensor formatted as XXXX-XXXX-XXXX.',
      'Sie haben diese Sensor-ID erhalten, nachdem Sie Ihren Sensor im Format XXXX-XXXX-XXXX konfiguriert haben.'
    ],
    countryHelper: [
      'El país y código postal se utilizan para medir el tiempo exterior.',
      'The country and zip code are used to track the outside weather data.',
      'Das Land und die Postleitzahl werden verwendet, um die Außenwetterdaten zu verfolgen'
    ],
    zipCodeHelper: [
      'Introduzca solo los dígitos del código postal.',
      'Enter ONLY the digits of the zip code.',
      'Geben Sie nur die Ziffern der Postleitzahl ein'
    ],
    isPublicHelper: [
      'Si marcas el sensor como público podrás compartir tus datos con otros.',
      'Marking this sensor as public will allow you to share this data with others.',
      'Wenn Sie diesen Sensor als öffentlich markieren, können Sie diese Daten mit anderen teilen'
    ],
    parametersBuilding: [
      'Parámetros del entorno del dispositivo',
      'Device environment parameters',
      'Parameter der Geräteumgebung'
    ],
    typeBuilding: ['Tipo de edificio', 'Building type', 'Gebäudetyp'],
    antiquityBuilding: ['Antigüedad del edificio', 'Building age', 'Baualter'],
    ventilationBuilding: ['Tipo de ventilación', 'Type of ventilation', 'Art der Belüftung'],
    airTightnessLevelBuilding: [
      'Nivel de hermeticidad',
      'Building airtightness level',
      'Luftdichtheitsgrad des Gebäudes'
    ],
    occupancyBuilding: ['Ocupación', 'Occupancy classification', 'Belegungsklassifizierung'],
    typeOfUseBuilding: ['Tipo de uso', 'Type of use', 'Nutzungsart'],
    typeOfSystem: ['Tipo de dispositivo', 'Type of device', 'Gerätetyp']
  },

  // tos: {
  //   firstSentance: [
  //     'TODO: spanish',
  //     'By clicking on Sign Up, you agree to InBiot\'s'
  //   ],
  //   termsAndConditions: [
  //     'TODO: spanish',
  //     'Terms & Conditions'
  //   ],
  //   and: [
  //     'TODO: spanish',
  //     'and'
  //   ],
  //   privacyPolicy: [
  //     'TODO: spanish',
  //     'Privacy Policy'
  //   ]
  // },

  // Snackbar
  snackbar: {
    whoops: [
      'Oops, algo ha ido mal. Por favor inténtelo otra vez.',
      'Whoops, something went wrong. Please try again or refresh the page.',
      'Hoppla, da ist etwas schief gelaufen. Bitte versuchen Sie es erneut oder aktualisieren Sie die Seite.'
    ],
    expiredToken: [
      'Tu token ha caducado. Inicie sesión otra vez.',
      'Your token has expired. Please login again.',
      'Ihr Token ist abgelaufen. Bitte melden Sie sich erneut an.'
    ],
    signUpSuccess: [
      'Se ha creado tu cuenta. Inicie sesión.',
      "You're successfully signed up. Now it's time to login.",
      'Sie haben sich erfolgreich angemeldet. Jetzt ist es Zeit sich anzumelden'
    ],
    confirmationCodeSent: [
      'Se ha enviado un correo de confirmación a tu cuenta.',
      'A confirmation mail has been sent to your account',
      'Eine Bestätigungs-E-Mail wurde an Ihr Konto gesendet'
    ],
    confirmSignUpSuccess: [
      'Cuenta verificada con éxito. Inicie sesión.',
      "You have verified successfully your account. Now it's time to login.",
      'Sie haben Ihr Konto erfolgreich verifiziert. Jetzt ist es Zeit sich anzumelden.'
    ],
    resendConfirmationMailSuccess: [
      'Se ha reenviado el correo de confirmación.',
      'Confirmation mail has been sent successfully.',
      'Bestätigungsmail wurde erfolgreich versendet.'
    ],
    resendConfirmationMailFailure: [
      'Ha habido un problema enviando el correo de confirmación.',
      'There was a problem when resending confirmation mail. ',
      'Beim erneuten Senden der Bestätigungsmail ist ein Problem aufgetreten.'
    ],
    addedSuccessful: ['Añadido con éxito', 'Successfully added', 'Erfolgreich hinzugefügt'],
    savedSuccessful: ['Guardado con éxito', 'Successfully saved', 'Erfolgreich gespeichert'],
    deleteSuccessful: ['Eliminado con éxito', 'Successfully deleted', 'Erfolgreich gelöscht'],
    createGroupSuccessful: ['Grupo creado con éxito.', 'Successfully created group.'],
    updateGroupSuccessful: ['Grupo actualizado con éxito.', 'Successfully updated group.'],
    deleteGroupSuccessful: ['Grupo borrado con éxito.', 'Successfully deleted group.'],
    deleteFromGroupSuccessful: ['Eliminado de grupo con éxito', 'Successfully deleted from group'],
    createScreenSuccessful: ['Pantalla creada con éxito', 'Successfully created screen'],
    updateScreenSuccessful: ['Pantalla editada con éxito', 'Successfully updated screen'],
    deleteScreenSuccessful: ['Pantalla borrada con éxito', 'Successfully deleted screen'],
    createAlarmSuccessful: [
      'Alarma creada con éxito',
      'Successfully created alarm',
      'Erfolgreich erstellter Alarm'
    ],
    updateAlarmSuccessful: [
      'Alarma actualizada con éxito',
      'Successfully updated alarm',
      'Erfolgreich aktualisierter Alarm'
    ],
    deleteAlarmSuccessful: [
      'Alarma eliminada con éxito',
      'Successfully deleted alarm',
      'Erfolgreich gelöschter Alarm'
    ],
    alarmActivatedSuccessful: [
      'Alarma reactivada con éxito',
      'Alarm successfully reactivated',
      'Alarm erfolgreich reaktiviert'
    ],
    alarmDesactivatedSuccessful: [
      'Alarma desactivada con éxito',
      'Alarm successfully deactivated',
      'Alarm erfolgreich abgeschaltet'
    ],
    forgottenPasswordSuccess: [
      'Se ha solicitado un cambio de contraseña. Por favor consulte su correo electronico.',
      'Reset password requested. Please check your email.',
      'Passwort zurücksetzen angefordert. Bitte überprüfen Sie Ihre E-Mail.'
    ],
    recoverPasswordSuccess: [
      'La contraseña se ha cambiado con éxito.',
      'Succesfully resetted password.',
      'Passwort erfolgreich zurückgesetzt.'
    ],
    logoUploadedSuccessful: [
      'Logo subido con éxito',
      'Logo uploaded successfully',
      'Logo erfolgreich hochgeladen'
    ],
    logoDeletedSuccessful: [
      'Logo eliminado con éxito',
      'Logo deleted successfully',
      'Logo erfolgreich gelöscht'
    ],
    sharableLinkCopiedToClipboard: [
      'El link se ha copiado en el portapapeles.',
      'Shareable link is copied to clipboard.',
      'Der teilbare Link wird in die Zwischenablage kopiert'
    ],
    publicApiKeyLinkCopiedToClipboard: [
      'Api Key copiada en el portapapeles.',
      'Api Key link is copied to clipboard.',
      'Der API-Key-Link wird in die Zwischenablage kopiert.'
    ],
    noPublicApiKeyLinkCopiedToClipboard: [
      'Esta funcionalidad es de pago. Si quieres solicitar un API key contacta con info@inbiot.es',
      'This functionality is not free. If you need a public API key contact info@inbiot.es for more details.',
      'Diese Funktion ist nicht kostenlos. Wenn Sie einen öffentlichen API-Schlüssel benötigen, wenden Sie sich an info@inbiot.es, um weitere Informationen zu erhalten.'
    ],
    zipCodeNotAvailable: [
      'Lo sentimos... No disponemos de datos de tiempo exterior para el código postal introducido',
      'Sorry... We do not have weather data for the zip code inserted',
      'Entschuldigung... Wir haben keine Wetterdaten für die eingegebene Postleitzahl'
    ],
    ScreenNameAlreadyExists: [
      'Ya existe un compartible con este nombre para este dispositivo. Inténtelo con otro nombre.',
      'There is already a shareable with this name for this device. Please try it with another name.',
      'There is already a shareable with this name for this device. Please try it with another name.'
    ]
  },

  // Submit errors
  submitErrors: {
    unknown: [
      'Error desconocido. Por favor inténtelo más tarde.',
      'Unknown error. Please try again later.',
      'Unbekannter Fehler. Bitte versuchen Sie es später erneut.'
    ],
    invalidLogin: [
      'Correo electrónico o contraseña erróneos.',
      'Wrong email or password.',
      'Falsche Email oder Passwort.'
    ],
    checkEmail: [
      'Por favor, comprueba el correo asociado a tu cuenta (incluso carpeta Spam), ya que ha habido un cambio en la plataforma.',
      'Please check the email associated with your account (including the Spam folder), as there has been a change in the platform.',
      'Bitte überprüfen Sie die mit Ihrem Konto verknüpfte E-Mail (einschließlich des Spam-Ordners), da es eine Änderung der Plattform gegeben hat.'
    ],
    userNotConfirmed: [
      'El usuario no ha sido confirmado.',
      'User has not been confirmed yet.',
      'Benutzer wurde noch nicht bestätigt.'
    ],
    emailInUse: [
      'La dirección de correo electrónico que ha introducido esta en uso',
      'Email address already in use.',
      'E-Mail-Adresse wird schon verwendet.'
    ],
    invalidPassword: ['Contrasenña incorrecta', 'Incorrect password', 'Falsches Passwort'],
    systemNotFound: [
      'No hemos encontrado un sensor que se corresponda al sensor ID introducido. Asegúrese de que el sensor ID es correcto.',
      'We were unable to find a sensor with the submitted sensor id. Please make sure the sensor id is correct.',
      'Wir konnten keinen Sensor mit der übermittelten Sensor-ID finden. Bitte stellen Sie sicher, dass die Sensor-ID korrekt ist.'
    ],
    systemAlreadyInUse: [
      'El sensor que está intentando añadir pertenece a otro usuario.',
      "The sensor you're trying to add is already used by another user.",
      'Der Sensor, den Sie hinzufügen möchten, wird bereits von einem anderen Benutzer verwendet.'
    ],
    systemAlreadyInOtherAlarm: [
      'Este dispositivo ya tiene una alarma configurada.',
      'This sensor has already a configured alert.',
      'Dieser Sensor hat bereits einen konfigurierten Alarm.'
    ],
    unknownEmail: [
      'Dirección de correo electrónico desconocida.',
      'Unknown email address.',
      'Unbekannte E-Mail-Adresse.'
    ],
    invalideRecoverPassword: [
      'La recuperacion de contraseña ha caducado.',
      'Password recovery is expired.',
      'Die Passwortwiederherstellung ist abgelaufen.'
    ],
    incorrectOldPassword: [
      'La contraseña antigua es incorrecta',
      'Old password is incorrect',
      'das alte Passwort ist falsch'
    ],
    passwordLimitExceeded: [
      'Has superado el límite de intentos. Inténtelo en un tiempo.',
      'You have exceeded the attemps limit. Try it again in some time.',
      'Sie haben das Versuchslimit überschritten. Versuchen Sie es nach einiger Zeit noch einmal.'
    ],
    channelNotFound: [
      'No hemos podido encontrar el sensor.',
      'We were not able to find this channel.',
      'Wir konnten diesen Kanal nicht finden.'
    ],
    channelForbidden: [
      'Este sensor no es público.',
      'This is not a public channel.',
      'Dies ist kein öffentlicher Kanal.'
    ],
    invalidVerificationCode: [
      'El código de verificación introducido no es correcto.',
      'The verification code is not correct.',
      'Der Bestätigungscode ist nicht korrekt.'
    ],
    expiredVerificationCode: [
      'El código de verificación del correo ha expirado. Solicite un nuevo código.',
      'The specified e-mail verification code has expired. Request another code.'
    ],
    expiredRecoveringVerificationCode: [
      'El código de verificación ha expirado. Solicite un código nuevamente.',
      'The specified email verification code has expired. Request another code.',
      'Der angegebene E-Mail-Bestätigungscode ist abgelaufen. Fordern Sie einen anderen Code an.'
    ],
    userAlreadyConfirmed: [
      'El usuario ya ha sido confirmado.',
      'This user has already been confirmed.',
      'Dieser Benutzer wurde bereits bestätigt.'
    ]
  },

  // Validation
  validation: {
    required: ['Campo requerido', 'Required', 'Erforderlich'],
    anyFieldRequired: [
      'Debes seleccionar al menos un tipo de dato',
      'You should choose at least one data type',
      'Sie sollten mindestens einen Datentyp auswählen'
    ],
    fieldValuesRequired: [
      'Debes introducir valores en todos los parámetros',
      'You must enter values for all parameters',
      'Sie müssen für alle Parameter Werte eingeben'
    ],
    anyDeviceRequired: [
      'Debes seleccionar al menos un dispositivo',
      'You should choose at least one device',
      'Sie sollten mindestens ein Gerät auswählen'
    ],
    deviceRequired: [
      'Debes seleccionar un dispositivo',
      'You should choose one device',
      'Sie sollten sich für ein Gerät entscheiden'
    ],
    timeIntervalAllowed: [
      'Tu plan actual solo permite un mínimo de 10 minutos.',
      'Your current plan only allows a minimum of 10 minutes.',
      'Ihr aktueller Plan erlaubt nur ein Minimum von 10 Minuten.'
    ],
    maxFieldsAllowed: [
      'El número de parámetros no puede ser superior a 8.',
      'Number of parameters can not exceed 8.',
      'Anzahl der Parameter darf 8 . nicht überschreiten'
    ],
    anyFileExtensionRequired: [
      'Debes seleccionar un tipo de extension',
      'You should choose one file extension type',
      'Sie sollten einen Dateierweiterungstyp auswählen'
    ],
    noDataAvailable: [
      'No dispones de ningún dato en las fechas seleccionadas. Elige otro intervalo.',
      'There is no data available in the selected date interval. Change it and try again.',
      'Im ausgewählten Datumsintervall sind keine Daten verfügbar. Ändern Sie es und versuchen Sie es erneut.'
    ],
    inValidEmail: ['Correo electrónico no válido', 'Invalid email', 'Ungültige E-Mail'],
    inValidPassword: [
      'La contraseña necesita al menos 6 caracteres',
      'Password must have at least 6 characters',
      'Passwort muss mindestens 6 Zeichen haben'
    ],
    invalidRepeatPassword: [
      'Las contraseñas deben coincidir.',
      'Passwords should match.',
      'Passwörter sollten übereinstimmen.'
    ],
    invalidFormat: ['Formato inválido.', 'Invalid format.', 'Ungültiges Format'],
    invalidDateRange: [
      'Rango de fechas inválido',
      'Invalid date range',
      'Ungültiger Datumsbereich'
    ],
    numbersOnly: ['Solo números', 'Numbers only', 'Nur Zahlen'],
    dataRangeToBig: ['No más de un mes', 'Not more than a month', 'Nicht länger als einen Monat'],
    maxValueCO2: [
      'El valor máximo es 5000 ppm',
      'Max value is 5000 ppm',
      'Maximalwert ist 5000 ppm'
    ],
    minValueCO2: ['El valor mínimo es 400 ppm', 'Min value is 400 ppm', 'Mindestwert ist 400 ppm']
  },
  sortOptions: {
    orderGroupBy: ['Ordenar grupos por ', 'Sort groups by ', 'Gruppen sortieren nach '],
    orderMicaBy: ['Ordenar MICAs por ', 'Sort MICAs by ', 'MICAs sortieren nach '],
    GroupsAZ: ['Grupos de la A-Z', 'Groups from A-Z', 'Gruppen von A-Z'],
    devicesAZ: ['MICAs de la A-Z', 'MICAs from A-Z', 'MICAs von A-Z'],
    withMoreMicas: [
      'Grupos con más MICAs primero',
      'Groups with more MICAs first',
      'Gruppen mit mehr MICAs zuerst'
    ],
    withLessMicas: [
      'Grupos con menos MICAs primero',
      'Groups with less MICAs first',
      'Gruppen mit weniger MICAs zuerst'
    ],
    lastAdded: [
      'Últimos MICAs añadidos primero',
      'Last added MICAs first',
      'Neueste MICAs zuerst hinzugefügt'
    ],
    worstMeasuresFirst: [
      'MICAs más críticos primero',
      'More critical MICAs first',
      'Kritischere MICAs zuerst'
    ],
    bestMeasuresFirst: [
      'MICAs menos críticos primero',
      'Less critical MICAs first',
      'Weniger kritische MICs zuerst'
    ]
  },
  // Data types
  pressurePip: ['Pressure PIP', 'Pressure PIP', 'Pressure PIP'],
  oscRate: ['Osc Rate', 'Osc Rate', 'Osc Rate'],
  rateMin: ['Rate Min', 'Rate Min', 'Rate Min'],
  volume: ['Volume', 'Volume', 'Volume'],
  ei: ['E/I', 'E/I', 'E/I'],
  pTrigger: ['P-Trigger', 'P-Trigger', 'P-Trigger'],
  ventilationMode: ['Modo de Ventilación', 'Ventilation Mode', 'Ventilation Mode'],
  temperature: ['Temperatura', 'Temperature', 'Temperatur'],
  humidity: ['Humedad', 'Humidity', 'Feuchtigkeit'],
  co2: ['CO₂', 'CO₂', 'CO₂'],
  formaldehyde: ['Formaldehído', 'Formaldehyde', 'Formaldehyd'],
  vocs: ['COVs', 'TVOCs', 'TVOCs'],
  vocsIndex: ['TVOC', 'TVOC', 'TVOC'],
  pm25: ['PM2.5', 'PM2.5', 'PM2.5'],
  pm10: ['PM10', 'PM10', 'PM10'],
  pm4: ['PM4.0', 'PM4.0', 'PM4.0'],
  pm1: ['PM1.0', 'PM1.0', 'PM1.0'],
  radon: ['Radón', 'Radon', 'Radon'],
  weather: ['Tiempo exterior', 'Weather', 'Wetter'],
  outdoorTemperature: ['Temperatura exterior', 'Outdoor temperature', 'Außentemperatur'],
  outdoorHumidity: ['Humedad exterior', 'Outdoor humidity', 'Außenluftfeuchtigkeit'],
  baseStationInfo: [
    'Localización de la estación base',
    'Base station location',
    'Base station location'
  ],
  outdoorAirQuality: ['Calidad de aire exterior', 'Outdoor Air Quality', 'Outdoor Air Quality'],
  localAqi: ['AQI Local', 'Local AQI', 'Local AQI'],
  dominantPollutant: ['Contaminante principal', 'Dominant Pollutant', 'Dominant Pollutant'],
  category: ['Nivel de calidad', 'Quality Level', 'Quality Level'],
  co: ['CO', 'CO', 'CO'],
  c6h6: ['C₆H₆', 'C₆H₆', 'C₆H₆'],
  o3: ['O₃', 'O₃', 'O₃'],
  covid19: ['Indicador virus', 'Virus indicator', 'Virenindikator'],
  no: ['NO', 'NO', 'NO'],
  nox: ['NOₓ', 'NOₓ', 'NOₓ'],
  no2: ['NO₂', 'NO₂', 'NO₂'],
  so2: ['SO₂', 'SO₂', 'SO₂'],
  iaq: ['IAQ', 'IAQ', 'IAQ'],
  temperatureControl: ['Temperatura de consigna', 'Control temperature', 'Control temperature'],
  radiantTemperature: ['Temperatura Radiante', 'Radiant Temperature', 'Strahlende Temperatur'],
  lux: ['Iluminancia', 'Illuminance', 'Beleuchtungsstärke'],
  dB: ['Nivel de ruido', 'Noise level', 'Geräuschpegel'],
  capacity: ['Aforo actual', 'Current capacity', 'Derzeitige Kapazität'],
  pressure: ['Presión', 'Pressure', 'Luftdruck'],
  dust: ['Partículas en suspensión', 'Dust', 'Feinstaub'],
  graphRange: ['Rango de gráfica', 'Graph range', 'Diagrammbereich'],
  measurementUnit: ['Unidad de medida', 'Measurement unit', 'Maßeinheit'],
  rangeValuePlaceholder: ['i.e. 24', 'e.g. 24', 'z.B. 24'],
  rangeUnitPlaceholder: ['i.e. horas', 'e.g. hours', 'z.B. Stunden'],
  goodIndicator: [
    'Estás en el rango de confort',
    'You are in the confort range',
    'Du bist im Komfortbereich'
  ],
  regularIndicator: [
    'Estás fuera del rango de confort',
    'You are out of the confort range',
    'Du bist außerhalb des Komfortbereichs'
  ],
  badIndicator: [
    'Estás en un rango peligroso',
    'You are in a dangerous range',
    'Du bist in einem gefährlichen bereich'
  ],
  veryLowProbability: ['MUY BAJA', 'VERY LOW', 'SEHR NIEDRIG'],
  lowProbability: ['BAJA', 'LOW', 'NIEDRIG'],
  mediumProbability: ['MEDIA', 'MEDIUM', 'MITTEL'],
  highProbability: ['ALTA', 'HIGH', 'HOCH'],
  propagationProbability: [
    'Probabilidad de propagación: ',
    'Propagation probability: ',
    'Ausbreitungswahrscheinlichkeit: '
  ],
  one_day_button: ['1D', '1D', '1T'],
  one_week_button: ['1S', '1W', '1W'],
  one_month_button: ['1M', '1M', '1M'],
  csv: ['CSV', 'CSV', 'CSV'],
  xlsx: ['XLSX', 'XLSX', 'XLSX'],
  // Graph range units
  graphRangeUnits: {
    hours: ['Horas', 'Hours', 'Stunden'],
    days: ['Días', 'Days', 'Tage'],
    weeks: ['Semanas', 'Weeks', 'Wochen'],
    months: ['Meses', 'Months', 'Monate'],
    years: ['Años', 'Years', 'Jahre']
  },

  // Temperature measurement units
  temperatureMeasurementUnits: {
    kelvin: ['Kelvin', 'Kelvin', 'Kelvin'],
    celsius: ['Grados celsius', 'Celsius', 'Celsius'],
    fahrenheit: ['Grados fahrenheit', 'Fahrenheit', 'Fahrenheit']
  },

  // Full screen sidebar
  // TODO: check names, do they make sense?
  checkValues: [
    'Ahora puedes ver los datos en tu móvil!',
    'Now you can check these values on your phone!',
    'Jetzt können Sie diese Werte auf Ihrem Telefon überprüfen!'
  ],
  inbiotLink: ['www.inbiot.es', 'www.inbiot.es'],
  knowMore: ['¿Quieres saber más?', 'Do you want to know more?', 'Möchten Sie mehr wissen?'],
  visitWeb: ['Visítanos en', 'Visit us on', 'Besuchen Sie'],
  copyrightInbiot: [
    'Copyright ©2019 INBIOT MONITORING SL',
    'Copyright ©2019 INBIOT MONITORING SL',
    'Copyright ©2019 INBIOT MONITORING SL'
  ],

  // Data tab panel
  temperatureInfo: [
    '<b>1. Descripción</b><br/>' +
      'La temperatura seca del aire es el parámetro habitual para determinar el confort térmico en el interior de una estancia. La temperatura del aire indica su nivel de energía calorífica con referencia a un nivel mínimo - 0 absoluto (0 K = -273,15 ºC).<br/>' +
      'El confort térmico en el organismo se mantiene gracias a la homeotermia, que equilibra las ganancias y pérdidas de calor para mantener la temperatura corporal entre los 36 y los 38ºC.<br/><br/>' +
      '<b>2. Rangos recomendados</b><br/>' +
      'MICA sólo establece un rango de valores de riesgo para la temperatura interior, en el caso de proyectos de evaluación de la calidad de aire interior, ya que deben evaluarse conjuntamente con otros parámetros como la humedad relativa, principalmente, debido a la variabilidad de los factores que determinan el confort.  Estos valores de confort están comprendidos entre 20 - 25ºC.<br/>' +
      'El rango de temperaturas que se considera confort va de los 21 a los 25ºC, siendo, según el RITE (Reglamento de Instalaciones Térmicas), 21-23ºC en invierno y 23-25ºC en verano, aunque dichos parámetros pueden verse aumentados y/o disminuidos en función del resto de variables que condicionan el confort.<br/><br/>' +
      '<b>3. Unidad de medida</b><br/>' +
      'ºC - grado Celsius pertenece al sistema internacional de unidades para expresar las unidades de temperatura de uso cotidiano, entendiendo la temperatura como la unidad física que representa el nivel de calor . La escala Celsius utiliza como referencia el punto de fusión del hielo para su valor 0, y el punto de ebullición del agua, para su valor 100, siempre a presión atmosférica normal, según la definición inicial del siglo XIX. Desde 1948 se eligió como único punto de referencia el punto triple del agua (0,01ºC) y se define su valor a partir de la unidad de temperatura absoluta, el Kelvin: <br/>' +
      'Tª (ºC) = T (K) - 273,15 <br/> <br/>' +
      '<b>4. Fuentes</b><br/>' +
      'El confort térmico viene determinado por la temperatura interior del aire, la temperatura superficial, el tipo de fuente de calor, la humedad relativa y el movimiento del aire, por lo que la fuente de una temperatura interior baja, elevada o de confort, depende de factores diversos. <br/>' +
      'Las principales fuentes que condicionan la temperatura interior son la radiación solar directa o indirecta, las fuentes internas como ocupación o equipos, así como los sistemas de calefacción y climatización, el tipo de ventilación utilizado y el sistema constructivo de la envolvente térmica del edificio. <br/><br/>' +
      '<b>5. Beneficios de rangos óptimos de temperatura</b><br/>' +
      'Una temperatura adecuada tiene beneficios directos en el confort y bienestar térmico, implica una adecuada respiración, garantiza el aporte de oxígeno requerido, mantiene un ambiente interior confortable y facilita la capacidad de concentración.<br/><br/>' +
      '<b>6. Riesgos de valores bajos de temperatura</b><br/>' +
      'A temperaturas excesivamente bajas, el frío afecta a los músculos más profundos, reduciendo su capacidad y favoreciendo la rigidez articular y la capacidad para el desarrollo de actividades mentales se puede ver reducida. <br/><br/>' +
      '<b>7. Riesgos de valores altos de temperatura</b><br/>' +
      'Las temperaturas interiores elevadas favorecen el crecimiento y la dispersión de bacterias y hongos. Se disminuye la capacidad de concentración y rendimiento, aumenta el ritmo cardíaco, favorece la somnolencia y el malestar. <br/><br/>' +
      '<b>8. Recomendaciones de mejora</b><br/>' +
      'Para una evaluación del efecto de la temperatura interior es preciso un análisis global de la temperatura, la humedad, el movimiento del aire, la actividad metabólica y la vestimenta para establecer recomendaciones óptimas. De esta manera, y de forma combinada con otros factores como la humedad o la tasa de renovación de aire, tanto la temperatura del aire interior como la de las superficies que envuelven un espacio determinarán la sensación térmica. <br/>' +
      'Los rangos de temperaturas recomendables en interiores variarán en función del tipo de actividad a realizar, aunque de forma generalizada se recomiendan los siguientes rangos: <br/>' +
      '<ul>' +
      '<li>18ºC < Tª < 21ºC – Rango de temperaturas óptimo para el descanso nocturno. Temperaturas moderadamente bajas favorecen la respiración nocturna, en parte debido al aumento del oxígeno cuando baja ligeramente la temperatura.</li>' +
      '<li>21ºC < Tª < 26ºC – Rango de temperaturas óptimo para el confort para la actividad normal diurna.</li>' +
      '<li>Fomentar los sistemas de calefacción radiante, que permiten un óptimo secado de los materiales de construcción y permiten a su vez una temperatura del aire inferior, con una temperatura superficial superior, garantizando el confort.</li>' +
      '</ul>',

    '<b>1. Descripción</b><br/>' +
      'La temperatura seca del aire es el parámetro habitual para determinar el confort térmico en el interior de una estancia. La temperatura del aire indica su nivel de energía calorífica con referencia a un nivel mínimo - 0 absoluto (0 K = -273,15 ºC).<br/>' +
      'El confort térmico en el organismo se mantiene gracias a la homeotermia, que equilibra las ganancias y pérdidas de calor para mantener la temperatura corporal entre los 36 y los 38ºC.<br/><br/>' +
      '<b>2. Rangos recomendados</b><br/>' +
      'MICA sólo establece un rango de valores de riesgo para la temperatura interior, en el caso de proyectos de evaluación de la calidad de aire interior, ya que deben evaluarse conjuntamente con otros parámetros como la humedad relativa, principalmente, debido a la variabilidad de los factores que determinan el confort.  Estos valores de confort están comprendidos entre 20 - 25ºC.<br/>' +
      'El rango de temperaturas que se considera confort va de los 21 a los 25ºC, siendo, según el RITE (Reglamento de Instalaciones Térmicas), 21-23ºC en invierno y 23-25ºC en verano, aunque dichos parámetros pueden verse aumentados y/o disminuidos en función del resto de variables que condicionan el confort.<br/><br/>' +
      '<b>3. Unidad de medida</b><br/>' +
      'ºC - grado Celsius pertenece al sistema internacional de unidades para expresar las unidades de temperatura de uso cotidiano, entendiendo la temperatura como la unidad física que representa el nivel de calor . La escala Celsius utiliza como referencia el punto de fusión del hielo para su valor 0, y el punto de ebullición del agua, para su valor 100, siempre a presión atmosférica normal, según la definición inicial del siglo XIX. Desde 1948 se eligió como único punto de referencia el punto triple del agua (0,01ºC) y se define su valor a partir de la unidad de temperatura absoluta, el Kelvin: <br/>' +
      'Tª (ºC) = T (K) - 273,15 <br/> <br/>' +
      '<b>4. Fuentes</b><br/>' +
      'El confort térmico viene determinado por la temperatura interior del aire, la temperatura superficial, el tipo de fuente de calor, la humedad relativa y el movimiento del aire, por lo que la fuente de una temperatura interior baja, elevada o de confort, depende de factores diversos. <br/>' +
      'Las principales fuentes que condicionan la temperatura interior son la radiación solar directa o indirecta, las fuentes internas como ocupación o equipos, así como los sistemas de calefacción y climatización, el tipo de ventilación utilizado y el sistema constructivo de la envolvente térmica del edificio. <br/><br/>' +
      '<b>5. Beneficios de rangos óptimos de temperatura</b><br/>' +
      'Una temperatura adecuada tiene beneficios directos en el confort y bienestar térmico, implica una adecuada respiración, garantiza el aporte de oxígeno requerido, mantiene un ambiente interior confortable y facilita la capacidad de concentración.<br/><br/>' +
      '<b>6. Riesgos de valores bajos de temperatura</b><br/>' +
      'A temperaturas excesivamente bajas, el frío afecta a los músculos más profundos, reduciendo su capacidad y favoreciendo la rigidez articular y la capacidad para el desarrollo de actividades mentales se puede ver reducida. <br/><br/>' +
      '<b>7. Riesgos de valores altos de temperatura</b><br/>' +
      'Las temperaturas interiores elevadas favorecen el crecimiento y la dispersión de bacterias y hongos. Se disminuye la capacidad de concentración y rendimiento, aumenta el ritmo cardíaco, favorece la somnolencia y el malestar. <br/><br/>' +
      '<b>8. Recomendaciones de mejora</b><br/>' +
      'Para una evaluación del efecto de la temperatura interior es preciso un análisis global de la temperatura, la humedad, el movimiento del aire, la actividad metabólica y la vestimenta para establecer recomendaciones óptimas. De esta manera, y de forma combinada con otros factores como la humedad o la tasa de renovación de aire, tanto la temperatura del aire interior como la de las superficies que envuelven un espacio determinarán la sensación térmica. <br/>' +
      'Los rangos de temperaturas recomendables en interiores variarán en función del tipo de actividad a realizar, aunque de forma generalizada se recomiendan los siguientes rangos: <br/>' +
      '<ul>' +
      '<li>18ºC < Tª < 21ºC – Rango de temperaturas óptimo para el descanso nocturno. Temperaturas moderadamente bajas favorecen la respiración nocturna, en parte debido al aumento del oxígeno cuando baja ligeramente la temperatura.</li>' +
      '<li>21ºC < Tª < 26ºC – Rango de temperaturas óptimo para el confort para la actividad normal diurna.</li>' +
      '<li>Fomentar los sistemas de calefacción radiante, que permiten un óptimo secado de los materiales de construcción y permiten a su vez una temperatura del aire inferior, con una temperatura superficial superior, garantizando el confort.</li>' +
      '</ul>'
  ],
  humidityInfoN: [
    '<h4> Humedad </h4>' +
      '<br/>Los niveles recomendados de humedad relativa para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 40 – 60 %</li>' +
      '<li>Amarillo/Alerta: 30 – 40% / 60 – 70%  </li>' +
      '<li>Rojo/Alarma: < 30 % y > 70% </li>' +
      '</ul>',
    '<h4> Humidity </h4>' +
      '<br/>Recommended relative humidity levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 40 – 60 %</li>' +
      '<li>Yellow/Warning: 30 – 40% / 60 – 70% </li>' +
      '<li>Red/Alarm: < 30 % y > 70% </li>' +
      '</ul>'
  ],
  humidityInfo: [
    '<b>1. Descripción</b><br/>' +
      'La humedad relativa (%), expresa el grado de saturación del aire a partir de la relación entre la cantidad de vapor de agua contenida en el aire (humedad absoluta) y la máxima cantidad de vapor de agua que el aire sería capaz de contener a esa misma temperatura (humedad de saturación). Su valor por tanto depende de la temperatura interior del aire y es un claro indicador de la cantidad de vapor de agua presente en el aire interior. <br/>' +
      'La humedad absoluta (g/m³) es la cantidad de vapor de agua contenido en un determinado volumen de aire. <br/><br/>' +
      '<b>2. Niveles recomendados</b><br/>' +
      'La humedad relativa interior ideal para una temperatura normal del interior de un espacio habitado se sitúa entre el 45 y el 50% de humedad, con un margen de valores recomendables entre el 40 y el 60%.<br/><br/>' +
      'Rangos MyInBiot:' +
      '<ul>' +
      '<li>VERDE – valores HR 40 – 60 % </li>' +
      '<li>AMARILLO – valores HR 30 – 40% / 60 – 70% </li>' +
      '<li>ROJO – valores HR < 30 % y  HR > 70% </li>' +
      '</ul><br/>' +
      '<b>3. Unidad de medida: %</b><br/>' +
      'La humedad relativa del aire interior se expresa como cociente (%) entre la cantidad de agua que el aire realmente contiene a una determinada temperatura y la cantidad que podría contener si estuviera saturado a la misma temperatura.<br/><br/>' +
      '<b>4. Fuentes</b><br/>' +
      'Las fuentes más comunes de una humedad relativa elevada en un espacio interior se deben a un exceso de vapor de agua contenido en el aire interior, cuyo origen puede estar en:' +
      '<ul>' +
      '<li>Condensaciones: es una de las principales causas de aparición de humedad en un espacio interior. Esta se genera cuando el aire interior, cargado de vapor de agua debido al uso normal de un edificio (respiración, evaporación de agua en cocinas o baños…) entra en contacto con las superficies frías de cerramientos exteriores. </li>' +
      '<li>Capilaridad: las humedades por capilaridad se producen debido al proceso natural de ascenso capilar del agua contenida en el subsuelo a través de los materiales de construcción en contacto con el terreno (cimentaciones, soleras, zócalos), sin una adecuada barrera horizontal impermeable que impida este ascenso capilar. </li>' +
      '<li>Patologías constructivas debido a una incorrecta ejecución de detalles constructivos y/o impermeabilizaciones, que conllevan la filtración de agua en el interior del edificio o al humedecimiento de los materiales de construcción. Esto supone por un lado la pérdida de sus propiedades físicas básicas, así como un mayor riesgo de proliferación de moho por una consecuente tasa de humedad relativa elevada. </li>' +
      '<li>Condiciones meteorológicas externas con alta humedad ambiental. </li>' +
      '<li>Utilización del edificio: la ocupación humana es fuente de emisión de vapor de agua. Una persona en estado de reposo emite aproximadamente 60 gramos de vapor de agua por hora, en función de las condiciones interiores de humedad y temperatura, así como de la actividad. </li>' +
      '</ul><br/>' +
      '<b>5. Beneficios de una óptima humedad relativa</b><br/>' +
      'Durante el proceso de inspirar y espirar, con una humedad ambiental adecuada desde el punto de vista fisiológico de entre un 45 y un 50%, el aire exhalado es capaz de absorber mayor cantidad de vapor de agua que con una humedad relativa más elevada. Y este hecho es beneficioso ya que durante la aspiración expelemos sustancias tóxicas resultantes de nuestra propia actividad metabólica.<br/>' +
      'La humedad relativa debe mantenerse entre el 40 y el 60%. Valores puntual y ligeramente inferiores no deberían ser motivo de alarma. La humedad relativa depende de la temperatura ambiente por lo que una ligera bajada en este parámetro, hará aumentar la HR.<br/><br/>' +
      '<b>6. Riesgos de humedad relativa elevada</b><br/>' +
      'La humedad contenida en el aire modifica en gran medida sus propiedades e influye enormemente en el confort de las personas. Desde el punto de vista fisiológico, en un espacio con elevada humedad atmosférica se respira con mayor dificultad. Aumenta además la proporción de gérmenes patógenos en el aire y existe una mayor probabilidad de proliferación de mohos, con mayor riesgo de desarrollar enfermedades fúngicas y reacciones alérgicas por contacto con esporas y mohos. El aire húmedo produce sensación de fatiga y genera malos olores. Además hay que tener en cuenta un mayor deterioro de los materiales de construcción, un mayor consumo energético y el mayor riesgo de proliferación de mohos.<br/><br/>' +
      '<b>7. Riesgos de una baja humedad relativa</b><br/>' +
      'Los niveles de humedad muy bajos contribuyen a la irritación y sequedad de las mucosas respiratorias y oculares – garganta seca, resfriados, sequedad ocular, molestias cutáneas. Además, hay una mayor proliferación de polvo y microorganismos en suspensión y se favorece la carga electrostática sobre la superficie de paramentos y mobiliario.<br/><br/>' +
      '<b>8. Recomendaciones de mejora</b><br/>' +
      'Las recomendaciones básicas para mejorar los efectos de una humedad relativa excesivamente alta o baja, se basan principalmente en las siguientes recomendaciones:' +
      '<ul>' +
      '<li>Evitar cualquier situación con HR >60%. Esto implica vigilar las principales fuentes de generación de humedad dentro del edificio, los posibles puentes térmicos, condensaciones superficiales e intersticiales, posibles fugas o posibles patologías constructivas que causen esta humedad relativa elevada.</li>' +
      '<li>Emplear materiales de acabado y equipamiento que no generen ni acumulen polvo</li>' +
      '<li>Utilizar sistemas de filtrado y aspirado con filtros de alta eficiencia tipo HEPA y limpiar adecuadamente cualquier acumulación de polvo en radiadores para evitar polvo en suspensión</li>' +
      '<li>Fomentar la utilización de materiales con propiedades higroscópicas que permitan la amortiguación de fluctuaciones de humedad debido a un uso normal del edificio (cocinas, baños, dormitorios)</li>' +
      '<li>Optimizar el sistema de ventilación para garantizar la ventilación puntual necesaria para picos de producción de humedad interior, y la ventilación continua para una óptima calidad del aire interior</li>' +
      '<li>Para humedades relativas bajas de forma continuada es recomendable la utilización de plantas de interior</li>' +
      '<li>Se debe vigilar la utilización de humidificadores o deshumidificadores por el alto riesgo de favorecer la proliferación de gérmenes. En caso de ser imprescindible su utilización, se recomienda programar su funcionamiento en base a valores medidos reales</li>' +
      '</ul>',

    '<b>1. Descripción</b><br/>' +
      'La humedad relativa (%), expresa el grado de saturación del aire a partir de la relación entre la cantidad de vapor de agua contenida en el aire (humedad absoluta) y la máxima cantidad de vapor de agua que el aire sería capaz de contener a esa misma temperatura (humedad de saturación). Su valor por tanto depende de la temperatura interior del aire y es un claro indicador de la cantidad de vapor de agua presente en el aire interior. <br/>' +
      'La humedad absoluta (g/m³) es la cantidad de vapor de agua contenido en un determinado volumen de aire. <br/><br/>' +
      '<b>2. Niveles recomendados</b><br/>' +
      'La humedad relativa interior ideal para una temperatura normal del interior de un espacio habitado se sitúa entre el 45 y el 50% de humedad, con un margen de valores recomendables entre el 40 y el 60%.<br/>' +
      'Rangos MyInBiot:' +
      '<ul>' +
      '<li>VERDE – valores HR 40 – 60 % </li>' +
      '<li>AMARILLO – valores HR 30 – 40% / 60 – 70% </li>' +
      '<li>ROJO – valores HR < 30 % y  HR> 70% </li>' +
      '</ul><br/>' +
      '<b>3. Unidad de medida: %</b><br/>' +
      'La humedad relativa del aire interior se expresa como cociente (%) entre la cantidad de agua que el aire realmente contiene a una determinada temperatura y la cantidad que podría contener si estuviera saturado a la misma temperatura.<br/><br/>' +
      '<b>4. Fuentes</b><br/>' +
      'Las fuentes más comunes de una humedad relativa elevada en un espacio interior se deben a un exceso de vapor de agua contenido en el aire interior, cuyo origen puede estar en:' +
      '<ul>' +
      '<li>Condensaciones: es una de las principales causas de aparición de humedad en un espacio interior. Esta se genera cuando el aire interior, cargado de vapor de agua debido al uso normal de un edificio (respiración, evaporación de agua en cocinas o baños…) entra en contacto con las superficies frías de cerramientos exteriores. </li>' +
      '<li>Capilaridad: las humedades por capilaridad se producen debido al proceso natural de ascenso capilar del agua contenida en el subsuelo a través de los materiales de construcción en contacto con el terreno (cimentaciones, soleras, zócalos), sin una adecuada barrera horizontal impermeable que impida este ascenso capilar. </li>' +
      '<li>Patologías constructivas debido a una incorrecta ejecución de detalles constructivos y/o impermeabilizaciones, que conllevan la filtración de agua en el interior del edificio o al humedecimiento de los materiales de construcción. Esto supone por un lado la pérdida de sus propiedades físicas básicas, así como un mayor riesgo de proliferación de moho por una consecuente tasa de humedad relativa elevada. </li>' +
      '<li>Condiciones meteorológicas externas con alta humedad ambiental. </li>' +
      '<li>Utilización del edificio: la ocupación humana es fuente de emisión de vapor de agua. Una persona en estado de reposo emite aproximadamente 60 gramos de vapor de agua por hora, en función de las condiciones interiores de humedad y temperatura, así como de la actividad. </li>' +
      '</ul><br/>' +
      '<b>5. Beneficios de una óptima humedad relativa</b><br/>' +
      'Durante el proceso de inspirar y espirar, con una humedad ambiental adecuada desde el punto de vista fisiológico de entre un 45 y un 50%, el aire exhalado es capaz de absorber mayor cantidad de vapor de agua que con una humedad relativa más elevada. Y este hecho es beneficioso ya que durante la aspiración expelemos sustancias tóxicas resultantes de nuestra propia actividad metabólica.<br/>' +
      'La humedad relativa debe mantenerse entre el 40 y el 60%. Valores puntual y ligeramente inferiores no deberían ser motivo de alarma. La humedad relativa depende de la temperatura ambiente por lo que una ligera bajada en este parámetro, hará aumentar la HR.<br/><br/>' +
      '<b>6. Riesgos de humedad relativa elevada</b><br/>' +
      'La humedad contenida en el aire modifica en gran medida sus propiedades e influye enormemente en el confort de las personas. Desde el punto de vista fisiológico, en un espacio con elevada humedad atmosférica se respira con mayor dificultad. Aumenta además la proporción de gérmenes patógenos en el aire y existe una mayor probabilidad de proliferación de mohos, con mayor riesgo de desarrollar enfermedades fúngicas y reacciones alérgicas por contacto con esporas y mohos. El aire húmedo produce sensación de fatiga y genera malos olores. Además hay que tener en cuenta un mayor deterioro de los materiales de construcción, un mayor consumo energético y el mayor riesgo de proliferación de mohos.<br/><br/>' +
      '<b>7. Riesgos de una baja humedad relativa</b><br/>' +
      'Los niveles de humedad muy bajos contribuyen a la irritación y sequedad de las mucosas respiratorias y oculares – garganta seca, resfriados, sequedad ocular, molestias cutáneas. Además, hay una mayor proliferación de polvo y microorganismos en suspensión y se favorece la carga electrostática sobre la superficie de paramentos y mobiliario.<br/><br/>' +
      '<b>8. Recomendaciones de mejora</b><br/>' +
      'Las recomendaciones básicas para mejorar los efectos de una humedad relativa excesivamente alta o baja, se basan principalmente en las siguientes recomendaciones:' +
      '<ul>' +
      '<li>Evitar cualquier situación con HR >60%. Esto implica vigilar las principales fuentes de generación de humedad dentro del edificio, los posibles puentes térmicos, condensaciones superficiales e intersticiales, posibles fugas o posibles patologías constructivas que causen esta humedad relativa elevada.</li>' +
      '<li>Emplear materiales de acabado y equipamiento que no generen ni acumulen polvo</li>' +
      '<li>Utilizar sistemas de filtrado y aspirado con filtros de alta eficiencia tipo HEPA y limpiar adecuadamente cualquier acumulación de polvo en radiadores para evitar polvo en suspensión</li>' +
      '<li>Fomentar la utilización de materiales con propiedades higroscópicas que permitan la amortiguación de fluctuaciones de humedad debido a un uso normal del edificio (cocinas, baños, dormitorios)</li>' +
      '<li>Optimizar el sistema de ventilación para garantizar la ventilación puntual necesaria para picos de producción de humedad interior, y la ventilación continua para una óptima calidad del aire interior</li>' +
      '<li>Para humedades relativas bajas de forma continuada es recomendable la utilización de plantas de interior</li>' +
      '<li>Se debe vigilar la utilización de humidificadores o deshumidificadores por el alto riesgo de favorecer la proliferación de gérmenes. En caso de ser imprescindible su utilización, se recomienda programar su funcionamiento en base a valores medidos reales</li>' +
      '</ul>'
  ],
  co2InfoN: [
    '<h4> CO2 </h4>' +
      '<br/>Los niveles recomendados de CO2 para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 800 ppm</li>' +
      '<li>Amarillo/Alerta: 800 – 1500 ppm </li>' +
      '<li>Rojo/Alarma: > 1500 ppm </li>' +
      '</ul>',
    '<h4> CO2 </h4>' +
      '<br/>Recommended CO2 levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 800 ppm</li>' +
      '<li>Yellow/Warning: 800 – 1500 ppm </li>' +
      '<li>Red/Alarm: > 1500 ppm </li>' +
      '</ul>'
  ],
  co2Info: [
    '<b>1. Descripción</b><br/>' +
      'El dióxido de carbono es un gas inodoro, incoloro e insípido. Se trata de una molécula muy estable y, a priori, no es un gas tóxico, pero sí puede llegar a ser asfixiante por desplazamiento del oxígeno en concentraciones muy elevadas. <br/>' +
      'La medición del CO₂ es un buen indicador para evaluar la calidad del aire interior de un espacio y de su tasa de renovación de aire.<br/><br/>' +
      '<b>2. Niveles recomendados </b><br/>' +
      '<ul>' +
      '<li>En ambientes exteriores el nivel de concentración de CO₂ es de aproximadamente 350-400 ppm (partes por millón).</li>' +
      '<li>El ambiente se considera “cargado” en valores por encima de los 800 - 1.000 ppm.</li>' +
      '<li>A partir de los 2000 ppm se considera muy cargado y se producen síntomas relacionados con dolor de cabeza, cansancio y apatía general.</li>' +
      '<li>Los efectos más graves se producen a partir de 5.000 ppm, cuando se pueden llegar a producir desvanecimientos.</li>' +
      '</ul><br/>' +
      'Rangos MyInBiot:' +
      '<ul>' +
      '<li>VERDE: < 800 ppm</li>' +
      '<li>AMARILLO: 800 – 1500 ppm </li>' +
      '<li>ROJO: > 1500 ppm</li>' +
      '</ul><br/>' +
      '<b>3. Unidad de medida: ppm </b><br/>' +
      'Las Partes por millón (ppm) es una unidad de medida de concentración que mide la cantidad de unidades de un compuesto o sustancia por cada millón de unidades del conjunto.<br/><br/>' +
      '<b>4. Fuentes</b><br/>' +
      'El CO₂ es emitido por los seres humanos y animales en el proceso de respiración. También es el resultado de la combustión de cualquier sustancia con contenido en carbono, de modo que estufas mal selladas u otros procesos de combustión interior o un exceso de ocupación sin adecuada regulación de la renovación de aire, suelen incrementar los niveles de CO₂.<br/><br/>' +
      '<b>5. Beneficios de bajo nivel de concentración</b><br/>' +
      'Un espacio interior con bajos niveles de CO₂ es representativo de un espacio con una adecuada renovación de aire. El aire fresco cuenta con niveles de CO₂ en torno a los 400 ppm.<br/><br/>' +
      '<b>6. Riesgos ante alta concentración</b><br/>' +
      'Niveles altos de CO₂ mantenidos en el tiempo provocan fatiga, malestar, aumento del ritmo respiratorio, somnolencia, falta de concentración y disminución de la productividad.<br/><br/>' +
      '<b>7. Recomendaciones de mejora</b><br/>' +
      'La principal recomendación es la ventilación, que deberá ser proporcional al ritmo de producción de CO₂ en el interior, principalmente debido a la ocupación de personas y animales o a la presencia de sistemas de combustión (estufas, chimeneas, cocinas, etc.). <br/>' +
      'En caso de haber calderas o estufas en el interior, es recomendable que sean lo más estancas posibles, facilitar el aporte de aire exterior directo y mantener el tiro alto en los momentos de encendido.',

    '<b>1. Descripción</b><br/>' +
      'El dióxido de carbono es un gas inodoro, incoloro e insípido. Se trata de una molécula muy estable y, a priori, no es un gas tóxico, pero sí puede llegar a ser asfixiante por desplazamiento del oxígeno en concentraciones muy elevadas. <br/>' +
      'La medición del CO₂ es un buen indicador para evaluar la calidad del aire interior de un espacio y de su tasa de renovación de aire.<br/><br/>' +
      '<b>2. Niveles recomendados </b><br/>' +
      '<ul>' +
      '<li>En ambientes exteriores el nivel de concentración de CO₂ es de aproximadamente 350-400 ppm (partes por millón).</li>' +
      '<li>El ambiente se considera “cargado” en valores por encima de los 800 - 1.000 ppm.</li>' +
      '<li>A partir de los 2000 ppm se considera muy cargado y se producen síntomas relacionados con dolor de cabeza, cansancio y apatía general.</li>' +
      '<li>Los efectos más graves se producen a partir de 5.000 ppm, cuando se pueden llegar a producir desvanecimientos.</li>' +
      '</ul><br/>' +
      'Rangos MyInBiot:' +
      '<ul>' +
      '<li>VERDE: < 800 ppm</li>' +
      '<li>AMARILLO: 800 – 1500 ppm </li>' +
      '<li>ROJO: > 1500 ppm</li>' +
      '</ul><br/>' +
      '<b>3. Unidad de medida: ppm </b><br/>' +
      'Las Partes por millón (ppm) es una unidad de medida de concentración que mide la cantidad de unidades de un compuesto o sustancia por cada millón de unidades del conjunto.<br/><br/>' +
      '<b>4. Fuentes</b><br/>' +
      'El CO₂ es emitido por los seres humanos y animales en el proceso de respiración. También es el resultado de la combustión de cualquier sustancia con contenido en carbono, de modo que estufas mal selladas u otros procesos de combustión interior o un exceso de ocupación sin adecuada regulación de la renovación de aire, suelen incrementar los niveles de CO₂.<br/><br/>' +
      '<b>5. Beneficios de bajo nivel de concentración</b><br/>' +
      'Un espacio interior con bajos niveles de CO₂ es representativo de un espacio con una adecuada renovación de aire. El aire fresco cuenta con niveles de CO₂ en torno a los 400 ppm.<br/><br/>' +
      '<b>6. Riesgos ante alta concentración</b><br/>' +
      'Niveles altos de CO₂ mantenidos en el tiempo provocan fatiga, malestar, aumento del ritmo respiratorio, somnolencia, falta de concentración y disminución de la productividad.<br/><br/>' +
      '<b>7. Recomendaciones de mejora</b><br/>' +
      'La principal recomendación es la ventilación, que deberá ser proporcional al ritmo de producción de CO₂ en el interior, principalmente debido a la ocupación de personas y animales o a la presencia de sistemas de combustión (estufas, chimeneas, cocinas, etc.). <br/>' +
      'En caso de haber calderas o estufas en el interior, es recomendable que sean lo más estancas posibles, facilitar el aporte de aire exterior directo y mantener el tiro alto en los momentos de encendido.'
  ],
  temperatureInfoN: [
    '<h4> Temperatura </h4>' +
      '<br/>Los niveles recomendados de temperatura para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: 20 - 23ºC </li>' +
      '<li>Amarillo/Alerta: 18 - 20ºC / 24 - 26ºC </li>' +
      '<li>Rojo/Alarma: < 18 y > 26ºC </li>' +
      '</ul>',
    '<h4> Temperature </h4>' +
      '<br/>Recommended temperature levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: 20 - 23ºC </li>' +
      '<li>Yellow/Warning: 18 - 20ºC / 24 - 26ºC </li>' +
      '<li>Red/Alarm: < 18 y > 26ºC </li>' +
      '</ul>'
  ],
  formaldehydeInfoN: [
    '<h4> Formaldeído </h4>' +
      '<br/>Los niveles recomendados de formaldehído para un espacio saludable son:<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 70 µg/m³ </li>' +
      '<li>Amarillo/Alerta: 70 – 120 µg/m³.</li>' +
      '<li>Rojo/Alarma: > 120 µg/m³.</li>' +
      '</ul>',
    '<h4> Formaldehyde </h4>' +
      '<br/>Recommended formaldehyde levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 70 µg/m³ </li>' +
      '<li>Yellow/Warning: 70 – 120 µg/m³.</li>' +
      '<li>Red/Alarm: > 120 µg/m³.</li>' +
      '</ul>'
  ],
  formaldehydeInfo: [
    '<b>1. Descripción </b><br/>' +
      'El formaldehído, el aldehído más importante, es quizá la sustancia tóxica más común en los espacios interiores. En condiciones normales de temperatura y presión el formaldehído se presenta como un gas, con un olor punzante, intenso y penetrante. Es hidrosoluble y muy volátil.<br/>' +
      'Es una sustancia de amplia presencia en la industria debido a su utilización como adhesivo, biocida, conservante, desengrasante, desinfectante, disolvente, endurecedor, fungicida, impermeabilizante, limpiador, lubricante, microbiocida, pesticida, protector de madera, o incluso como refrigerante. Muy presente en interiores de edificios, debido a materiales de construcción – tableros de virutas aglomeradas y otros materiales derivados de la madera, equipamiento, tratamientos o productos de higiene y cosmética, gases de escape y humo de tabaco – como consecuencia de una combustión lenta.<br/><br/>' +
      '<b>2. Niveles recomendados: </b><br/>' +
      'Los límites establecidos en España para exposiciones <b>laborales de corta duración (VLA-EC)</b> son de 0,3 ppm o 370 µg/m³.<br/>' +
      'No existe valor de referencia para interiores de viviendas.<br/>' +
      'La norma técnica de medición en Bioconstrucción SBM2015 recoge los siguientes valores indicativos para zonas de descanso de concentración de formaldehído en el aire interior:<br/>' +
      '<ul>' +
      '<li>No significativo :             <20 µg/m³ </li>' +
      '<li>Débilmente significativo  :   20 – 50 µg/m³</li>' +
      '<li>Fuertemente significativo  :  50 – 100 µg/m³</li>' +
      '<li>Extremadamente significativo :   >100 µg/m³</li>' +
      '</ul><br/>' +
      'Los rangos de valores utilizados en el dispositivo MICA como indicadores recogen teniendo en cuenta los valores SBM para zonas de descanso, así como los valores a partir de los cuales se presentan reacciones alérgicas y sensibilizantes:<br/>' +
      '<ul>' +
      '<li>VERDE: < 70 µg/m³ </li>' +
      '<li>AMARILLO: 70 – 120 µg/m³.</li>' +
      '<li>ROJO: > 120 µg/m³.</li>' +
      '</ul><br/>' +
      '<b>3. Unidades de medida </b><br/>' +
      'La medición de la concentración de formaldehído en el aire se realiza en µg/m³, ppm o ppb (partes por billón). MICA recoge los valores en µg/m³.<br/>' +
      'La conversión entre µg/m³, y ppm/ppb – como otras unidades de concentración habitual – depende del tipo de compuesto y las condiciones del ambiente interior. Para una estancia habitada habitual, con una temperatura alrededor de 20 ºC: <br/>' +
      '<ul>' +
      '<li>0,1 ppm ~ 0,125 mg/m³ ó 100 ppb ~ 125 µg/m³</li>' +
      '</ul><br/>' +
      '<b>4. Fuentes </b><br/>' +
      'El formaldehído se detecta comúnmente en interiores de edificios (oficinas, viviendas). Por un lado, es un compuesto ampliamente utilizado en la industria de la fabricación de tableros de aglomerados de madera en forma de colas y pegamentos, para mobiliario, equipamiento y tableros de construcción. También es frecuente en productos de acabado (colas, adhesivos, laminados), así como en productos de higiene, limpieza y cosmética. Y al ser un gas muy volátil emana con facilidad al aire interior.<br/>' +
      'También está presente en la madera maciza de forma natural, aunque en concentración muy reducida en comparación con los tableros aglomerados u otros productos.<br/><br/>' +
      '<b>5. Beneficios de bajo nivel de formaldehído</b><br/>' +
      'Pasamos entre un 80 y un 90% de nuestro tiempo en espacios interiores. Nuestra interrelación con factores como la temperatura, la humedad, la renovación de aire o la presencia de sustancias contaminantes como el formaldehído caracterizan un adecuado ambiente interior y condicionan nuestra salud.<br/>' +
      'Un ambiente interior sin presencia de formaldehído será un ambiente interior enfocado a la salud, o al menos con ausencia de uno de los contaminantes interiores más frecuentes. Deberá evaluarse la calidad del ambiente interior global y la presencia de otros contaminantes y factores de riesgo para la salud.<br/><br/>' +
      '<b>6. Riesgos de formaldehído elevado </b><br/>' +
      'El formaldehído puede causar alergias, dolor de cabeza, irritación de las mucosas, picor de nariz, ojos enrojecidos, tos, caída del cabello. Es además una sustancia incluida dentro de la lista negra de ISTAS debido a su clasificación como:' +
      '<ul>' +
      '<li>Cancerígena 1B según RD 1272/2008 y grupo I IARC</li>' +
      '<li>Mutágena, grado 2, según RD 1272/2008</li>' +
      '<li>Neurotóxica nivel 2 </li>' +
      '<li>Sensibilizante según RD 1272/2008 </li>' +
      '<li>Alérgena, según REACH</li>' +
      '</ul><br/>' +
      '<b>7. Recomendaciones de mejora</b><br/>' +
      '<ul>' +
      '<li>Adecuada renovación de aire</li>' +
      '<li>Eliminación de la fuente contaminante</li>' +
      '<li>Sellado de fuente contaminante -  con asesoramiento especializado para evitar contaminación secundaria por COVs y/o isocianatos:</li>' +
      '<ul>' +
      '<li>Barniz sellante para formaldehído</li>' +
      '<li>Sellado de cantos u orificios abiertos mediante encolado, cinta adhesiva de aluminio, silicona, etc. </li>' +
      '</ul>' +
      '<li>Utilización de productos libres de formaldehído</li>' +
      '</ul><br/>',

    '<b>1. Descripción </b><br/>' +
      'El formaldehído, el aldehído más importante, es quizá la sustancia tóxica más común en los espacios interiores. En condiciones normales de temperatura y presión el formaldehído se presenta como un gas, con un olor punzante, intenso y penetrante. Es hidrosoluble y muy volátil.<br/>' +
      'Es una sustancia de amplia presencia en la industria debido a su utilización como adhesivo, biocida, conservante, desengrasante, desinfectante, disolvente, endurecedor, fungicida, impermeabilizante, limpiador, lubricante, microbiocida, pesticida, protector de madera, o incluso como refrigerante. Muy presente en interiores de edificios, debido a materiales de construcción – tableros de virutas aglomeradas y otros materiales derivados de la madera, equipamiento, tratamientos o productos de higiene y cosmética, gases de escape y humo de tabaco – como consecuencia de una combustión lenta.<br/><br/>' +
      '<b>2. Niveles recomendados: </b><br/>' +
      'Los límites establecidos en España para exposiciones <b>laborales de corta duración (VLA-EC)</b> son de 0,3 ppm o 370 µg/m³.<br/>' +
      'No existe valor de referencia para interiores de viviendas.<br/>' +
      'La norma técnica de medición en Bioconstrucción SBM2015 recoge los siguientes valores indicativos para zonas de descanso de concentración de formaldehído en el aire interior:<br/>' +
      '<ul>' +
      '<li>No significativo :             <20 µg/m³ </li>' +
      '<li>Débilmente significativo  :   20 – 50 µg/m³</li>' +
      '<li>Fuertemente significativo  :  50 – 100 µg/m³</li>' +
      '<li>Extremadamente significativo :   >100 µg/m³</li>' +
      '</ul><br/>' +
      'Los rangos de valores utilizados en el dispositivo MICA como indicadores recogen teniendo en cuenta los valores SBM para zonas de descanso, así como los valores a partir de los cuales se presentan reacciones alérgicas y sensibilizantes:<br/>' +
      '<ul>' +
      '<li>VERDE: < 70 µg/m³ </li>' +
      '<li>AMARILLO: 70 – 120 µg/m³.</li>' +
      '<li>ROJO: > 120 µg/m³.</li>' +
      '</ul><br/>' +
      '<b>3. Unidades de medida </b><br/>' +
      'La medición de la concentración de formaldehído en el aire se realiza en µg/m³, ppm o ppb (partes por billón). MICA recoge los valores en µg/m³.<br/>' +
      'La conversión entre µg/m³, y ppm/ppb – como otras unidades de concentración habitual – depende del tipo de compuesto y las condiciones del ambiente interior. Para una estancia habitada habitual, con una temperatura alrededor de 20 ºC: <br/>' +
      '<ul>' +
      '<li>0,1 ppm ~ 0,125 mg/m³ ó 100 ppb ~ 125 µg/m³</li>' +
      '</ul><br/>' +
      '<b>4. Fuentes </b><br/>' +
      'El formaldehído se detecta comúnmente en interiores de edificios (oficinas, viviendas). Por un lado, es un compuesto ampliamente utilizado en la industria de la fabricación de tableros de aglomerados de madera en forma de colas y pegamentos, para mobiliario, equipamiento y tableros de construcción. También es frecuente en productos de acabado (colas, adhesivos, laminados), así como en productos de higiene, limpieza y cosmética. Y al ser un gas muy volátil emana con facilidad al aire interior.<br/>' +
      'También está presente en la madera maciza de forma natural, aunque en concentración muy reducida en comparación con los tableros aglomerados u otros productos.<br/><br/>' +
      '<b>5. Beneficios de bajo nivel de formaldehído</b><br/>' +
      'Pasamos entre un 80 y un 90% de nuestro tiempo en espacios interiores. Nuestra interrelación con factores como la temperatura, la humedad, la renovación de aire o la presencia de sustancias contaminantes como el formaldehído caracterizan un adecuado ambiente interior y condicionan nuestra salud.<br/>' +
      'Un ambiente interior sin presencia de formaldehído será un ambiente interior enfocado a la salud, o al menos con ausencia de uno de los contaminantes interiores más frecuentes. Deberá evaluarse la calidad del ambiente interior global y la presencia de otros contaminantes y factores de riesgo para la salud.<br/><br/>' +
      '<b>6. Riesgos de formaldehído elevado </b><br/>' +
      'El formaldehído puede causar alergias, dolor de cabeza, irritación de las mucosas, picor de nariz, ojos enrojecidos, tos, caída del cabello. Es además una sustancia incluida dentro de la lista negra de ISTAS debido a su clasificación como:' +
      '<ul>' +
      '<li>Cancerígena 1B según RD 1272/2008 y grupo I IARC</li>' +
      '<li>Mutágena, grado 2, según RD 1272/2008</li>' +
      '<li>Neurotóxica nivel 2 </li>' +
      '<li>Sensibilizante según RD 1272/2008 </li>' +
      '<li>Alérgena, según REACH</li>' +
      '</ul><br/>' +
      '<b>7. Recomendaciones de mejora</b><br/>' +
      '<ul>' +
      '<li>Adecuada renovación de aire</li>' +
      '<li>Eliminación de la fuente contaminante</li>' +
      '<li>Sellado de fuente contaminante -  con asesoramiento especializado para evitar contaminación secundaria por COVs y/o isocianatos:</li>' +
      '<ul>' +
      '<li>Barniz sellante para formaldehído</li>' +
      '<li>Sellado de cantos u orificios abiertos mediante encolado, cinta adhesiva de aluminio, silicona, etc. </li>' +
      '</ul>' +
      '<li>Utilización de productos libres de formaldehído</li>' +
      '</ul><br/>'
  ],

  outdoorAirQualityInfo: [
    'Informacion de calidad del aire Exterior',
    'Outdoor Air Quality info',
    'Info zur Luftqualität im Freien'
  ],

  // TODO : Meter referencia que aparece en Word de las PMs
  iaqInfoN: [
    '<h4> IAQ </h4>' +
      '<br/>Probabilidad de propagación del virus<br/>' +
      'Necesidad de ventilación<br/>',
    '<h4> IAQ </h4>' + '<br/>Likelihood of virus to spread<br/>' + 'Need for ventilation<br/>'
  ],
  vocsInfoN: [
    '<h4> Covs </h4>' +
      '<br/>Los niveles recomendados de COVTs en suspensión para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 220 ppb </li>' +
      '<li>Amarillo/Alerta: 220 - 660 ppb </li>' +
      '<li>Rojo/Alarma: > 660 ppb </li>' +
      '</ul>',
    '<h4> TVocs </h4>' +
      '<br/>Recommended TVOC levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 220 ppb </li>' +
      '<li>Yellow/Warning: 220 - 660 ppb </li>' +
      '<li>Red/Alarm: > 660 ppb </li>' +
      '</ul>'
  ],
  vocsInfo: [
    '<style type="text/css">' +
      '.tg  {border-collapse:collapse;border-spacing:0;margin:0px auto;}' +
      '.tg td{font-family:Arial, sans-serif;font-size:14px;padding:10px 5px;border-style:solid;border-width:5px;overflow:hidden;word-break:normal;border-color:black;}' +
      '.tg th{font-family:Arial, sans-serif;font-size:14px;font-weight:normal;padding:10px 5px;border-style:solid;border-width:5px;overflow:hidden;word-break:normal;border-color:black;' +
      '.tg .tg-0lax{text-align:left;vertical-align:top}' +
      '@media screen and (max-width: 767px) {.tg {width: auto !important;}.tg col {width: auto !important;}.tg-wrap {overflow-x: auto;-webkit-overflow-scrolling: touch;margin: auto 0px;}}</style>' +
      '<b>1. Descripción: </b><br/>' +
      'Los compuestos orgánicos volátiles - COVs – o VOCs – <i>volatile organic compounds</i>, son todos aquellos hidrocarburos presentes en estado gaseoso a temperatura ambiente normal, o que son muy volátiles a dicha temperatura.  Se puede considerar como COV aquel compuesto orgánico que a 20ºC tenga una presión de vapor de 0.01 kPa o más, o una volatilidad equivalente en las condiciones particulares de uso – y un punto de ebullición entre 50 y 260ºC.  <br/> <br/>' +
      '<table style="border-collapse: collapse; border-spacing: 0;margin: 0px auto;">' +
      '<tr>' +
      '<th style="font-size: 14px;font-weight: normal;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top"></th>' +
      '<th style="font-size: 14px;font-weight: normal;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Descripción</th>' +
      '<th style="font-size: 14px;font-weight: normal;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Punto de ebullición aprox.</th>' +
      '<th style="font-size: 14px;font-weight: normal;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Presencia</th>' +
      '<th style="font-size: 14px;font-weight: normal;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Ejemplo</th>' +
      '</tr>' +
      '<tr>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">VVOC - COMV</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Compuestos orgánicos muy volátiles</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">&lt; 0 – 100ºC</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Aire</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Formaldehído</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">VOC - COV</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Compuestos orgánicos volátiles</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">50 – 260ºC</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Aire</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Benceno</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">SVOC - COSV</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Compuestos orgánicos semivolátiles</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">240 – 400ºC</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Polvo</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Ftalatos</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">TVOC - COVT</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Compuestos orgánicos volátiles totales</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top" colSpan="3">Suma de todos los compuestos orgánicos volátiles</td>' +
      '</tr>' +
      '</table><br/>' +
      'Los COVs son liberados frecuentemente por disolventes, pinturas y otros productos de uso común en edificios. Los TCOV, son los compuestos orgánicos totales, la suma de todos los COVs medidos en el aire interior. <br/> <br/>' +
      '<b>2. Niveles recomendados </b><br/>' +
      'Existen más de 120.000 sustancias químicas y más de 12 millones de compuestos publicados. De los cuales, únicamente una pequeña parte está estudiada desde una perspectiva médica y tan sólo existen valores límite para unas 500, solamente en el ámbito laboral/profesional. En el ámbito de la vivienda no se cuenta con regulación específica en el ámbito de la contaminación por compuestos orgánicos volátiles – más allá de recomendaciones. <br/>' +
      'Para una valoración global de los compuestos orgánicos totales se realiza la medición total de la concentración en el aire en ppb (partes por billón) o µg/m³, de una relación de alcoholes, aldehídos, cetonas, aminas, siloxanos e hidrocarburos aromáticos y alifáticos. Otros compuestos de respuesta más crítica sobre la salud, como benceno, naftalinas, cresoles, o estireno, deben estudiarse de manera individualizada.<br/><br/>' +
      '<b>Según normativas</b><br/>' +
      'El AGÖEF es la asociación alemana de investigación ecológica; una asociación de empresas de consultoría y servicios independientes que colaboran en la medición de la contaminación interior en espacios construidos. Desde 1993 trabajan en la elaboración de valores de referencia en compuestos químicos, tanto en aire como en muestras de polvo, y resultan referencias válidas para el trabajo desarrollado en el presente informe. <br/>' +
      'Los límites de recomendación se clasifican en: <br/> <ul>' +
      '<li>P50 – Percentil 50. Valor normal. No es considerada suficiente evidencia para acción urgente – TVOC < 360 µg/m³ </li>' +
      '<li>P90 – Percentil 90. Valor de atención. Fuente emisora presente – TVOC <1572 µg/m³</li>' +
      '<li>Guidance values – Valor de referencia equivalente a valores derivados de riesgo toxicológico – TVOC = 1000 µg/m³</li>' +
      '</ul><br/>' +
      'Los valores indicativos recogidos en el estándar de medición SBM2015 hacen referencia a los compuestos orgánicos volátiles totales, recomendados como valores de precaución para zonas de descanso: <br/><ul>' +
      '<li>No significativo : < 100 µg/m³ </li>' +
      '<li>Débilmente significativo :  100 – 300 µg/m³</li>' +
      '<li>Fuertemente significativo : 300 – 1000 µg/m³</li>' +
      '<li>Extremadamente significativo : >1000 µg/m³</li>' +
      '</ul><br/>' +
      'Los límites establecidos en España para exposiciones se recogen según compuestos individuales en los Límites de Exposición Profesional para Agentes Químicos en España, actualizado cada año. <br/>' +
      'No existe valor de referencia para interiores de viviendas, ni tampoco para los compuestos orgánicos totales. <br/><br/>' +
      '<b>Rangos MyInbiot </b><br/>' +
      'Los valores de referencia en MyInbiot están basados en la clasificación de compuestos orgánicos volátiles totales de la Agencia Federal Medioambiental alemana, a partir de los cuales, y en función del tiempo de exposición, pueden derivar en reacciones alérgicas y sensibilizantes, hipersensibilidades o enfermedades de diversas patologías.<br/> <br/>' +
      '<table style="border-collapse:collapse;border-spacing:0;">' +
      '<tr>' +
      '<th style="font-family:Arial, sans-serif;font-size:14px;font-weight:normal;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;border-color:inherit;text-align:left;vertical-align:top">Referencia</th>' +
      '<th style="font-family:Arial, sans-serif;font-size:14px;font-weight:normal;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;border-color:inherit;text-align:left;vertical-align:top">Calidad Aire</th>' +
      '<th style="font-family:Arial, sans-serif;font-size:14px;font-weight:normal;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;border-color:inherit;text-align:left;vertical-align:top">Salud</th>' +
      '<th style="font-family:Arial, sans-serif;font-size:14px;font-weight:normal;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;border-color:inherit;text-align:left;vertical-align:top">TVOC (ppb)</th>' +
      '</tr>' +
      '<tr>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#009901;border-color:inherit;text-align:left;vertical-align:top;">1 (excelente)</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#009901;border-color:inherit;text-align:left;vertical-align:top;">Buena calidad aire</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#009901;border-color:inherit;text-align:left;vertical-align:top;">Valor de referencia</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#009901;border-color:inherit;text-align:left;vertical-align:top;">0 – 65</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#34ff34;border-color:inherit;text-align:left;vertical-align:top;">2 (bueno)</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#34ff34;border-color:inherit;text-align:left;vertical-align:top;">Valores aceptables</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#34ff34;border-color:inherit;text-align:left;vertical-align:top;">Aceptable</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#34ff34;border-color:inherit;text-align:left;vertical-align:top;">65 – 220</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#f8ff00;border-color:inherit;text-align:left;vertical-align:top;">3 (medio)</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#f8ff00;border-color:inherit;text-align:left;vertical-align:top;">Valores no recomendados en exposición continua (1 año)</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#f8ff00;border-color:inherit;text-align:left;vertical-align:top;">Reacciones alérgicas y/o sensibilizantes</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#f8ff00;border-color:inherit;text-align:left;vertical-align:top;">220 – 660</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#f8a102;border-color:inherit;text-align:left;vertical-align:top;">4 (pobre)</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#f8a102;border-color:inherit;text-align:left;vertical-align:top;">Valores no recomendados en exposición continua (1 mes)</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#f8a102;border-color:inherit;text-align:left;vertical-align:top;">Hipersensibilidad y/o enfermedad</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#f8a102;border-color:inherit;text-align:left;vertical-align:top;">660 – 2200</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#fe0000;border-color:inherit;text-align:left;vertical-align:top;">5 (insalubre)</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#fe0000;border-color:inherit;text-align:left;vertical-align:top;">Valores inaceptables</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#fe0000;border-color:inherit;text-align:left;vertical-align:top;">Acción inmediata</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#fe0000;border-color:inherit;text-align:left;vertical-align:top;">2200 – 5500</td>' +
      '</tr>' +
      '</table> <br/>' +
      'En base a estos valores, se han establecido los siguientes rangos en MyInbiot.<br/>' +
      '<ul>' +
      '<li>VERDE < 220 ppb</li>' +
      '<li>AMARILLO  220 – 660 ppb</li>' +
      '<li>ROJO > 660 ppb</li>' +
      '</ul><br/>' +
      '<b>3. Unidades de medida </b><br/>' +
      'La concentración de compuestos orgánicos totales en el aire se determina en ppb (partes por billón).<br/>' +
      'La relación entre ppb y µg/m³ no es directa, ya que la relación de TVOC es la suma de una serie de compuestos con diferentes comportamientos y pesos moleculares. El valor de los TVOC se calcula como la suma de todos los compuestos detectados a partir del valor equivalente de tolueno para cada uno de ellos, conforme a la UNE 16516. <br/><br/>' +
      '<b>4. Fuentes </b><br/>' +
      'Los compuestos orgánicos volátiles están presentes en disolventes, pinturas, pegamentos, barnices y similares, en elementos plásticos, mobiliario, materiales de construcción (aislantes, tableros de acabado, espumas), productos de limpieza, higiene y mantenimiento en edificios. Son compuestos comunes, fácilmente localizados en interiores de edificios.<br/> <br/>' +
      '<b>5. Beneficios de bajo nivel de compuestos orgánicos volátiles </b><br/>' +
      'Pasamos entre un 80 y un 90% de nuestro tiempo en espacios interiores. Nuestra interrelación con factores como la temperatura, la humedad, la renovación de aire o la presencia de compuestos orgánicos volátiles caracterizan un adecuado ambiente interior y condicionan nuestra salud.<br/>' +
      'Un ambiente interior sin presencia de compuestos orgánicos volátiles perjudiciales será un ambiente interior enfocado a la salud, sin presencia de sustancias irritantes y/o potencialmente alérgicas, que garantice el confort y bienestar, con impactos positivos sobre la productividad y creatividad de sus ocupantes.<br/><br/>' +
      '<b>6. Riesgos de COVs elevados  </b><br/>' +
      'El riesgo derivado de la presencia de COVs se produce principalmente durante su aplicación y los primeros días y semanas inmediatas. Aunque algunos compuestos sustitutivos para los VOCs más frecuentes y de mayor impacto sobre la salud, están siendo sustituidos por alternativas con puntos de ebullición más elevado, y que por lo tanto, pueden liberar gases durante más tiempo, con el consecuente riesgo para la salud. <br/>' +
      'Los efectos sobre la salud son diversos, en función del compuesto y el periodo de exposición. Generalmente son liposolubles y a corto plazo pueden derivar en  reacciones alérgicas, dolor de cabeza, irritación de las mucosas, picor de nariz, ojos enrojecidos, tos, fatiga, mareos o manchas en la piel. A largo plazo, pueden dañar el hígado, riñones o el sistema nervioso central, y algunos de ellos pueden ser cancerígenos. <br/><br/>' +
      '<b>7. Recomendaciones de mejora </b><br/> <ul>' +
      '<li>Adecuada renovación de aire. </li>' +
      '<li>Eliminación de la fuente contaminante.</li>' +
      '<li>Utilización de productos de construcción y acabado certificados con bajas emisiones de COVs (EC1 plus).</li>' +
      '<li>Revisión de productos de higiene y limpieza.</li></ul><br/>',

    '<style type="text/css">' +
      '.tg  {border-collapse:collapse;border-spacing:0;margin:0px auto;}' +
      '.tg td{font-family:Arial, sans-serif;font-size:14px;padding:10px 5px;border-style:solid;border-width:5px;overflow:hidden;word-break:normal;border-color:black;}' +
      '.tg th{font-family:Arial, sans-serif;font-size:14px;font-weight:normal;padding:10px 5px;border-style:solid;border-width:5px;overflow:hidden;word-break:normal;border-color:black;' +
      '.tg .tg-0lax{text-align:left;vertical-align:top}' +
      '@media screen and (max-width: 767px) {.tg {width: auto !important;}.tg col {width: auto !important;}.tg-wrap {overflow-x: auto;-webkit-overflow-scrolling: touch;margin: auto 0px;}}</style>' +
      '<b>1. Descripción: </b><br/>' +
      'Los compuestos orgánicos volátiles - COVs – o VOCs – <i>volatile organic compounds</i>, son todos aquellos hidrocarburos presentes en estado gaseoso a temperatura ambiente normal, o que son muy volátiles a dicha temperatura.  Se puede considerar como COV aquel compuesto orgánico que a 20ºC tenga una presión de vapor de 0.01 kPa o más, o una volatilidad equivalente en las condiciones particulares de uso – y un punto de ebullición entre 50 y 260ºC.  <br/> <br/>' +
      '<table style="border-collapse: collapse; border-spacing: 0;margin: 0px auto;">' +
      '<tr>' +
      '<th style="font-size: 14px;font-weight: normal;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top"></th>' +
      '<th style="font-size: 14px;font-weight: normal;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Descripción</th>' +
      '<th style="font-size: 14px;font-weight: normal;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Punto de ebullición aprox.</th>' +
      '<th style="font-size: 14px;font-weight: normal;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Presencia</th>' +
      '<th style="font-size: 14px;font-weight: normal;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Ejemplo</th>' +
      '</tr>' +
      '<tr>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">VVOC - COMV</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Compuestos orgánicos muy volátiles</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">&lt; 0 – 100ºC</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Aire</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Formaldehído</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">VOC - COV</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Compuestos orgánicos volátiles</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">50 – 260ºC</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Aire</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Benceno</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">SVOC - COSV</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Compuestos orgánicos semivolátiles</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">240 – 400ºC</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Polvo</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Ftalatos</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">TVOC - COVT</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top">Compuestos orgánicos volátiles totales</td>' +
      '<td style="font-size: 14px;padding: 10px 5px;border-style: solid;border-width: 1px;overflow: hidden;word-break: normal;border-color:black;text-align:left;vertical-align:top" colSpan="3">Suma de todos los compuestos orgánicos volátiles</td>' +
      '</tr>' +
      '</table><br/>' +
      'Los COVs son liberados frecuentemente por disolventes, pinturas y otros productos de uso común en edificios. Los TCOV, son los compuestos orgánicos totales, la suma de todos los COVs medidos en el aire interior. <br/> <br/>' +
      '<b>2. Niveles recomendados </b><br/>' +
      'Existen más de 120.000 sustancias químicas y más de 12 millones de compuestos publicados. De los cuales, únicamente una pequeña parte está estudiada desde una perspectiva médica y tan sólo existen valores límite para unas 500, solamente en el ámbito laboral/profesional. En el ámbito de la vivienda no se cuenta con regulación específica en el ámbito de la contaminación por compuestos orgánicos volátiles – más allá de recomendaciones. <br/>' +
      'Para una valoración global de los compuestos orgánicos totales se realiza la medición total de la concentración en el aire en ppb (partes por billón) o µg/m³, de una relación de alcoholes, aldehídos, cetonas, aminas, siloxanos e hidrocarburos aromáticos y alifáticos. Otros compuestos de respuesta más crítica sobre la salud, como benceno, naftalinas, cresoles, o estireno, deben estudiarse de manera individualizada.<br/><br/>' +
      '<b>Según normativas</b><br/>' +
      'El AGÖEF es la asociación alemana de investigación ecológica; una asociación de empresas de consultoría y servicios independientes que colaboran en la medición de la contaminación interior en espacios construidos. Desde 1993 trabajan en la elaboración de valores de referencia en compuestos químicos, tanto en aire como en muestras de polvo, y resultan referencias válidas para el trabajo desarrollado en el presente informe. <br/>' +
      'Los límites de recomendación se clasifican en: <br/> <ul>' +
      '<li>P50 – Percentil 50. Valor normal. No es considerada suficiente evidencia para acción urgente – TVOC < 360 µg/m³ </li>' +
      '<li>P90 – Percentil 90. Valor de atención. Fuente emisora presente – TVOC <1572 µg/m³</li>' +
      '<li>Guidance values – Valor de referencia equivalente a valores derivados de riesgo toxicológico – TVOC = 1000 µg/m³</li>' +
      '</ul><br/>' +
      'Los valores indicativos recogidos en el estándar de medición SBM2015 hacen referencia a los compuestos orgánicos volátiles totales, recomendados como valores de precaución para zonas de descanso: <br/><ul>' +
      '<li>No significativo : < 100 µg/m³ </li>' +
      '<li>Débilmente significativo :  100 – 300 µg/m³</li>' +
      '<li>Fuertemente significativo : 300 – 1000 µg/m³</li>' +
      '<li>Extremadamente significativo : >1000 µg/m³</li>' +
      '</ul><br/>' +
      'Los límites establecidos en España para exposiciones se recogen según compuestos individuales en los Límites de Exposición Profesional para Agentes Químicos en España, actualizado cada año. <br/>' +
      'No existe valor de referencia para interiores de viviendas, ni tampoco para los compuestos orgánicos totales. <br/><br/>' +
      '<b>Rangos MyInbiot </b><br/>' +
      'Los valores de referencia en MyInbiot están basados en la clasificación de compuestos orgánicos volátiles totales de la Agencia Federal Medioambiental alemana, a partir de los cuales, y en función del tiempo de exposición, pueden derivar en reacciones alérgicas y sensibilizantes, hipersensibilidades o enfermedades de diversas patologías.<br/> <br/>' +
      '<table style="border-collapse:collapse;border-spacing:0;">' +
      '<tr>' +
      '<th style="font-family:Arial, sans-serif;font-size:14px;font-weight:normal;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;border-color:inherit;text-align:left;vertical-align:top">Referencia</th>' +
      '<th style="font-family:Arial, sans-serif;font-size:14px;font-weight:normal;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;border-color:inherit;text-align:left;vertical-align:top">Calidad Aire</th>' +
      '<th style="font-family:Arial, sans-serif;font-size:14px;font-weight:normal;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;border-color:inherit;text-align:left;vertical-align:top">Salud</th>' +
      '<th style="font-family:Arial, sans-serif;font-size:14px;font-weight:normal;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;border-color:inherit;text-align:left;vertical-align:top">TVOC (ppb)</th>' +
      '</tr>' +
      '<tr>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#009901;border-color:inherit;text-align:left;vertical-align:top;">1 (excelente)</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#009901;border-color:inherit;text-align:left;vertical-align:top;">Buena calidad aire</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#009901;border-color:inherit;text-align:left;vertical-align:top;">Valor de referencia</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#009901;border-color:inherit;text-align:left;vertical-align:top;">0 – 65</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#34ff34;border-color:inherit;text-align:left;vertical-align:top;">2 (bueno)</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#34ff34;border-color:inherit;text-align:left;vertical-align:top;">Valores aceptables</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#34ff34;border-color:inherit;text-align:left;vertical-align:top;">Aceptable</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#34ff34;border-color:inherit;text-align:left;vertical-align:top;">65 – 220</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#f8ff00;border-color:inherit;text-align:left;vertical-align:top;">3 (medio)</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#f8ff00;border-color:inherit;text-align:left;vertical-align:top;">Valores no recomendados en exposición continua (1 año)</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#f8ff00;border-color:inherit;text-align:left;vertical-align:top;">Reacciones alérgicas y/o sensibilizantes</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#f8ff00;border-color:inherit;text-align:left;vertical-align:top;">220 – 660</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#f8a102;border-color:inherit;text-align:left;vertical-align:top;">4 (pobre)</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#f8a102;border-color:inherit;text-align:left;vertical-align:top;">Valores no recomendados en exposición continua (1 mes)</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#f8a102;border-color:inherit;text-align:left;vertical-align:top;">Hipersensibilidad y/o enfermedad</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#f8a102;border-color:inherit;text-align:left;vertical-align:top;">660 – 2200</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#fe0000;border-color:inherit;text-align:left;vertical-align:top;">5 (insalubre)</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#fe0000;border-color:inherit;text-align:left;vertical-align:top;">Valores inaceptables</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#fe0000;border-color:inherit;text-align:left;vertical-align:top;">Acción inmediata</td>' +
      '<td style="font-family:Arial, sans-serif;font-size:12px;padding:10px 5px;border-style:solid;border-width:1px;overflow:hidden;word-break:normal;border-color:black;background-color:#fe0000;border-color:inherit;text-align:left;vertical-align:top;">2200 – 5500</td>' +
      '</tr>' +
      '</table> <br/>' +
      'En base a estos valores, se han establecido los siguientes rangos en MyInbiot.<br/>' +
      '<ul>' +
      '<li>VERDE < 220 ppb</li>' +
      '<li>AMARILLO  220 – 660 ppb</li>' +
      '<li>ROJO > 660 ppb</li>' +
      '</ul><br/>' +
      '<b>3. Unidades de medida </b><br/>' +
      'La concentración de compuestos orgánicos totales en el aire se determina en ppb (partes por billón).<br/>' +
      'La relación entre ppb y µg/m³ no es directa, ya que la relación de TVOC es la suma de una serie de compuestos con diferentes comportamientos y pesos moleculares. El valor de los TVOC se calcula como la suma de todos los compuestos detectados a partir del valor equivalente de tolueno para cada uno de ellos, conforme a la UNE 16516. <br/><br/>' +
      '<b>4. Fuentes </b><br/>' +
      'Los compuestos orgánicos volátiles están presentes en disolventes, pinturas, pegamentos, barnices y similares, en elementos plásticos, mobiliario, materiales de construcción (aislantes, tableros de acabado, espumas), productos de limpieza, higiene y mantenimiento en edificios. Son compuestos comunes, fácilmente localizados en interiores de edificios.<br/> <br/>' +
      '<b>5. Beneficios de bajo nivel de compuestos orgánicos volátiles </b><br/>' +
      'Pasamos entre un 80 y un 90% de nuestro tiempo en espacios interiores. Nuestra interrelación con factores como la temperatura, la humedad, la renovación de aire o la presencia de compuestos orgánicos volátiles caracterizan un adecuado ambiente interior y condicionan nuestra salud.<br/>' +
      'Un ambiente interior sin presencia de compuestos orgánicos volátiles perjudiciales será un ambiente interior enfocado a la salud, sin presencia de sustancias irritantes y/o potencialmente alérgicas, que garantice el confort y bienestar, con impactos positivos sobre la productividad y creatividad de sus ocupantes.<br/><br/>' +
      '<b>6. Riesgos de COVs elevados  </b><br/>' +
      'El riesgo derivado de la presencia de COVs se produce principalmente durante su aplicación y los primeros días y semanas inmediatas. Aunque algunos compuestos sustitutivos para los VOCs más frecuentes y de mayor impacto sobre la salud, están siendo sustituidos por alternativas con puntos de ebullición más elevado, y que por lo tanto, pueden liberar gases durante más tiempo, con el consecuente riesgo para la salud. <br/>' +
      'Los efectos sobre la salud son diversos, en función del compuesto y el periodo de exposición. Generalmente son liposolubles y a corto plazo pueden derivar en  reacciones alérgicas, dolor de cabeza, irritación de las mucosas, picor de nariz, ojos enrojecidos, tos, fatiga, mareos o manchas en la piel. A largo plazo, pueden dañar el hígado, riñones o el sistema nervioso central, y algunos de ellos pueden ser cancerígenos. <br/><br/>' +
      '<b>7. Recomendaciones de mejora </b><br/> <ul>' +
      '<li>Adecuada renovación de aire. </li>' +
      '<li>Eliminación de la fuente contaminante.</li>' +
      '<li>Utilización de productos de construcción y acabado certificados con bajas emisiones de COVs (EC1 plus).</li>' +
      '<li>Revisión de productos de higiene y limpieza.</li></ul><br/>'
  ],
  // TODO : Meter referencia que aparece en Word de las PMs
  pm1InfoN: [
    '<h4> PM1.0 </h4>' +
      '<br/>Los niveles recomendados de partículas en suspensión para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 15 µg/m³ </li>' +
      '<li>Amarillo/Alerta: 15 - 35 µg/m³ </li>' +
      '<li>Rojo/Alarma: > 35 µg/m³ </li>' +
      '</ul>',
    '<h4> PM1 </h4>' +
      '<br/>Recommended PM levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 15 µg/m³ </li>' +
      '<li>Yellow/Warning: 15 - 35 µg/m³ </li>' +
      '<li>Red/Alarm: > 35 µg/m³ </li>' +
      '</ul>'
  ],
  pm25InfoN: [
    '<h4> PM2.5 </h4>' +
      '<br/>Los niveles recomendados de partículas en suspensión para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 15 µg/m³ </li>' +
      '<li>Amarillo/Alerta: 15 - 35 µg/m³ </li>' +
      '<li>Rojo/Alarma: > 35 µg/m³ </li>' +
      '</ul>',
    '<h4> PM2.5 </h4>' +
      '<br/>Recommended PM levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 15 µg/m³ </li>' +
      '<li>Yellow/Warning: 15 - 35 µg/m³ </li>' +
      '<li>Red/Alarm: > 35 µg/m³ </li>' +
      '</ul>'
  ],
  pm10InfoN: [
    '<h4> PM10 </h4>' +
      '<br/>Los niveles recomendados de partículas en suspensión para un espacio saludable son :<br/>' +
      '<ul>' +
      '<li>Verde/Seguro: < 50 µg/m³ </li>' +
      '<li>Amarillo/Alerta: 50 - 150 µg/m³ </li>' +
      '<li>Rojo/Alarma: > 150 µg/m³ </li>' +
      '</ul>',
    '<h4> PM10 </h4>' +
      '<br/>Recommended PM levels for a healthy space are:<br/>' +
      '<ul>' +
      '<li>Green/Safe: < 50 µg/m³ </li>' +
      '<li>Yellow/Warning: 50 - 150 µg/m³ </li>' +
      '<li>Red/Alarm: > 150 µg/m³ </li>' +
      '</ul>'
  ],
  dustInfo: [
    '<b>1. Descripción: </b><br/>' +
      'Las partículas en suspensión en el aire son una mezcla compleja de sustancias de distinta composición química y de diversa naturaleza física que presentan un tamaño variable que oscila desde 0,005 a 100 μm y que no pueden detectarse a simple vista. La composición de estas partículas en suspensión es una mezcla heterogénea, desde compuestos poco volátiles, amianto, esporas de hongos, bacterias, todo tipo de alérgenos, o incluso metales pesados.<br/>' +
      'Aunque la valoración de su toxicidad depende de la composición específica, una cantidad elevada de polvo en suspensión ya puede derivar en problemas para la salud, especialmente las partículas inferiores a 1 – 5 μm que pasan en su mayor parte sin filtrar de las vías respiratorias a los alveolos pulmonares.<br/>' +
      'Generalmente la medición de estas partículas agrupa a todas las partículas con diámetro que van desde menos de 0,1 micras a 50 micras. Las partículas de mayor tamaño suelen depositarse por gravedad en el polvo presente en suelo y superficies interiores. Se expresan como PM, (particulate matter o material particulado) y el diámetro de partícula como subíndice en μm: PM1, PM2,5 o PM10 son las más frecuentes en mediciones de calidad de aire interior. <br/> <br/>' +
      '<b>2. Niveles recomendados </b><br/>' +
      'Cuanto mayor es el tamaño de las partículas, menor es el tiempo que permanecen en suspensión. Partículas mayores de 10 μm se depositan rápidamente en el polvo; las partículas <b>PM10</b> (con diámetro ≤ 10 μm) pueden permanecer suspendidas durante horas. Las partículas <b>PM2,5</b> (con diámetro ≤ 2,5 μm) pueden permanecer en el aire durante semanas y son más susceptibles de desplazarse a través de los sistemas de ventilación. <br/>' +
      'Los actuales estándares de la EPA (Agencia de Protección Medioambiental de Estados Unidos), recomienda valores máximos para PM2,5 de 35 μg/m³, aunque reduce los valores totales anuales a 12 – 15 μg/m³. La UE establece unos niveles máximos de 25 μg/m³ también para PM2,5, aunque con perspectiva de aumentar la restricción. <br/> <br/>' +
      '<b>3. Rangos MyInbiot </b><br/> <br/>' +
      ' <b>PM2,5</b>: <br/> <ul>' +
      '<li>VERDE < 25 µg/m³</li>' +
      '<li>AMARILLO  25 – 55 µg/m³.</li>' +
      '<li>ROJO > 55 µg/m³.</li>' +
      '</ul><br/>' +
      ' <b>PM10</b>: <br/> <ul>' +
      '<li>VERDE < 50 µg/m³</li>' +
      '<li>AMARILLO  50 – 150 µg/m³.</li>' +
      '<li>ROJO > 150 µg/m³.</li>' +
      '</ul><br/>' +
      '<b>4. Unidades de medida </b><br/>' +
      'La valoración de las partículas en suspensión se realiza en función del peso de las partículas de cada tamaño por volumen de aire (μm/m³).<br/><br/>' +
      '<b>5. Fuentes </b><br/>' +
      'La asociación de partículas en suspensión en el aire interior, con la contaminación ambiental exterior es directa. El tráfico rodado (vehículos diesel, principalmente) o la industria (chimeneas, carbón, incineradoras, canteras, determinados trabajos agrícolas, etc.) son la principal fuente de emisión, y que, en función del sistema de ventilación y filtros, pueden detectarse en ambientes interiores. Sin embargo, el interior de edificios tiene su propia fuente de emisión de partículas en aparatos de combustión o el humo del tabaco, o tener un origen biológico como polen, esporas, bacterias u hongos.<br/>' +
      'En ausencia de fuentes interiores conocidas, las concentraciones de partículas en el aire interior son muy similares a las del aire exterior.<br/><br/>' +
      '<b>6. Beneficios de bajo nivel de partículas en suspensión </b><br/>' +
      'Un bajo nivel de partículas en suspensión implica un bajo nivel de polvo en suspensión y por lo tanto ausencia de sustancias irritantes, potencialmente alérgicas y contaminantes. Un aire libre de partículas en suspensión es un aire limpio y característico de un ambiente interior sin movimientos de aire interior insalubres.<br/><br/>' +
      '<b>7. Riesgos de niveles de partículas en suspensión elevados </b><br/>' +
      'Las partículas presentes en el aire interior pueden ser irritantes respiratorias y potencialmente alérgicas. Los efectos sobre la salud dependen del tipo de partícula presente y su facilidad de penetración en el organismo. En personas con sistema respiratorio debilitado o con patologías respiratorias previas, como asma, afectan más directamente. La diversidad de síntomas asociados va desde irritaciones de las vías respiratorias y oculares, mayor incidencia de enfermedades respiratorias y cardiovasculares, aumento de la incidencia de cáncer a largo plazo, o incluso agravamiento de enfermedades infecciosas o con sistema inmunitario debilitado.<br/><br/>' +
      '<b>8. Recomendaciones de mejora </b><br/> <ul>' +
      '<li>Adecuar el sistema y mantenimiento de la instalación de ventilación a los niveles de contaminación exterior e interior en el ámbito de las partículas. </li>' +
      '<li>Evitar el humo del tabaco en interiores.</li>' +
      '<li>Utilizar aspiradores con filtros de partículas finas (tipo HEPA, ULPA, Clase C…).</li>' +
      '<li>Garantizar una limpieza interior sin arrastre de polvo: balleta húmeda para el polvo, priorizar el fregado al barrido.</li>' +
      '<li>Evitar la alta concentración de elementos textiles en interiores de edificio y/o garantizar una limpieza frecuente: las alfombras y moquetas son fuente de acumulación de polvo y una limpieza insuficiente puede hacer que ese polvo esté en suspensión de forma continua.</li>' +
      '<li>Utilización de purificadores de aire que filtren partículas de hasta 0,3 µm.</li>' +
      '<li>Asegurar la adecuada renovación de aire durante procesos de combustión (chimeneas).</li>' +
      '<li>En edificios de oficinas, ubicar fotocopiadores e impresoras en ambientes separados ventilados.</li>',

    '<b>1. Descripción: </b><br/>' +
      'Las partículas en suspensión en el aire son una mezcla compleja de sustancias de distinta composición química y de diversa naturaleza física que presentan un tamaño variable que oscila desde 0,005 a 100 μm y que no pueden detectarse a simple vista. La composición de estas partículas en suspensión es una mezcla heterogénea, desde compuestos poco volátiles, amianto, esporas de hongos, bacterias, todo tipo de alérgenos, o incluso metales pesados.<br/>' +
      'Aunque la valoración de su toxicidad depende de la composición específica, una cantidad elevada de polvo en suspensión ya puede derivar en problemas para la salud, especialmente las partículas inferiores a 1 – 5 μm que pasan en su mayor parte sin filtrar de las vías respiratorias a los alveolos pulmonares.<br/>' +
      'Generalmente la medición de estas partículas agrupa a todas las partículas con diámetro que van desde menos de 0,1 micras a 50 micras. Las partículas de mayor tamaño suelen depositarse por gravedad en el polvo presente en suelo y superficies interiores. Se expresan como PM, (particulate matter o material particulado) y el diámetro de partícula como subíndice en μm: PM1, PM2,5 o PM10 son las más frecuentes en mediciones de calidad de aire interior. <br/> <br/>' +
      '<b>2. Niveles recomendados </b><br/>' +
      'Cuanto mayor es el tamaño de las partículas, menor es el tiempo que permanecen en suspensión. Partículas mayores de 10 μm se depositan rápidamente en el polvo; las partículas <b>PM10</b> (con diámetro ≤ 10 μm) pueden permanecer suspendidas durante horas. Las partículas <b>PM2,5</b> (con diámetro ≤ 2,5 μm) pueden permanecer en el aire durante semanas y son más susceptibles de desplazarse a través de los sistemas de ventilación. <br/>' +
      'Los actuales estándares de la EPA (Agencia de Protección Medioambiental de Estados Unidos), recomienda valores máximos para PM2,5 de 35 µg/m³, aunque reduce los valores totales anuales a 12 – 15 µg/m³. La UE establece unos niveles máximos de 25 µg/m³ también para PM2,5, aunque con perspectiva de aumentar la restricción. <br/> <br/>' +
      '<b>3. Rangos MyInbiot </b><br/> <br/>' +
      ' <b>PM2,5</b>: <br/> <ul>' +
      '<li>VERDE <25 µg/m³</li>' +
      '<li>AMARILLO  25 – 55 µg/m³.</li>' +
      '<li>ROJO > 55 µg/m³.</li>' +
      '</ul><br/>' +
      ' <b>PM10</b>: <br/> <ul>' +
      '<li>VERDE <50 µg/m³</li>' +
      '<li>AMARILLO  50 – 150 µg/m³.</li>' +
      '<li>ROJO > 150 µg/m³.</li>' +
      '</ul><br/>' +
      '<b>4. Unidades de medida </b><br/>' +
      'La valoración de las partículas en suspensión se realiza en función del peso de las partículas de cada tamaño por volumen de aire (μm/m³).<br/><br/>' +
      '<b>5. Fuentes </b><br/>' +
      'La asociación de partículas en suspensión en el aire interior, con la contaminación ambiental exterior es directa. El tráfico rodado (vehículos diesel, principalmente) o la industria (chimeneas, carbón, incineradoras, canteras, determinados trabajos agrícolas, etc.) son la principal fuente de emisión, y que, en función del sistema de ventilación y filtros, pueden detectarse en ambientes interiores. Sin embargo, el interior de edificios tiene su propia fuente de emisión de partículas en aparatos de combustión o el humo del tabaco, o tener un origen biológico como polen, esporas, bacterias u hongos.<br/>' +
      'En ausencia de fuentes interiores conocidas, las concentraciones de partículas en el aire interior son muy similares a las del aire exterior.<br/><br/>' +
      '<b>6. Beneficios de bajo nivel de partículas en suspensión </b><br/>' +
      'Un bajo nivel de partículas en suspensión implica un bajo nivel de polvo en suspensión y por lo tanto ausencia de sustancias irritantes, potencialmente alérgicas y contaminantes. Un aire libre de partículas en suspensión es un aire limpio y característico de un ambiente interior sin elevados movimientos de aire interior.<br/><br/>' +
      '<b>7. Riesgos de niveles de partículas en suspensión elevados </b><br/>' +
      'Las partículas presentes en el aire interior son irritantes respiratorias y potencialmente alérgicas. Los efectos sobre la salud dependen del tipo de partícula presente y su facilidad de penetración en el organismo. En personas con sistema respiratorio debilitado o con patologías respiratorias previas, como asma, afectan más directamente. La diversidad de síntomas asociados va desde irritaciones de las vías respiratorias y oculares, mayor incidencia de enfermedades respiratorias y cardiovasculares, aumento de la incidencia de cáncer a largo plazo, o incluso agravamiento de enfermedades infecciosas o con sistema inmunitario debilitado.<br/><br/>' +
      '<b>8. Recomendaciones de mejora </b><br/> <ul>' +
      '<li>Adecuar el sistema y mantenimiento de la instalación de ventilación a los niveles de contaminación exterior e interior en el ámbito de las partículas. </li>' +
      '<li>Evitar el humo del tabaco en interiores.</li>' +
      '<li>Utilizar aspiradores con filtros de partículas finas (tipo HEPA, ULPA, Clase C…).</li>' +
      '<li>Garantizar una limpieza interior sin arrastre de polvo: balleta húmeda para el polvo, priorizar el fregado al barrido.</li>' +
      '<li>Evitar la alta concentración de elementos textiles en interiores de edificio y/o garantizar una limpieza frecuente: las alfombras y moquetas son fuente de acumulación de polvo y una limpieza insuficiente puede hacer que ese polvo esté en suspensión de forma continua.</li>' +
      '<li>Utilización de purificadores de aire que filtren partículas de hasta 0,3 µm.</li>' +
      '<li>Asegurar la adecuada renovación de aire durante procesos de combustión (chimeneas).</li>' +
      '<li>En edificios de oficinas, ubicar fotocopiadores e impresoras en ambientes separados ventilados.</li>'
  ],

  o3Info: [
    '<b>1. Descripción: </b><br/>' +
      'El ozono es un gas incoloro e inodoro, compuesto de tres átomos de oxígeno. Es la forma, químicamente, más activa del oxígeno.<br/>' +
      'El ozono se encuentra en dos niveles atmosféricos diferentes, con comportamientos diferentes. Por un lado, está el ozono estratosférico, naturalmente situado en la estratosfera, entre 10 y 50 km de distancia de la superficie terrestre. Este ozono se forma por efecto de la radiación solar que rompe las moléculas de oxígeno (O₂), combinándose en O₃. Forma parte de la capa de ozono, filtrando la mayor parte de la radiación ultravioleta y permitiendo la vida en la tierra.<br/>' +
      'Por otro lado, encontramos el ozono troposférico, formado por reacción química entre los óxidos de nitrógeno y compuestos orgánicos volátiles debidos a fuentes naturales y contaminación antropogénica, en presencia de luz solar. En este caso, con consecuencias directas para la salud humana por su efecto en la salud respiratoria y envejecimiento cutáneo en exposiciones prolongadas.<br/>' +
      'Por su propia naturaleza es altamente oxidante, motivo por el que es ampliamente utilizado como desinfectante y biocida para eliminar microorganismos patógenos como virus, bacterias, hongos, moho, esporas u olores desagradables en espacios interiores. También es un subproducto en algunos ionizadores.<br/> <br/>' +
      '<b>2. Niveles recomendados </b><br/>' +
      'Los límites establecidos en España para exposiciones <b>laborales de corta duración (VLA-EC)</b> se clasifican en función del tipo de trabajo a desarrollar: <br/> <br/>' +
      '<table style="border-collapse:collapse;border-spacing:0;table-layout: fixed; width: 460px" className="tg">' +
      '<colgroup>' +
      '<col style="width: 241px">' +
      '<col style="width: 219px">' +
      '</colgroup>' +
      '<thead>' +
      '<tr>' +
      '<th style="background-color:#6caae4;border-color:black;border-style:solid;border-width:1px;color:#ffffff;font-family:Arial, sans-serif;font-size:14px;font-weight:bold;overflow:hidden;padding:10px 5px;text-align:center;vertical-align:top;word-break:normal">Estado</th>' +
      '<th style="background-color:#6caae4;border-color:black;border-style:solid;border-width:1px;color:#ffffff;font-family:Arial, sans-serif;font-size:14px;font-weight:bold;overflow:hidden;padding:10px 5px;text-align:center;vertical-align:top;word-break:normal">VLA-ED </th>' +
      '</tr>' +
      '</thead>' +
      '<tbody>' +
      '<tr>' +
      '<td style="border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:10px 5px;text-align:center;vertical-align:top;word-break:normal">Trabajo pesado</td>' +
      '<td style="border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:10px 5px;text-align:center;vertical-align:top;word-break:normal"> ' +
      '<span style="font-weight:400;font-style:normal;text-decoration:none">0.05 ppm - 100 µg/m³</span>' +
      '</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:10px 5px;text-align:center;vertical-align:top;word-break:normal">Trabajo moderado</td>' +
      '<td style="border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:10px 5px;text-align:center;vertical-align:top;word-break:normal">' +
      '<span style="font-weight:400;font-style:normal;text-decoration:none">0.08 ppm - 160 µg/m³</span>' +
      '</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:10px 5px;text-align:center;vertical-align:top;word-break:normal">Trabajo ligero</td>' +
      '<td style="border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:10px 5px;text-align:center;vertical-align:top;word-break:normal">' +
      '<span style="font-weight:400;font-style:normal;text-decoration:none">0.1 ppm - 200 µg/m³</span>' +
      '</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:10px 5px;text-align:center;vertical-align:top;word-break:normal">Trabajo pesado, moderado o ligero (= 2 horas)</td>' +
      '<td style="border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:10px 5px;text-align:center;vertical-align:top;word-break:normal">' +
      ' <span style="font-weight:400;font-style:normal;text-decoration:none">0.2 ppm - 400 µg/m³</span>' +
      '</td>' +
      '</tr>' +
      '</tbody>' +
      '</table> <br/>' +
      'La concentración recomendada por el organismo de salud Health Canada es de 240 < µg/m³ en concentraciones de hora. La OMS establece que cuando la concentración de ozono en el aire que se respira es superior a los 240 µg/m³ durante más de ocho horas, existe un claro riesgo para la salud: reduce considerablemente la función pulmonar, inflama las vías respiratorias y exacerba el asma, además de favorecer las infecciones respiratorias. <br/>' +
      'La legislación española UNE 400-201-94 <i>generadores de ozono – tratamiento de aire – seguridad química</i>, establece exposiciones recomendadas por debajo de 100 µg/m³. <br/> <br/>' +
      '<b>3. Rangos MyInbiot </b> <br/>' +
      'Los rangos de valores utilizados en el dispositivo MICA tienen en cuenta los requerimientos anteriores, así como sus efectos sensibilizantes e irritantes: <br/>' +
      '<ul>' +
      '<li>VERDE – valores <100 µg/m³</li>' +
      '<li>AMARILLO – valores 100 – 240 µg/m³</li>' +
      '<li>ROJO – valores > 240 µg/m³</li>' +
      '</ul><br/>' +
      '<b>4. Unidades de medida </b><br/>' +
      'La medición de la concentración de ozono en el aire se realiza en µg/m³, mg/m³ o ppm MICA recoge los valores en µg/m³. <br/>' +
      'La conversión entre µg/m³, y ppm – como otras unidades de concentración habitual – depende del tipo de compuesto y las condiciones del ambiente interior. Para una estancia habitada habitual, con una temperatura alrededor de 20 ºC: <br/>' +
      '0,05 ppm ~ 0,1 mg/m³ ~ 100 µg/m³ <br/><br/>' +
      '<b>5. Fuentes </b><br/>' +
      'La infiltración de aire exterior contaminado por ozono es la principal fuente de concentraciones de ozono en espacios interiores. <br/>' +
      'Igualmente se localizan fuentes secundarias, procedentes de generadores interiores de ozono como pueden ser fotocopiadoras, ionizadores, o purificadores de aire instalados y/o mantenidos de manera inadecuada, sin garantizar la renovación de aire mínima requerida para estos equipos o con producción de ozono de forma secundaria. <br/>' +
      'Otra fuente de contaminación interior son los ozonizadores utilizados como sistemas para la higienización y limpieza de espacios interiores, por su acción oxidante y biocida, en los que no se han garantizado las recomendaciones de seguridad de uso, ni el plazo de seguridad de utilización recomendado para la ocupación del espacio.<br/><br/>' +
      '<b>6. Beneficios de bajo nivel de compuestos orgánicos volátiles </b><br/>' +
      'Pasamos entre un 80 y un 90% de nuestro tiempo en espacios interiores. Nuestra interrelación con factores como la temperatura, la humedad, la renovación de aire o la presencia de otros contaminantes caracterizan un adecuado ambiente interior y condicionan nuestra salud. <br/>' +
      'Un ambiente interior sin presencia significativa de ozono, es un espacio donde se haya garantizado que no entra aire exterior con mayor riesgo de contaminación por este contaminante. También representa un adecuado uso de dispositivos interiores con producción secundaria de este compuesto, y donde se ha garantizado su renovación suficiente de aire limpio. <br/>' +
      'A pesar de la presencia de bajos niveles de ozono, su localización en interiores, aunque baja, es motivo de estudio conjunto con el resto de posibles contaminantes presentes.<br/><br/>' +
      '<b>7. Riesgos de concentración de ozono elevada</b><br/>' +
      'Altas concentraciones de ozono son peligrosas para la salud, debido a su efecto irritante para las vías respiratorias. El ozono puede causar dificultades para respirar, tos, fatiga, así como irritación y daños en vías respiratorias y pulmonares, por lo que puede empeora cuadros previos de asma o bronquitis y comprometer la capacidad de combatir otras infecciones o insuficiencias respiratorias. <br/>' +
      'Como muchos contaminantes afecta de manera diversa a personas sanas o con patologías previas. <br/>' +
      'Es especialmente significativo su localización en lugar de alta actividad física, ya que al aumentar la frecuencia respiratoria se inhala una mayor cantidad de oxígeno que puede derivar en aumentar sus consecuencias respiratorias.<br/><br/>' +
      '<b>8. Recomendaciones de mejora </b><br/>' +
      'El ozono, como el resto de contaminantes interiores, no es recomendable localizarlo en interiores de manera continuada. No obstante, en este caso, su alta reactividad le hace reaccionar con otros compuestos, derivando en contaminaciones secundarias, como por ejemplo la reacción de terpenos con ozono puede elevar las concentraciones de formaldehído en interiores. <br/>' +
      'Por este motivo, es importante evitar la exposición de productos de higiene y limpieza con al aire interior de manera continua, garantizando su almacenamiento con tapones bien cerrados y en lugares adecuadamente preservados y preferiblemente ventilados. De esta manera, cualquier presencia interior de ozono, ya sea como subproducto, por contaminación exterior o la utilización de ozonificadores, no deriva en el aumento significativo de otros contaminantes interiores. <br/>' +
      'En el caso de utilizar ozonificadores como tratamiento específico en la higienización de un espacio se debe realizar una evaluación conjunta del ambiente interior previo a su utilización, para evitar la contaminación secundaria, y sin ánimo de eliminar contaminantes en fase gas (como dióxido de nitrógeno o gas) o de partículas, por su baja efectividad.<br/>' +
      'Por lo que se recomienda cualquier intervención de monitorización, evaluación y mejora que implique reducir la exposición sinérgica y acumulativa a diferentes contaminantes, acompañado siempre de una adecuada renovación de aire. <br/>',

    '<b>1. Descripción: </b><br/>' +
      'El ozono es un gas incoloro e inodoro, compuesto de tres átomos de oxígeno. Es la forma, químicamente, más activa del oxígeno.<br/>' +
      'El ozono se encuentra en dos niveles atmosféricos diferentes, con comportamientos diferentes. Por un lado, está el ozono estratosférico, naturalmente situado en la estratosfera, entre 10 y 50 km de distancia de la superficie terrestre. Este ozono se forma por efecto de la radiación solar que rompe las moléculas de oxígeno (O₂), combinándose en O₃. Forma parte de la capa de ozono, filtrando la mayor parte de la radiación ultravioleta y permitiendo la vida en la tierra.<br/>' +
      'Por otro lado, encontramos el ozono troposférico, formado por reacción química entre los óxidos de nitrógeno y compuestos orgánicos volátiles debidos a fuentes naturales y contaminación antropogénica, en presencia de luz solar. En este caso, con consecuencias directas para la salud humana por su efecto en la salud respiratoria y envejecimiento cutáneo en exposiciones prolongadas.<br/>' +
      'Por su propia naturaleza es altamente oxidante, motivo por el que es ampliamente utilizado como desinfectante y biocida para eliminar microorganismos patógenos como virus, bacterias, hongos, moho, esporas u olores desagradables en espacios interiores. También es un subproducto en algunos ionizadores.<br/> <br/>' +
      '<b>2. Niveles recomendados </b><br/>' +
      'Los límites establecidos en España para exposiciones <b>laborales de corta duración (VLA-EC)</b> se clasifican en función del tipo de trabajo a desarrollar: <br/> <br/>' +
      '<table style="border-collapse:collapse;border-spacing:0;table-layout: fixed; width: 460px" className="tg">' +
      '<colgroup>' +
      '<col style="width: 241px">' +
      '<col style="width: 219px">' +
      '</colgroup>' +
      '<thead>' +
      '<tr>' +
      '<th style="background-color:#6caae4;border-color:black;border-style:solid;border-width:1px;color:#ffffff;font-family:Arial, sans-serif;font-size:14px;font-weight:bold;overflow:hidden;padding:10px 5px;text-align:center;vertical-align:top;word-break:normal">Estado</th>' +
      '<th style="background-color:#6caae4;border-color:black;border-style:solid;border-width:1px;color:#ffffff;font-family:Arial, sans-serif;font-size:14px;font-weight:bold;overflow:hidden;padding:10px 5px;text-align:center;vertical-align:top;word-break:normal">VLA-ED </th>' +
      '</tr>' +
      '</thead>' +
      '<tbody>' +
      '<tr>' +
      '<td style="border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:10px 5px;text-align:center;vertical-align:top;word-break:normal">Trabajo pesado</td>' +
      '<td style="border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:10px 5px;text-align:center;vertical-align:top;word-break:normal"> ' +
      '<span style="font-weight:400;font-style:normal;text-decoration:none">0.05 ppm - 100 µg/m³</span>' +
      '</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:10px 5px;text-align:center;vertical-align:top;word-break:normal">Trabajo moderado</td>' +
      '<td style="border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:10px 5px;text-align:center;vertical-align:top;word-break:normal">' +
      '<span style="font-weight:400;font-style:normal;text-decoration:none">0.08 ppm - 160 µg/m³</span>' +
      '</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:10px 5px;text-align:center;vertical-align:top;word-break:normal">Trabajo ligero</td>' +
      '<td style="border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:10px 5px;text-align:center;vertical-align:top;word-break:normal">' +
      '<span style="font-weight:400;font-style:normal;text-decoration:none">0.1 ppm - 200 µg/m³ </span>' +
      '</td>' +
      '</tr>' +
      '<tr>' +
      '<td style="border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:10px 5px;text-align:center;vertical-align:top;word-break:normal">Trabajo pesado, moderado o ligero (= 2 horas)</td>' +
      '<td style="border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:10px 5px;text-align:center;vertical-align:top;word-break:normal">' +
      ' <span style="font-weight:400;font-style:normal;text-decoration:none">0.2 ppm - 400 µg/m³</span>' +
      '</td>' +
      '</tr>' +
      '</tbody>' +
      '</table> <br/>' +
      'La concentración recomendada por el organismo de salud Health Canada es de 240 < µg/m³ en concentraciones de hora. La OMS establece que cuando la concentración de ozono en el aire que se respira es superior a los 240 µg/m³ durante más de ocho horas, existe un claro riesgo para la salud: reduce considerablemente la función pulmonar, inflama las vías respiratorias y exacerba el asma, además de favorecer las infecciones respiratorias. <br/>' +
      'La legislación española UNE 400-201-94 <i>generadores de ozono – tratamiento de aire – seguridad química</i>, establece exposiciones recomendadas por debajo de 100 µg/m³. <br/> <br/>' +
      '<b>3. Rangos MyInbiot </b><br/>' +
      'Los rangos de valores utilizados en el dispositivo MICA tienen en cuenta los requerimientos anteriores, así como sus efectos sensibilizantes e irritantes: <br/>' +
      '<ul>' +
      '<li>VERDE – valores <100 µg/m³</li>' +
      '<li>AMARILLO – valores 100 – 240 µg/m³</li>' +
      '<li>ROJO – valores > 240 µg/m³</li>' +
      '</ul> <br/>' +
      '<b>4. Unidades de medida </b><br/>' +
      'La medición de la concentración de ozono en el aire se realiza en µg/m³, mg/m³ o ppm MICA recoge los valores en µg/m³. <br/>' +
      'La conversión entre µg/m³, y ppm – como otras unidades de concentración habitual – depende del tipo de compuesto y las condiciones del ambiente interior. Para una estancia habitada habitual, con una temperatura alrededor de 20 ºC: <br/>' +
      '0,05 ppm ~ 0,1 mg/m³ ~ 100 µg/m³ <br/><br/>' +
      '<b>5. Fuentes </b><br/>' +
      'La infiltración de aire exterior contaminado por ozono es la principal fuente de concentraciones de ozono en espacios interiores. <br/>' +
      'Igualmente se localizan fuentes secundarias, procedentes de generadores interiores de ozono como pueden ser fotocopiadoras, ionizadores, o purificadores de aire instalados y/o mantenidos de manera inadecuada, sin garantizar la renovación de aire mínima requerida para estos equipos o con producción de ozono de forma secundaria. <br/>' +
      'Otra fuente de contaminación interior son los ozonizadores utilizados como sistemas para la higienización y limpieza de espacios interiores, por su acción oxidante y biocida, en los que no se han garantizado las recomendaciones de seguridad de uso, ni el plazo de seguridad de utilización recomendado para la ocupación del espacio.<br/><br/>' +
      '<b>6. Beneficios de bajo nivel de compuestos orgánicos volátiles </b><br/>' +
      'Pasamos entre un 80 y un 90% de nuestro tiempo en espacios interiores. Nuestra interrelación con factores como la temperatura, la humedad, la renovación de aire o la presencia de otros contaminantes caracterizan un adecuado ambiente interior y condicionan nuestra salud. <br/>' +
      'Un ambiente interior sin presencia significativa de ozono, es un espacio donde se haya garantizado que no entra aire exterior con mayor riesgo de contaminación por este contaminante. También representa un adecuado uso de dispositivos interiores con producción secundaria de este compuesto, y donde se ha garantizado su renovación suficiente de aire limpio. <br/>' +
      'A pesar de la presencia de bajos niveles de ozono, su localización en interiores, aunque baja, es motivo de estudio conjunto con el resto de posibles contaminantes presentes.<br/><br/>' +
      '<b>7. Riesgos de concentración de ozono elevada</b><br/>' +
      'Altas concentraciones de ozono son peligrosas para la salud, debido a su efecto irritante para las vías respiratorias. El ozono puede causar dificultades para respirar, tos, fatiga, así como irritación y daños en vías respiratorias y pulmonares, por lo que puede empeora cuadros previos de asma o bronquitis y comprometer la capacidad de combatir otras infecciones o insuficiencias respiratorias. <br/>' +
      'Como muchos contaminantes afecta de manera diversa a personas sanas o con patologías previas. <br/>' +
      'Es especialmente significativo su localización en lugar de alta actividad física, ya que al aumentar la frecuencia respiratoria se inhala una mayor cantidad de oxígeno que puede derivar en aumentar sus consecuencias respiratorias.<br/><br/>' +
      '<b>8. Recomendaciones de mejora </b><br/>' +
      'El ozono, como el resto de contaminantes interiores, no es recomendable localizarlo en interiores de manera continuada. No obstante, en este caso, su alta reactividad le hace reaccionar con otros compuestos, derivando en contaminaciones secundarias, como por ejemplo la reacción de terpenos con ozono puede elevar las concentraciones de formaldehído en interiores. <br/>' +
      'Por este motivo, es importante evitar la exposición de productos de higiene y limpieza con al aire interior de manera continua, garantizando su almacenamiento con tapones bien cerrados y en lugares adecuadamente preservados y preferiblemente ventilados. De esta manera, cualquier presencia interior de ozono, ya sea como subproducto, por contaminación exterior o la utilización de ozonificadores, no deriva en el aumento significativo de otros contaminantes interiores. <br/>' +
      'En el caso de utilizar ozonificadores como tratamiento específico en la higienización de un espacio se debe realizar una evaluación conjunta del ambiente interior previo a su utilización, para evitar la contaminación secundaria, y sin ánimo de eliminar contaminantes en fase gas (como dióxido de nitrógeno o gas) o de partículas, por su baja efectividad.<br/>' +
      'Por lo que se recomienda cualquier intervención de monitorización, evaluación y mejora que implique reducir la exposición sinérgica y acumulativa a diferentes contaminantes, acompañado siempre de una adecuada renovación de aire. <br/>'
  ],
  coInfo: ['Información de monóxido de carbono', 'CO Info', 'CO Info'],
  radonInfo: ['Información de Radón', 'Radon Info', 'Radon Info'],
  covidInfo: [
    '<ul>' +
      '<li style="align-content: center; align-items: center; display: flex"><img alt="low_propagation_probability" src="/favicons/virus_low.png" width="70" height="50"> Probabilidad de propagación baja </li>' +
      '<li style="align-content: center; align-items: center; display: flex"><img alt="medium_propagation_probability" src="/favicons/virus_medium.png" width="70" height="50">  Probabilidad de propagación media </li>' +
      '<li style="align-content: center; align-items: center; display: flex"><img alt="high_propagation_probability" src="/favicons/virus_high.png" width="70" height="50"> Probabilidad de propagación alta </li>' +
      '</ul><br/>' +
      '<b>ÍNDICE VIRUS: </b><br/><br/>' +
      'La transmisión de virus en espacios interiores es <b>compleja y diversa</b>, que puede cambiar significativamente en función del tipo de virus. Hay varios parámetros clave de un espacio interior, como la <b>temperatura interior, la humedad relativa</b>, la eficacia de la ventilación a través de conocer la <b>concentración de CO₂</b> o las <b>partículas en suspensión</b> presentes en el aire interior.<br/>' +
      'En nuestro rango de confort de 20-25°C y 40-60% de humedad relativa el virus es muy estable. Hay estudios que afirman que una <b>baja humedad relativa</b> (por debajo del 40%) <b>favorece su propagación por el aire y disminuye la resistencia de nuestro sistema inmunitario</b>, de modo que mantener un ambiente bien humidificado siempre reducirá las consecuencias de un posible contagio.<br/><br/>' +
      '<b>TEMPERATURA: </b><br/><br/>' +
      'La tasa de infección de virus se reduce significativamente a temperatura ambiente (20ºC), en comparación con temperaturas más frías, a las que los virus tienen <b>mayor persistencia</b>. Por otro lado, a altas temperaturas, los virus se desestabilizan y generalmente se reduce su capacidad de contagio. Las <b>altas temperaturas pueden reducir la actividad de los virus</b>, en algunos casos hasta inactivarla, mientras que las bajas podrían prolongar su actividad. Además, <b>a bajas temperaturas se reduce la eficiencia de nuestras defensas</b> innatas en las vías respiratorias. Cuando la temperatura corporal baja mucho, las defensas del cuerpo se debilitan y se es más propenso a coger infecciones víricas.<br/>' +
      'Sin embargo, hay que tener en cuenta que las altas temperaturas estivales no desactivan completamente la actividad de un <b>virus no estacional</b> (como SARS-CoV-2), motivo por el cual se detecta igualmente incidencia de virus en sitios cálidos.<br/><br/>' +
      '<b>HUMEDAD RELATIVA: </b><br/><br/>' +
      'La humedad relativa afecta a la persistencia de los bioaerosoles y a la capacidad de protección de nuestras mucosas.<br/>' +
      'En entornos con menos del 40% de humedad relativa, las gotas de agua en el aire por tos o estornudos pierden su humedad con facilidad, lo que permite su persistencia en el aire durante periodos de tiempo más largos. <b>Una humedad relativa entre el 40% y el 60% es ideal</b> desde el punto de vista del confort termohigrométrico, aunque en términos de <b>inactivación de virus el 50%</b> es más idónea, situación en la que los virus presentan menor actividad. Con humedad relativa significativamente baja (<40%) se resecan las mucosas de las vías respiratorias <b>reduciendo su capacidad de protección</b> frente a la entrada de agentes patógenos en el organismo.<br/>' +
      '<b>A humedad relativas altas, aumenta la proporción de gérmenes patógenos en el aire</b> y existe una mayor probabilidad de proliferación de mohos, con mayor riesgo de desarrollar enfermedades fúngicas y reacciones alérgicas por contacto con esporas y mohos, especialmente en sistemas inmunológicos debilitados. <br/><br/>' +
      '<b>CO₂ </b><br/><br/>' +
      'La ventilación es la estrategia clave para reducir la concentración de contaminantes en el aire interior, sean compuestos químicos o agentes biológicos como virus. El CO₂, dióxido de carbono es el indicador clave de la eficacia del sistema de ventilación y renovación de aire. En espacios interiores cerrados y ocupados la concentración aumenta rápidamente por el CO₂ emitido en la exhalación. Niveles de CO₂ altos, indican un espacio con una ventilación deficitaria. <br/>' +
      'Igualmente, exposición a niveles de CO₂ altos (>1500 ppm) de forma prolongada, puede causar fatiga, dolores de cabeza y reducción de la capacidad de atención y concentración. Los valores de emisión de CO₂ de una persona varían en función de factores, como la edad, el sexo, o actividad metabólica. <br/><br/>' +
      '<i>"El CO₂ no se degrada con el tiempo, mientras que un virus en aire sí, por lo que las concentraciones de virus en aire decrecerán más rápidamente que las de CO₂. La diferencia depende de varios factores ambientales, tales como radiación UV o temperatura"</i>.<br/> Fuente: <a href=" https://www.ciencia.gob.es/stfls/MICINN/Ministerio/FICHEROS/guia_para_ventilacion_en_aulas_csic.pdf"> https://www.ciencia.gob.es/stfls/MICINN/Ministerio/FICHEROS/guia_para_ventilacion_en_aulas_csic.pdf</a> <br/><br/>' +
      '<b>PM2.5 </b><br/><br/>' +
      'PM – <i>Particulate Matter</i> o <b>partículas en suspensión</b> es una mezcla compleja de sustancias de distinta composición química y de diversa naturaleza que permanecen en suspensión en el aire interior. Los virus son contaminantes biológicos que permanecen en el aire de espacios interiores, al margen de por contacto cercano con personas infectadas, también debido por aerosoles transportables (transmisión de virus por <b>vía aérea</b>). Los <b>aerosoles</b> son los vehículos a los cuales se adhieren los virus y que les permiten permanecer en suspensión durante más tiempo, para ser inhaladas por otras personas.<br/>' +
      'La transmisión y la virulencia de los virus también depende del tamaño y de la concentración de los aerosoles respirados. Con respiración nasal típica los aerosoles se pueden depositar de manera continua en el sistema respiratorio. Y en particular, los <b>aerosoles pequeños</b> (los menores de 2.5 μm, - <b>PM2.5</b>) <b>penetran profundamente en el trayecto respiratorio</b> y tienen la capacidad de permanecer más tiempo en suspensión que partículas de más tamaño (PM10), que se depositan por gravedad en superficies con mayor facilidad.<br/>' +
      'Mantener <b>bajos los niveles de PM2.5</b> ayuda a minimizar la probabilidad de propagación de un virus en un espacio interior.<br/><br/>' +
      '<a href="http://tinyurl.com/faqs-aerosol">http://tinyurl.com/faqs-aerosol</a> <br/>' +
      '<a href="https://gesinor.com/wp-content/uploads/2021/01/asi-contagia-el-coronavirus-en-el-aire4.pdf">https://gesinor.com/wp-content/uploads/2021/01/asi-contagia-el-coronavirus-en-el-aire4.pdf</a> <br/>' +
      '<a href="https://ccdd.hsph.harvard.edu/covid-19-research/">https://ccdd.hsph.harvard.edu/covid-19-research/</a><br/><br/>',

    '<ul>' +
      '<li style="align-content: center; align-items: center; display: flex"><img alt="low_propagation_probability" src="/favicons/virus_low.png" width="70" height="50"> Probabilidad de propagación baja </li>' +
      '<li style="align-content: center; align-items: center; display: flex"><img alt="medium_propagation_probability" src="/favicons/virus_medium.png" width="70" height="50">  Probabilidad de propagación media </li>' +
      '<li style="align-content: center; align-items: center; display: flex"><img alt="high_propagation_probability" src="/favicons/virus_high.png" width="70" height="50"> Probabilidad de propagación alta </li>' +
      '</ul><br/>' +
      '<b>ÍNDICE VIRUS: </b><br/><br/>' +
      'La transmisión de virus en espacios interiores es <b>compleja y diversa</b>, que puede cambiar significativamente en función del tipo de virus. Hay varios parámetros clave de un espacio interior, como la <b>temperatura interior, la humedad relativa</b>, la eficacia de la ventilación a través de conocer la <b>concentración de CO₂</b> o las <b>partículas en suspensión</b> presentes en el aire interior.<br/>' +
      'En nuestro rango de confort de 20-25°C y 40-60% de humedad relativa el virus es muy estable. Hay estudios que afirman que una <b>baja humedad relativa</b> (por debajo del 40%) <b>favorece su propagación por el aire y disminuye la resistencia de nuestro sistema inmunitario</b>, de modo que mantener un ambiente bien humidificado siempre reducirá las consecuencias de un posible contagio.<br/><br/>' +
      '<b>TEMPERATURA: </b><br/><br/>' +
      'La tasa de infección de virus se reduce significativamente a temperatura ambiente (20ºC), en comparación con temperaturas más frías, a las que los virus tienen <b>mayor persistencia</b>. Por otro lado, a altas temperaturas, los virus se desestabilizan y generalmente se reduce su capacidad de contagio. Las <b>altas temperaturas pueden reducir la actividad de los virus</b>, en algunos casos hasta inactivarla, mientras que las bajas podrían prolongar su actividad. Además, <b>a bajas temperaturas se reduce la eficiencia de nuestras defensas</b> innatas en las vías respiratorias. Cuando la temperatura corporal baja mucho, las defensas del cuerpo se debilitan y se es más propenso a coger infecciones víricas.<br/>' +
      'Sin embargo, hay que tener en cuenta que las altas temperaturas estivales no desactivan completamente la actividad de un <b>virus no estacional</b> (como SARS-CoV-2), motivo por el cual se detecta igualmente incidencia de virus en sitios cálidos.<br/><br/>' +
      '<b>HUMEDAD RELATIVA: </b><br/><br/>' +
      'La humedad relativa afecta a la persistencia de los bioaerosoles y a la capacidad de protección de nuestras mucosas.<br/>' +
      'En entornos con menos del 40% de humedad relativa, las gotas de agua en el aire por tos o estornudos pierden su humedad con facilidad, lo que permite su persistencia en el aire durante periodos de tiempo más largos. <b>Una humedad relativa entre el 40% y el 60% es ideal</b> desde el punto de vista del confort termohigrométrico, aunque en términos de <b>inactivación de virus el 50%</b> es más idónea, situación en la que los virus presentan menor actividad. Con humedad relativa significativamente baja (<40%) se resecan las mucosas de las vías respiratorias <b>reduciendo su capacidad de protección</b> frente a la entrada de agentes patógenos en el organismo.<br/>' +
      '<b>A humedad relativas altas, aumenta la proporción de gérmenes patógenos en el aire</b> y existe una mayor probabilidad de proliferación de mohos, con mayor riesgo de desarrollar enfermedades fúngicas y reacciones alérgicas por contacto con esporas y mohos, especialmente en sistemas inmunológicos debilitados. <br/><br/>' +
      '<b>CO₂ </b><br/><br/>' +
      'La ventilación es la estrategia clave para reducir la concentración de contaminantes en el aire interior, sean compuestos químicos o agentes biológicos como virus. El CO₂, dióxido de carbono es el indicador clave de la eficacia del sistema de ventilación y renovación de aire. En espacios interiores cerrados y ocupados la concentración aumenta rápidamente por el CO₂ emitido en la exhalación. Niveles de CO₂ altos, indican un espacio con una ventilación deficitaria. <br/>' +
      'Igualmente, exposición a niveles de CO₂ altos (>1500 ppm) de forma prolongada, puede causar fatiga, dolores de cabeza y reducción de la capacidad de atención y concentración. Los valores de emisión de CO₂ de una persona varían en función de factores, como la edad, el sexo, o actividad metabólica. <br/><br/>' +
      '<i>"El CO₂ no se degrada con el tiempo, mientras que un virus en aire sí, por lo que las concentraciones de virus en aire decrecerán más rápidamente que las de CO₂. La diferencia depende de varios factores ambientales, tales como radiación UV o temperatura"</i>.<br/> Fuente: <a href=" https://www.ciencia.gob.es/stfls/MICINN/Ministerio/FICHEROS/guia_para_ventilacion_en_aulas_csic.pdf"> https://www.ciencia.gob.es/stfls/MICINN/Ministerio/FICHEROS/guia_para_ventilacion_en_aulas_csic.pdf</a> <br/><br/>' +
      '<b>PM2.5 </b><br/><br/>' +
      'PM – <i>Particulate Matter</i> o <b>partículas en suspensión</b> es una mezcla compleja de sustancias de distinta composición química y de diversa naturaleza que permanecen en suspensión en el aire interior. Los virus son contaminantes biológicos que permanecen en el aire de espacios interiores, al margen de por contacto cercano con personas infectadas, también debido por aerosoles transportables (transmisión de virus por <b>vía aérea</b>). Los <b>aerosoles</b> son los vehículos a los cuales se adhieren los virus y que les permiten permanecer en suspensión durante más tiempo, para ser inhaladas por otras personas.<br/>' +
      'La transmisión y la virulencia de los virus también depende del tamaño y de la concentración de los aerosoles respirados. Con respiración nasal típica los aerosoles se pueden depositar de manera continua en el sistema respiratorio. Y en particular, los <b>aerosoles pequeños</b> (los menores de 2.5 μm, - <b>PM2.5</b>) <b>penetran profundamente en el trayecto respiratorio</b> y tienen la capacidad de permanecer más tiempo en suspensión que partículas de más tamaño (PM10), que se depositan por gravedad en superficies con mayor facilidad.<br/>' +
      'Mantener <b>bajos los niveles de PM2.5</b> ayuda a minimizar la probabilidad de propagación de un virus en un espacio interior.<br/><br/>' +
      '<a href="http://tinyurl.com/faqs-aerosol">http://tinyurl.com/faqs-aerosol</a> <br/>' +
      '<a href="https://gesinor.com/wp-content/uploads/2021/01/asi-contagia-el-coronavirus-en-el-aire4.pdf">https://gesinor.com/wp-content/uploads/2021/01/asi-contagia-el-coronavirus-en-el-aire4.pdf</a> <br/>' +
      '<a href="https://ccdd.hsph.harvard.edu/covid-19-research/">https://ccdd.hsph.harvard.edu/covid-19-research/</a><br/><br/>'
  ],
  weatherInfo: ['Informacion del tiempo exterior', 'Weather info', 'Wetterinfos'],
  allDataSettings: [
    'Aplicar el rango de tiempo para todos los tipos de datos',
    'Apply this data range for all data types',
    'Wenden Sie diesen Datenbereich für alle Datentypen an'
  ],
  dataSettingsFormInfo: [
    'Estamos teniendo dificultades accediendo a datos anteriores a un mes.' +
      '<br />' +
      'Por ahora, no cambies el rango a mas de 1 mes.',

    "We're experiencing some diffuculties retrieving data older than a month." +
      '<br />' +
      'This is currently being improved. But for now, just dont retrieve data older than a month.',

    'Beim Abrufen von Daten, die älter als einen Monat sind, treten einige Schwierigkeiten auf.' +
      '<br />' +
      'Dies wird derzeit verbessert. Aber im Moment sollten Sie nur keine Daten abrufen, die älter als einen Monat sind.'
  ],
  privacyPolicy: [
    '<h1>Política de Privacidad</h1>' +
      '<br/>' +
      '<br/>' +
      '<p>La presente política de privacidad explica qué tipo de información recopilamos, almacenamos y usamos cuando utiliza los dispositivos y servicios. </p>' +
      '<br/>' +
      '<p>Nuestra actividad se rige por el comúnmente conocido como Reglamento General de Protección de Datos (RGPD), que es el Reglamento (UE) 2016/679 del Parlamento y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE, y demás normativa nacional que sea de aplicación.</p>' +
      '<br/>' +
      '<h3>Información que nos proporcionas</h3>' +
      '<br/>' +
      '<h4>Información de registro de cuenta</h4>' +
      '<br/>' +
      '<p>Cuando creas una cuenta en <b>myinbiot.com</b>, recopilamos información de identificación personal, que puede incluir su nombre, apellidos, dirección de correo electrónico, contraseña y localización donde el dispositivo será utilizado.  Crear una cuenta le permite usar todas las funciones disponibles del Servicio.<p>' +
      '<br/>' +
      '<p>No utilizaremos su información personal para ningún otro propósito sin su consentimiento.</p>' +
      '<br/>' +
      '<h4>Información de configuración del dispositivo</h4>' +
      '<br/>' +
      '<p>Cuando añades dispositivos a la plataforma, proporcionas voluntariamente su código postal. Esto garantiza que pueda ver con precisión mediciones del tiempo exterior en la zona en la que se encuentra. Una vez añadido a tu cuenta los datos recopilados a través de los sensores en sus dispositivos se transfieren a nuestros servidores. Esto te permite acceder a todas sus mediciones de datos actuales e históricos de la calidad del aire para ver cómo sus hábitos y actividades afectan su    ambiente interior.</p>' +
      '<br/>' +
      '<h4>Información cuando el dispositivo no opera correctamente</h4>' +
      '<br/>' +
      '<p>Nuestro objetivo es garantizar que los dispositivos funcionen correctamente en todo momento, entendemos que a veces los dispositivos pueden encontrar un problema al transmitir información, conectarse a los servicios o al recopilar datos ambientales. Para mejorar continuamente su experiencia, cuando los dispositivos encuentran un problema, se nos puede contactar para que podamos ayudarlo. Para verificar que le ocurre al dispositivo en algunos casos será necesario añadirlo a nuestro sistema de manera que tendremos acceso a los datos registrados por el dispositivo, pero en ningún caso tendremos acceso a los datos personales.</p>' +
      '<br/>' +
      '<h3>Tratamiento de datos</h3>' +
      '<br/>' +
      '<p>Utilizamos la información que recopilamos para responder a tus correos electrónicos y para enviarte mensajes de servicio. inBiot Monitoring S.L. recoge exclusivamente la información personal en la medida necesaria para alcanzar un propósito específico. La información no se utilizará para una finalidad incompatible con la descrita.</p>' +
      '<br/>' +
      '<p>inBiot Monitoring S.L. solamente revela la información a terceros si es necesaria para el cumplimiento de la finalidad del servicio y únicamente a las personas que deben conocerlos. Todo ello al objeto de que se pueda prestar el servicio tratando tus datos personales con confidencialidad y reserva, conforme a la legislación vigente.</p>' +
      '<br/>' +
      '<h3>¿Cuánto tiempo guardamos tus datos?</h3>' +
      '<br/>' +
      '<p>inBiot Monitoring S.L. solamente guarda los datos el tiempo necesario para cumplir la finalidad de su recogida o de su procesamiento posterior. El periodo de conservación de los datos dependerá del servicio y en cada servicio se indicará la duración del tratamiento de datos personales.</p>' +
      '<br/>' +
      '<h3>Derechos de los usuarios</h3>' +
      '<br/>' +
      '<p>Cualquier persona tiene derecho a acceder y modificar su información personal, como su nombre, apellido, contraseña y país. Así como solicitar el acceso, rectificación, supresión, limitación del tratamiento, oposición o derecho a la portabilidad de sus datos personales, escribiéndonos a nuestra dirección postal o electrónica indicada en el apartado “Identidad del titular de este sitio web” del presente Términos de Uso, comunicándonos el derecho que desea ejercer. Tendrá derecho igualmente a retirar el consentimiento prestado en cualquier momento. La retirada del consentimiento no afectará a la licitud del tratamiento efectuado antes de la retirada de dicho consentimiento. Tiene derecho a presentar una reclamación ante la autoridad de control si considera que pueden haber sido vulnerados sus derechos en lo concerniente a la protección de sus datos personales.</p>' +
      '<br/>' +
      '<h3>Modificación de este documento</h3>' +
      '<br/>' +
      '<p>La legislación puede cambiar, nuestro sitio web y otros servicios pueden evolucionar, nosotros podemos recurrir a nuevos proveedores de servicios. Como resultado, es posible que tengamos que realizar algunos cambios en la Política de Privacidad y se reserva el derecho a actualizarla o modificarla en cualquier momento. Esta Política de Privacidad puede consultarse en myinbiot.com en cualquier momento.</p>',
    '<h1>Política de Privacidad</h1>' +
      '<br/>' +
      '<br/>' +
      '<p>La presente política de privacidad explica qué tipo de información recopilamos, almacenamos y usamos cuando utiliza los dispositivos y servicios. </p>' +
      '<br/>' +
      '<p>Nuestra actividad se rige por el comúnmente conocido como Reglamento General de Protección de Datos (RGPD), que es el Reglamento (UE) 2016/679 del Parlamento y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE, y demás normativa nacional que sea de aplicación.</p>' +
      '<br/>' +
      '<h3>Información que nos proporcionas</h3>' +
      '<br/>' +
      '<h4>Información de registro de cuenta</h4>' +
      '<br/>' +
      '<p>Cuando creas una cuenta en <b>myinbiot.com</b>, recopilamos información de identificación personal, que puede incluir su nombre, apellidos, dirección de correo electrónico, contraseña y localización donde el dispositivo será utilizado.  Crear una cuenta le permite usar todas las funciones disponibles del Servicio.<p>' +
      '<br/>' +
      '<p>No utilizaremos su información personal para ningún otro propósito sin su consentimiento.</p>' +
      '<br/>' +
      '<h4>Información de configuración del dispositivo</h4>' +
      '<br/>' +
      '<p>Cuando añades dispositivos a la plataforma, proporcionas voluntariamente su código postal. Esto garantiza que pueda ver con precisión mediciones del tiempo exterior en la zona en la que se encuentra. Una vez añadido a tu cuenta los datos recopilados a través de los sensores en sus dispositivos se transfieren a nuestros servidores. Esto te permite acceder a todas sus mediciones de datos actuales e históricos de la calidad del aire para ver cómo sus hábitos y actividades afectan su    ambiente interior.</p>' +
      '<br/>' +
      '<h4>Información cuando el dispositivo no opera correctamente</h4>' +
      '<br/>' +
      '<p>Nuestro objetivo es garantizar que los dispositivos funcionen correctamente en todo momento, entendemos que a veces los dispositivos pueden encontrar un problema al transmitir información, conectarse a los servicios o al recopilar datos ambientales. Para mejorar continuamente su experiencia, cuando los dispositivos encuentran un problema, se nos puede contactar para que podamos ayudarlo. Para verificar que le ocurre al dispositivo en algunos casos será necesario añadirlo a nuestro sistema de manera que tendremos acceso a los datos registrados por el dispositivo, pero en ningún caso tendremos acceso a los datos personales.</p>' +
      '<br/>' +
      '<h3>Tratamiento de datos</h3>' +
      '<br/>' +
      '<p>Utilizamos la información que recopilamos para responder a tus correos electrónicos y para enviarte mensajes de servicio. inBiot Monitoring S.L. recoge exclusivamente la información personal en la medida necesaria para alcanzar un propósito específico. La información no se utilizará para una finalidad incompatible con la descrita.</p>' +
      '<br/>' +
      '<p>inBiot Monitoring S.L. solamente revela la información a terceros si es necesaria para el cumplimiento de la finalidad del servicio y únicamente a las personas que deben conocerlos. Todo ello al objeto de que se pueda prestar el servicio tratando tus datos personales con confidencialidad y reserva, conforme a la legislación vigente.</p>' +
      '<br/>' +
      '<h3>¿Cuánto tiempo guardamos tus datos?</h3>' +
      '<br/>' +
      '<p>inBiot Monitoring S.L. solamente guarda los datos el tiempo necesario para cumplir la finalidad de su recogida o de su procesamiento posterior. El periodo de conservación de los datos dependerá del servicio y en cada servicio se indicará la duración del tratamiento de datos personales.</p>' +
      '<br/>' +
      '<h3>Derechos de los usuarios</h3>' +
      '<br/>' +
      '<p>Cualquier persona tiene derecho a acceder y modificar su información personal, como su nombre, apellido, contraseña y país. Así como solicitar el acceso, rectificación, supresión, limitación del tratamiento, oposición o derecho a la portabilidad de sus datos personales, escribiéndonos a nuestra dirección postal o electrónica indicada en el apartado “Identidad del titular de este sitio web” del presente Términos de Uso, comunicándonos el derecho que desea ejercer. Tendrá derecho igualmente a retirar el consentimiento prestado en cualquier momento. La retirada del consentimiento no afectará a la licitud del tratamiento efectuado antes de la retirada de dicho consentimiento. Tiene derecho a presentar una reclamación ante la autoridad de control si considera que pueden haber sido vulnerados sus derechos en lo concerniente a la protección de sus datos personales.</p>' +
      '<br/>' +
      '<h3>Modificación de este documento</h3>' +
      '<br/>' +
      '<p>La legislación puede cambiar, nuestro sitio web y otros servicios pueden evolucionar, nosotros podemos recurrir a nuevos proveedores de servicios. Como resultado, es posible que tengamos que realizar algunos cambios en la Política de Privacidad y se reserva el derecho a actualizarla o modificarla en cualquier momento. Esta Política de Privacidad puede consultarse en myinbiot.com en cualquier momento.</p>'
  ],

  termsOfUse: [
    '<h1>Términos de Uso</h1>' +
      '<br/>' +
      '<p>Al acceder y / o utilizar los servicios, usted acepta estos Términos y nuestra Política de privacidad en su nombre o en la entidad que representa en relación con dicho acceso y / o uso. Usted declara y garantiza que tiene el derecho, la autoridad y la capacidad de aceptar y aceptar estos Términos en su nombre o en la entidad que representa. Usted acepta permitirnos comunicarnos electrónicamente con respecto a los dispositivos y los servicios.</p>' +
      '<br/>' +
      '<p>Como se describe a continuación, acepta permitirnos actualizar automáticamente el software relacionado con los dispositivos y los servicios sin notificarle ni solicitar su consentimiento siempre que esas actualizaciones no afecten a los datos privados. Si una actualización necesita modificar o actualizar sus datos privados de alguna manera, le enviaremos una solicitud de consentimiento antes de cualquier modificación.</p>' +
      '<br/>' +
      '<p>Si no acepta algunos de los Términos, no cree una cuenta en myinbiot.com.</p>' +
      '<br/>' +
      '<p>inBiot Monitoring S.L. se reserva la facultad de efectuar, en cualquier momento y sin necesidad de previo aviso, modificaciones y actualizaciones de la información contenida en su Web o en la configuración y presentación de ésta.</p>' +
      '<p>Con objeto de mantener actualizada la información publicada en los portales, los contenidos del mismo podrán ser modificados, corregidos, eliminados o añadidos en cualquier momento, por lo que será conveniente comprobar la vigencia o exactitud de los mismos acudiendo a las fuentes oficiales.</p>' +
      '<br/>' +
      '<h2>Identidad del Titular</h2>' +
      '<br/>' +
      '<p>El titular del presente Sitio Web es:</p>' +
      '<ul>' +
      '<li>Denominación social: <b> inBiot Monitoring S.L.</b></li>' +
      '<li>C.I.F.: B71361596</li>' +
      '<li>Domicilio Social: Pol. Ind. Mocholí, plaza CEIN 5 of. T-7 Código Postal: 31110 Noáin- Navarra (España)</li>' +
      '<li>e-mail de contacto: info@inbiot.es</li>' +
      '</ul>' +
      '<br/>' +
      '<h2>Legislación</h2>' +
      '<br/>' +
      '<p>Con carácter general, el presente Sitio Web y las relaciones entre el Titular del presente Sitio Web con los Usuarios, se rigen a todos los efectos por la legislación y jurisdicción españolas.</p>' +
      '<br/>' +
      '<h2>Propiedad Intelectual</h2>' +
      '<br/>' +
      '<p>Los derechos de propiedad intelectual del contenido, diseño gráfico y códigos del presente Sitio Web, tanto existente en el momento actual como en el futuro, son titularidad del Titular del presente Sitio Web. Por lo tanto, queda prohibida su reproducción, distribución, divulgación, comunicación pública, copia, transformación o cualquier otra actividad que se pueda realizar con los contenidos de sus páginas web ni aún citando las fuentes, salvo consentimiento por escrito del Titular del presente Sitio Web. Todos los nombres comerciales, marcas o signos distintivos de cualquier clase contenidos en las páginas web del presente Sitio Web son propiedad de sus dueños y están protegidos por ley. El acceso por parte de los Usuarios al Sitio Web no supone la concesión de derecho alguno sobre dichos derechos de propiedad intelectual.</p>' +
      '<br/>' +
      '<h2>Confidencialidad de la cuenta</h2>' +
      '<br/>' +
      '<p>Usted es responsable de mantener la confidencialidad de su Cuenta, incluida su información de inicio de sesión y contraseña. Usted acepta mantener su contraseña de forma segura y será responsable de todas las actividades que ocurran en su Cuenta. Si sospecha un uso no autorizado de su cuenta, acepta notificarnos de inmediato a info@inbiot.es. No somos responsables de ninguna pérdida o daño que surja debido a su incumplimiento de los requisitos anteriores.</p>' +
      '<br/>' +
      '<h2>Restricciones de Uso</h2>' +
      '<br/>' +
      '<p>Al usar los dispositivos y los servicios, usted acepta lo siguiente: </p>' +
      '<ul>' +
      '<li>No comercializará los dispositivos o los servicios alquilando, vendiendo, otorgando licencias o distribuyendo los dispositivos o los servicios.</li>' +
      '<li>No accederá a los servicios para crear un servicio similar o competitivo.</li>' +
      '<li>No realizará ingeniería inversa, desmontará, compilará inversa ni realizará trabajos derivados de ningún componente de los dispositivos o los servicios.</li>' +
      '<li>No copiará, reproducirá, distribuirá, republicará, descargará, mostrará, publicará ni transmitirá los servicios de ninguna forma ni por ningún medio.</li>' +
      '<li>No utilizará los servicios para distribuir virus, gusanos u otro software que pueda dañar cualquier elemento de los servicios.</li>' +
      '<li>No eliminará, alterará ni ocultará los avisos de derechos de propiedad intelectual que puedan mostrarse en relación con los dispositivos o los servicios.</li>' +
      '</ul>' +
      '<br/>' +
      '<h2>Exclusión de Garantías y de Responsabilidad</h2>' +
      '<br/>' +
      '<p>inBiot Monitoring S.L. rechaza todas las garantías y condiciones relativas a los productos, ya sean expresas, implícitas o legales, incluso las garantías implícitas de calidad comercial, conforme a un uso específico, de titularidad, no violación de los derechos de terceros, disfrute pacífico y exactitud. inBiot Monitoring S.L. no garantiza que la utilización del producto no incluya ningún error o no se interrumpa jamás o que el producto trate siempre los datos correctamente.</p>' +
      '<br/>' +
      '<p>La plataforma facilita información relativa a las mediciones interiores. Toda la información relativa al producto se ofrece para mayor comodidad del cliente, tal como está y según disponibilidad. inBiot Monitoring S.L.  no puede asegurar ni garantizar que la información relativa al producto esté disponible, sea precisa o fiable. La utilización de la información relativa al producto, de los servicios o del producto se hace a su propia discreción y bajo su propia responsabilidad. Usted es plenamente responsable (y inBiot Monitoring S.L.  declina cualquier responsabilidad) en caso de pérdida, responsabilidad o daño derivado del uso que usted haga de la información relativa al producto, de los servicios o del producto.</p>' +
      '<br/>' +
      '<p>inBiot Monitoring S.L. no será responsable por ningún daño de ningún tipo que surja del uso del sitio myinbiot.com o de cualquier información, contenido, materiales, productos (incluido software) o servicios incluidos o puestos a su disposición de otro modo a través del sitio, incluyendo, sin limitarse a ellos, daños directos, indirectos, incidentales, punitivos, y emergentes, a no ser que se especifique lo contrario en este contrato.</p>',
    '<h1>Términos de Uso</h1>' +
      '<br/>' +
      '<p>Al acceder y / o utilizar los servicios, usted acepta estos Términos y nuestra Política de privacidad en su nombre o en la entidad que representa en relación con dicho acceso y / o uso. Usted declara y garantiza que tiene el derecho, la autoridad y la capacidad de aceptar y aceptar estos Términos en su nombre o en la entidad que representa. Usted acepta permitirnos comunicarnos electrónicamente con respecto a los dispositivos y los servicios.</p>' +
      '<br/>' +
      '<p>Como se describe a continuación, acepta permitirnos actualizar automáticamente el software relacionado con los dispositivos y los servicios sin notificarle ni solicitar su consentimiento siempre que esas actualizaciones no afecten a los datos privados. Si una actualización necesita modificar o actualizar sus datos privados de alguna manera, le enviaremos una solicitud de consentimiento antes de cualquier modificación.</p>' +
      '<br/>' +
      '<p>Si no acepta algunos de los Términos, no cree una cuenta en myinbiot.com.</p>' +
      '<br/>' +
      '<p>inBiot Monitoring S.L. se reserva la facultad de efectuar, en cualquier momento y sin necesidad de previo aviso, modificaciones y actualizaciones de la información contenida en su Web o en la configuración y presentación de ésta.</p>' +
      '<p>Con objeto de mantener actualizada la información publicada en los portales, los contenidos del mismo podrán ser modificados, corregidos, eliminados o añadidos en cualquier momento, por lo que será conveniente comprobar la vigencia o exactitud de los mismos acudiendo a las fuentes oficiales.</p>' +
      '<br/>' +
      '<h2>Identidad del Titular</h2>' +
      '<br/>' +
      '<p>El titular del presente Sitio Web es:</p>' +
      '<ul>' +
      '<li>Denominación social: <b> inBiot Monitoring S.L.</b></li>' +
      '<li>C.I.F.: B71361596</li>' +
      '<li>Domicilio Social: Pol. Ind. Mocholí, plaza CEIN 5 of. T-7 Código Postal: 31110 Noáin- Navarra (España)</li>' +
      '<li>e-mail de contacto: info@inbiot.es</li>' +
      '</ul>' +
      '<br/>' +
      '<h2>Legislación</h2>' +
      '<br/>' +
      '<p>Con carácter general, el presente Sitio Web y las relaciones entre el Titular del presente Sitio Web con los Usuarios, se rigen a todos los efectos por la legislación y jurisdicción españolas.</p>' +
      '<br/>' +
      '<h2>Propiedad Intelectual</h2>' +
      '<br/>' +
      '<p>Los derechos de propiedad intelectual del contenido, diseño gráfico y códigos del presente Sitio Web, tanto existente en el momento actual como en el futuro, son titularidad del Titular del presente Sitio Web. Por lo tanto, queda prohibida su reproducción, distribución, divulgación, comunicación pública, copia, transformación o cualquier otra actividad que se pueda realizar con los contenidos de sus páginas web ni aún citando las fuentes, salvo consentimiento por escrito del Titular del presente Sitio Web. Todos los nombres comerciales, marcas o signos distintivos de cualquier clase contenidos en las páginas web del presente Sitio Web son propiedad de sus dueños y están protegidos por ley. El acceso por parte de los Usuarios al Sitio Web no supone la concesión de derecho alguno sobre dichos derechos de propiedad intelectual.</p>' +
      '<br/>' +
      '<h2>Confidencialidad de la cuenta</h2>' +
      '<br/>' +
      '<p>Usted es responsable de mantener la confidencialidad de su Cuenta, incluida su información de inicio de sesión y contraseña. Usted acepta mantener su contraseña de forma segura y será responsable de todas las actividades que ocurran en su Cuenta. Si sospecha un uso no autorizado de su cuenta, acepta notificarnos de inmediato a info@inbiot.es. No somos responsables de ninguna pérdida o daño que surja debido a su incumplimiento de los requisitos anteriores.</p>' +
      '<br/>' +
      '<h2>Restricciones de Uso</h2>' +
      '<br/>' +
      '<p>Al usar los dispositivos y los servicios, usted acepta lo siguiente: </p>' +
      '<ul>' +
      '<li>No comercializará los dispositivos o los servicios alquilando, vendiendo, otorgando licencias o distribuyendo los dispositivos o los servicios.</li>' +
      '<li>No accederá a los servicios para crear un servicio similar o competitivo.</li>' +
      '<li>No realizará ingeniería inversa, desmontará, compilará inversa ni realizará trabajos derivados de ningún componente de los dispositivos o los servicios.</li>' +
      '<li>No copiará, reproducirá, distribuirá, republicará, descargará, mostrará, publicará ni transmitirá los servicios de ninguna forma ni por ningún medio.</li>' +
      '<li>No utilizará los servicios para distribuir virus, gusanos u otro software que pueda dañar cualquier elemento de los servicios.</li>' +
      '<li>No eliminará, alterará ni ocultará los avisos de derechos de propiedad intelectual que puedan mostrarse en relación con los dispositivos o los servicios.</li>' +
      '</ul>' +
      '<br/>' +
      '<h2>Exclusión de Garantías y de Responsabilidad</h2>' +
      '<br/>' +
      '<p>inBiot Monitoring S.L. rechaza todas las garantías y condiciones relativas a los productos, ya sean expresas, implícitas o legales, incluso las garantías implícitas de calidad comercial, conforme a un uso específico, de titularidad, no violación de los derechos de terceros, disfrute pacífico y exactitud. inBiot Monitoring S.L. no garantiza que la utilización del producto no incluya ningún error o no se interrumpa jamás o que el producto trate siempre los datos correctamente.</p>' +
      '<br/>' +
      '<p>La plataforma facilita información relativa a las mediciones interiores. Toda la información relativa al producto se ofrece para mayor comodidad del cliente, tal como está y según disponibilidad. inBiot Monitoring S.L.  no puede asegurar ni garantizar que la información relativa al producto esté disponible, sea precisa o fiable. La utilización de la información relativa al producto, de los servicios o del producto se hace a su propia discreción y bajo su propia responsabilidad. Usted es plenamente responsable (y inBiot Monitoring S.L.  declina cualquier responsabilidad) en caso de pérdida, responsabilidad o daño derivado del uso que usted haga de la información relativa al producto, de los servicios o del producto.</p>' +
      '<br/>' +
      '<p>inBiot Monitoring S.L. no será responsable por ningún daño de ningún tipo que surja del uso del sitio myinbiot.com o de cualquier información, contenido, materiales, productos (incluido software) o servicios incluidos o puestos a su disposición de otro modo a través del sitio, incluyendo, sin limitarse a ellos, daños directos, indirectos, incidentales, punitivos, y emergentes, a no ser que se especifique lo contrario en este contrato.</p>'
  ]
}
