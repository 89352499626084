import AppLayout from 'common/components/layouts/AppLayout'
import AuthLayout from 'common/components/layouts/AuthLayout'
import MainLayout from 'common/components/layouts/MainLayout'
import SystemLayout from 'common/components/layouts/SystemLayout'

import Login from 'modules/Login'
import Logout from 'modules/Logout'
import Dashboard from 'modules/Dashboard'
import SystemPanel from 'modules/SystemPanel'

import requireAdmin from 'utils/requireAdmin'

export default store => ({
  component: AppLayout,
  childRoutes: [
    {
      component: AuthLayout,
      childRoutes: [Login(store)]
    },
    {
      component: requireAdmin(MainLayout),
      childRoutes: [Dashboard(store)]
    },
    {
      component: requireAdmin(SystemLayout),
      childRoutes: [SystemPanel(store)]
    },

    Logout(store)
    // NotFound(store),
    // {
    //   path: '*',
    //   indexRoute: {
    //     onEnter: (nextState, replace) => replace(Routes.NOT_FOUND)
    //   }
    // }
  ]
})
