import { createAction, createRequestTypes, createRequestAction } from 'utils'

export const LOAD_USERS = '@user/LOAD_USERS'
export const UPDATE_USER_PREMIUM = '@user/UPDATE_USER_PREMIUM'
export const UPDATE_USER_DISABLED = '@user/UPDATE_USER_DISABLED'
export const UPDATE_USER_LIMIT = '@user/UPDATE_USER_LIMIT'

export const LOAD_USERS_REQUEST = createRequestTypes('user/LOAD_USERS_REQUEST')
export const UPDATE_USER_PREMIUM_REQUEST = createRequestTypes('@user/UPDATE_USER_PREMIUM_REQUEST')
export const UPDATE_USER_DISABLED_REQUEST = createRequestTypes('@user/UPDATE_USER_DISABLED_REQUEST')
export const UPDATE_USER_LIMIT_REQUEST = createRequestTypes('@user/UPDATE_USER_LIMIT_REQUEST')

export const constants = {
  LOAD_USERS,
  UPDATE_USER_PREMIUM,
  UPDATE_USER_DISABLED,
  UPDATE_USER_LIMIT,

  LOAD_USERS_REQUEST,
  UPDATE_USER_PREMIUM_REQUEST,
  UPDATE_USER_DISABLED_REQUEST,
  UPDATE_USER_LIMIT_REQUEST
}

export const loadUsers = createAction(LOAD_USERS)
export const updateUserPremium = createAction(UPDATE_USER_PREMIUM)
export const updateUserDisabled = createAction(UPDATE_USER_DISABLED)
export const updateUserLimit = createAction(UPDATE_USER_LIMIT)

export const loadUsersRequest = createRequestAction(LOAD_USERS_REQUEST)
export const updateUserPremiumRequest = createRequestAction(UPDATE_USER_PREMIUM_REQUEST)
export const updateUserDisabledRequest = createRequestAction(UPDATE_USER_DISABLED_REQUEST)
export const updateUserLimitRequest = createRequestAction(UPDATE_USER_LIMIT_REQUEST)

export default {
  loadUsers,
  updateUserPremium,
  updateUserDisabled,
  updateUserLimit,

  loadUsersRequest,
  updateUserPremiumRequest,
  updateUserDisabledRequest,
  updateUserLimitRequest
}
