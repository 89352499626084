import _ from 'lodash'
import { toggleLoader } from 'common/actions/loader'

import { AUTH_USER_REQUEST, LOGIN_REQUEST } from 'common/actions/auth'

import {
  LOAD_SYSTEMS_REQUEST,
  CREATE_SYSTEM_REQUEST,
  UPDATE_SYSTEM_REQUEST,
  DELETE_SYSTEM_REQUEST,
  UPDATE_NEXT_VERSION_SYSTEM_REQUEST,
  UPDATE_RESET_SYSTEM_REQUEST,
  ACTIVATE_SYSTEM_API_KEY_REQUEST,
  DISABLE_SYSTEM_API_KEY_REQUEST,
  POLL_SYSTEMS_REQUEST,
  DELETE_SYSTEM_DATA_REQUEST,
  DELETE_SYSTEM_FROM_USER_REQUEST,
  SYNC_AIRTABLE_REQUEST
} from 'common/actions/system'

import { LOAD_USERS_REQUEST, UPDATE_USER_LIMIT_REQUEST } from '../common/actions/user'

const showLoaderActions = []
const hideLoaderActions = []

const addAsyncActions = actionTypes => {
  actionTypes.forEach(actionType => {
    showLoaderActions.push(actionType.START)
    hideLoaderActions.push(actionType.SUCCESS, actionType.FAILURE)
  })
}

// Add actions here...
addAsyncActions([
  // User
  AUTH_USER_REQUEST,
  LOGIN_REQUEST,

  // Systems
  POLL_SYSTEMS_REQUEST,
  LOAD_SYSTEMS_REQUEST,
  CREATE_SYSTEM_REQUEST,
  UPDATE_SYSTEM_REQUEST,
  DELETE_SYSTEM_REQUEST,
  UPDATE_NEXT_VERSION_SYSTEM_REQUEST,
  UPDATE_RESET_SYSTEM_REQUEST,
  ACTIVATE_SYSTEM_API_KEY_REQUEST,
  DISABLE_SYSTEM_API_KEY_REQUEST,
  DELETE_SYSTEM_DATA_REQUEST,
  DELETE_SYSTEM_FROM_USER_REQUEST,
  SYNC_AIRTABLE_REQUEST,

  // Users
  LOAD_USERS_REQUEST,
  UPDATE_USER_LIMIT_REQUEST
])

export default () => dispatch => action => {
  const type = action.type

  if (_.includes(showLoaderActions, type)) {
    dispatch(toggleLoader(true))
  }

  if (_.includes(hideLoaderActions, type)) {
    dispatch(toggleLoader(false))
  }

  return dispatch(action)
}
