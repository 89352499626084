import PropTypes from 'prop-types'
import styles from './AuthLayout.scss'

const AuthLayout = ({ children }) => (
  <div className={styles.component}>
    <div className={styles.content}>{children}</div>
  </div>
)

AuthLayout.propTypes = {
  children: PropTypes.node
}

export default AuthLayout
