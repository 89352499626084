import { fromJS } from 'immutable'
import { createReducer } from 'utils'

import { AUTH_USER_REQUEST, LOGIN_REQUEST, LOGOUT } from 'common/actions/auth'

import {
  LOAD_SYSTEMS_REQUEST,
  CREATE_SYSTEM_REQUEST,
  UPDATE_SYSTEM_REQUEST,
  UPDATE_SYSTEMS_BY_MODEL_REQUEST,
  ACTIVATE_SYSTEM_API_KEY_REQUEST,
  DISABLE_SYSTEM_API_KEY_REQUEST,
  UPDATE_RESET_SYSTEM_REQUEST,
  UPDATE_NEXT_VERSION_SYSTEM_REQUEST,
  LOAD_SYSTEM_REQUEST,
  LOAD_LAST_VERSIONS_REQUEST,
  POLL_SYSTEMS_REQUEST
} from 'common/actions/system'

import {
  LOAD_USERS_REQUEST,
  UPDATE_USER_DISABLED_REQUEST,
  UPDATE_USER_LIMIT_REQUEST,
  UPDATE_USER_PREMIUM_REQUEST
} from 'common/actions/user'

export const initialState = fromJS({
  users: {},
  systems: {},
  lastVersions: {},
  models: {},
  device: {}
})

const mergeEntities = (state, { payload }) => {
  return state.withMutations(state =>
    Object.keys(payload.entities).reduce(
      (_state, entity) => _state.mergeDeepIn([entity], payload.entities[entity]),
      state
    )
  )
}

const replaceEntities = (state, { payload }) => {
  let immutableMap
  if (payload.entities.systems) {
    immutableMap = fromJS(payload.entities.systems)
  } else {
    immutableMap = fromJS([])
  }

  return state.setIn(['systems'], immutableMap)
}

export default createReducer(initialState, {
  // User actions
  [LOGOUT]: () => initialState,
  [AUTH_USER_REQUEST.SUCCESS]: mergeEntities,
  [LOGIN_REQUEST.SUCCESS]: mergeEntities,

  // System actions
  [LOAD_SYSTEMS_REQUEST.SUCCESS]: replaceEntities,
  [LOAD_LAST_VERSIONS_REQUEST.SUCCESS]: mergeEntities,
  [POLL_SYSTEMS_REQUEST.SUCCESS]: mergeEntities,
  [LOAD_SYSTEM_REQUEST.SUCCESS]: mergeEntities,
  [CREATE_SYSTEM_REQUEST.SUCCESS]: mergeEntities,
  [UPDATE_SYSTEM_REQUEST.SUCCESS]: mergeEntities,
  [UPDATE_SYSTEMS_BY_MODEL_REQUEST.SUCCESS]: mergeEntities,
  [ACTIVATE_SYSTEM_API_KEY_REQUEST.SUCCESS]: (state, { payload: { deviceId, apiKey } }) => {
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        return s.set('apiKey', apiKey)
      })
    )
  },
  [DISABLE_SYSTEM_API_KEY_REQUEST.SUCCESS]: (state, { payload: { deviceId } }) => {
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== deviceId) return s
        return s.set('apiKey', null)
      })
    )
  },
  [UPDATE_RESET_SYSTEM_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== data.deviceId) return s
        return s.set('reset', data.data.reset)
      })
    )
  },
  [UPDATE_NEXT_VERSION_SYSTEM_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    return state.updateIn(['systems'], systems =>
      systems.map(s => {
        if (s.get('_id') !== data.deviceId) return s
        return s.set('nextVersion', data.data.nextVersion)
      })
    )
  },

  // User actions
  [LOAD_USERS_REQUEST.SUCCESS]: mergeEntities,
  [UPDATE_USER_PREMIUM_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    return state.updateIn(['users'], users =>
      users.map(u => {
        if (u.get('_id') !== data.customerId) return u
        return u.set('isPremium', data.data.isPremium)
      })
    )
  },
  [UPDATE_USER_LIMIT_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    return state.updateIn(['users'], users =>
      users.map(u => {
        if (u.get('_id') !== data.customerId) return u
        return u.set('nDispositivosLimit', data.data.nLimit)
      })
    )
  },
  [UPDATE_USER_DISABLED_REQUEST.SUCCESS]: (state, { payload: { data } }) => {
    return state.updateIn(['users'], users =>
      users.map(u => {
        if (u.get('_id') !== data.customerId) return u
        return u.set('disable', data.data.disabled)
      })
    )
  }
})
